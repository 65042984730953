import { Card } from "@glorialabs/gl-components-module-ui";
import { FunctionComponent } from "react";
import { Period } from "../../../enum/Period.enum";

interface ICaption{
  activeTab: number;
  activePage: string;
  className?: string;
}

export const Caption:FunctionComponent<ICaption> = ({ activeTab, activePage, className }) => {
  const legendInfoQuality = () => {
    return (
      <>
        {activeTab === Period.MONTHLY ? (
          <>
            <p className="leading-5">
              <span className="font-bold">Barras en azul obscuro</span>:
              Resultados de este año.
            </p>
            <p className="leading-5">
              <span className="font-bold">Barras en azul claro</span>:
              Resultados del año pasado.
            </p>
            <p className="leading-5">
              <span className="font-bold">Línea base</span>: Límite referencial
              según esquema de pagos.
            </p>
            <p className="leading-5">
              <span className="font-bold">UFC</span>: Unidades formadoras de
              colonias.
            </p>
            <p className="leading-5">
              <span className="font-bold">RCS</span>: Recuento de células
              somáticas.
            </p>
          </>
        ) : (
          <>
            <p className="leading-5">
              <span className="font-bold">Barras en azul</span>: Resultados
              óptimos.
            </p>
            <p className="leading-5">
              <span className="font-bold">Barras en rojo</span>: Resultados por
              mejorar.
            </p>
            <p className="leading-5">
              <span className="font-bold">Línea base</span>: Límite referencial
              según esquema de pagos.
            </p>
            <p className="leading-5">
              <span className="font-bold">UFC</span>: Unidades formadoras de
              colonias.
            </p>
            <p className="leading-5">
              <span className="font-bold">RCS</span>: Recuento de células
              somáticas.
            </p>
          </>
        )}

        {activeTab === Period.BIWEEKLY && (
          <p className="leading-5">
            <span className="font-bold">Qna</span>: Quincena.
          </p>
        )}
      </>
    );
  };

  const legendInfoCompositionAndAcumulation = () => {
    return (
      <>
        {activeTab === Period.MONTHLY ? (
          <>
            <p className="leading-5">
              <span className="font-bold">Barras en azul obscuro</span>:
              Resultados de este año.
            </p>
            <p className="leading-5">
              <span className="font-bold">Barras en azul claro</span>:
              Resultados del año pasado.
            </p>
          </>
        ) : (
          <>
            <p className="leading-5">
              <span className="font-bold">Barras en azul</span>: Resultados
              óptimos.
            </p>
            <p className="leading-5">
              <span className="font-bold">Barras en rojo</span>: Resultados por
              mejorar.
            </p>
          </>
        )}
        <p className="leading-5">
          <span className="font-bold">Línea base</span>: Límite referencial
          según esquema de pagos.
        </p>
        {activeTab === Period.BIWEEKLY && (
          <p className="leading-5">
            <span className="font-bold">Qna</span>: Quincena.
          </p>
        )}
      </>
    );
  };

  return (

    <Card.Container className={`bg-transparent border border-[#D6DBDC] 
      rounded-2xl px-6 py-4 w-full self-start ${className}`}>
      <Card.Header className="mb-4">
        <p className="text-base font-bold">Leyenda</p>
      </Card.Header>
      <Card.Body className="text-sm">
        {activePage === "quality"
          ? legendInfoQuality()
          : legendInfoCompositionAndAcumulation()}
      </Card.Body>
    </Card.Container>
  );
};
