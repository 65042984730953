import {
  Card, Icon, Text
} from "@glorialabs/gl-components-module-ui";
import moment from "moment";
import { formatMilesNumber } from "../../../utils";
import { ILastMeasurementQualityResponse } from "../../../services/quality/response.interface";
import { InfoType } from "../../../configs/interfaces";

interface Props {
  readonly info: InfoType
}

export default function QualityCard({ info }: Props) {
  const { lastRCSMeasurement, lastUFCMeasurement } = info as ILastMeasurementQualityResponse;
  return (
    <Card.Container
      style={{
        display:         "flex",
        flexDirection:   "column",
        justifyContent:  "space-between",
        backgroundColor: "#F0EDFF",
        paddingTop:      20,
        paddingBottom:   20,
        borderRadius:    16,
        // width: 361,
        height:          225
      }}
    >
      <Card.Header className="text-center flex flex-row justify-center">
        <Icon name="CalidadIcon" size={32}></Icon>
        <Text size="2xl" className="ml-4">
          Calidad
        </Text>
      </Card.Header>
      <Card.Body className="text-center flex justify-center flex-col">
        <div className="flex text-center justify-center items-center">
          <Text size="2xl" className="text-[#0A0036] flex tracking-wider">
            {lastUFCMeasurement?.result ? formatMilesNumber(lastUFCMeasurement?.result) : "-"} ufc/ml
          </Text>
          <Text size="xs" bold className="ml-1">
            {lastUFCMeasurement.lot_milk_date.length > 0
              ? `(${moment(lastUFCMeasurement.lot_milk_date).format("DD/MM/YYYY")})`
              : "( - )"}
          </Text>
        </div>

        <div className="flex text-center justify-center items-center mt-1">
          <Text
            size="2xl"
            className="text-[#0A0036] flex items-end tracking-wider	"
          >
            {formatMilesNumber(lastRCSMeasurement.result)} rsc/ml
          </Text>
          <Text size="xs" bold className="ml-1">
            {lastRCSMeasurement?.lot_milk_date.length > 0
              ? `(${moment(lastRCSMeasurement.lot_milk_date).format("DD/MM/YYYY")})`
              : "( - )"}
          </Text>
        </div>
      </Card.Body>
      <Card.Footer
        className="text-center"
        style={{ color: "#B60014", textDecoration: "underline" }}
      >
        {/* <Link href="/myproduction/quality" style={{ marginTop: 8 }}> */}
        <Text
          size="base"
          bold={true}
          className="cursor-pointer"
          style={{ color: "#B60014" }}
        >
          Ver más
        </Text>
        {/* </Link> */}
      </Card.Footer>
    </Card.Container>
  );
}
