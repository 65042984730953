import { IResponseListUserMaster } from "../../../services/master/response.interface";

export const countActives = (listUserByMaster: IResponseListUserMaster) => {
  if (listUserByMaster) {
    return listUserByMaster.data.reduce((contador, user) => {
      return contador + (user.is_active ? 1 : 0);
    }, 0);
  } else {
    return 0;
  }
};
