import {
  Card, Icon, Text
} from "@glorialabs/gl-components-module-ui";
import moment from "moment";
import { formatMilesNumber } from "../../../utils";
import { ILoanInfo, IPurchaseInfo } from "../../../services/services/interface";
export interface BaseCardProps {
  readonly info: ILoanInfo | IPurchaseInfo;
  readonly iconName: "ComprasIcon" | "PrestamosIcon";
  readonly headerText: string;
  readonly amount: number;
  readonly interest: number;
}

export default function BaseCard({
  info, iconName, headerText, amount, interest
}: BaseCardProps) {

  return (
    <Card.Container
      style={{
        display:         "flex",
        flexDirection:   "column",
        justifyContent:  "space-between",
        backgroundColor: "#F0EDFF",
        paddingTop:      20,
        paddingBottom:   20,
        borderRadius:    16,
        paddingInline:   50,
        height:          225
      }}
    >
      <Card.Header className="text-center flex flex-row justify-center">
        <Icon name={iconName} size={32}></Icon>
        <Text size="2xl" className="ml-4">
          {headerText}
        </Text>
      </Card.Header>
      <Card.Body className="text-center ">
        <Text size="base" className="text-[#0A0036]">
          Próxima cuota
        </Text>
        <Text size="2xl" className="text-[#0A0036] mt-2">
          {amount
            ? formatMilesNumber(amount + interest)
            : 0}{" "}
          PEN
        </Text>
        <Text size="xs" bold={true} className="text-[#0A0036] mt-2">
          Vencimiento:{" "}
          {info.installment_due_date
            ? moment(info.installment_due_date).format("DD/MM/YYYY")
            : "( - )"}
        </Text>
      </Card.Body>
      <Card.Footer
        className="text-center"
        style={{ color: "#B60014", textDecoration: "underline" }}
      >
        <Text
          size="base"
          bold={true}
          className="cursor-pointer"
          style={{ color: "#B60014" }}
        >
          Ver más
        </Text>
      </Card.Footer>
    </Card.Container>
  );
}
