/**Tipos de indicadores de calidad */
export enum QualityIndicator {
  FAT = 1,

  PROTEIN = 2,

  /**unidades formadoras de colonia*/
  RANGE_UFC = 3,

  /**
   * Recuento de celulas somáticas
   */
  RANGE_RCS = 4,

  /**
   * kg de leche
   */
  RANGE_VOLUME = 5,

  /**
   * Antibióticos/Inhibidores
   */
  ANTIBIOTICOS = 6,

  /** CERTIFICADO DE BIENESTAR ANIMAL */
  ANIMAL_WELFARE = 7,

  RANGE_TEMPERATURE = 8,

  /**RANGO: GRASA + PROTEINA */

  RANGE_FAT_PROTEIN = 9,

  /** CERTIFICADO LIBRE TBC */
  TBC = 10,

  /** CERTIFICADO LIBRE BR */

  BR = 11,

  /** BONO DE DOBLE CERTIFICADO: TBC + BR */
  DOUBLE_CERTIFICATE_BONUS_TBC_BR = 12,

  /** AFILIADO SNP */
  SNP = 13,

  /**PRECIO BASE */
  PRICE_BASE = 14,

  /**
   * Adulteraciones
   */
  ADULTERATION = 15,

  /**
   * valor para calcular el factor del flete
   */
  FLETE_FACTOR = 16
}
