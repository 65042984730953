import {
  Fragment, useContext, useState
} from "react";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { useQuery } from "react-query";
import {
  Dropdown,
  IOption,
  Tabs,
  Text
} from "@glorialabs/gl-components-module-ui";
import { getGroupsAndPlotsByCowfarmer } from "../../../services/cowfarmers/api";
import { AuthContext } from "../../../context/AuthContext";
import {
  infoTab,
  optionsTab,
  renderLoading,
  useProductionData
} from "../../../hooks/getDataProductionHook";
import { DataCharts } from "./components/DataCharts";
import { IBaseDataProduction } from "../../../services/quality/interface";
import { sortMilkRecordsItems } from "../DownloadInfoPage/utils/downloadInfo.utils";
import DataTable, { MonthlyDataField } from "../components/DataTableProductionNew";
import { ONE_HOUR } from "../../../constant/staleTimes";
import { ConditionalWrapper } from "../../../components/ConditionalWrapper/ConditionalWrapper";
import { Caption } from "../components/Caption";
import { Period } from "../../../enum/Period.enum";
import classNames from "classnames";

export default function QualityPage() {
  const { cowfarmer, cowbarn, setCowbarn } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState<number>(1);
  const { width: windowWidth } = useWindowSize();

  const { data: dataCowbarns, isLoading: isLoadingCowbarns } = useQuery(
    ["dataCowbarns"],
    () => getGroupsAndPlotsByCowfarmer(cowfarmer!.id),
    {
      onSuccess(data) {
        setCowbarn(data[0].value);
      },
      staleTime: ONE_HOUR
    }
  );

  const onSelectChange = (value: IOption | null) => {
    if (value) {
      setCowbarn(value.value as number);
    }
  };

  const { dataDaily, dataBiweekly, dataMonthly } = useProductionData(cowbarn);

  const isLoading = isLoadingCowbarns ||
    dataDaily.isLoading ||
    dataBiweekly.isLoading ||
    dataMonthly.isLoading;

  const monthlyDataFields = [
    { field: "UFCAverageWeighted", title: "UFC (ml)" },
    { field: "RCSAverageWeighted", title: "RCS (ml)" }
  ] as MonthlyDataField[];

  return (
    <div>
      <Text size="4xl" bold={true} className="text-3xl mb-6  w-[361px]">
        Calidad
      </Text>
      <div className="flex justify-between mb-8 flex-wrap flex-col-reverse lg:flex-row">
        <div className="mt-4 lg:mt-0 w-full lg:w-[361px]">
          <Tabs
            options={optionsTab}
            defaultTab={activeTab}
            onOptionSelect={(tab) => setActiveTab(tab as number)}
          />
          <Text size="sm" className="tracking-wider	mt-4">
            {infoTab[activeTab]}
          </Text>
        </div>

        {Array.isArray(dataCowbarns) && dataCowbarns.length > 1 && (
          <div className="w-full lg:w-[361px]">
            <Dropdown
              options={dataCowbarns || []}
              label="Grupo y parcela"
              suggestionText="Selecciona el grupo y parcela de tu establo"
              value={dataCowbarns?.find(
                (option: IOption) => option.value === cowbarn
              )}
              onChange={(value) => onSelectChange(value as IOption | null)}
            />
          </div>
        )}
      </div>
      {isLoading ? (renderLoading()) : (
        <div>
          <DataCharts
            activeTab={activeTab}
            dataDaily={sortMilkRecordsItems(dataDaily.data!)}
            dataBiweekly={dataBiweekly.data as IBaseDataProduction[]}
            dataMonthly={dataMonthly.data as IBaseDataProduction[]}
            windowWidth={windowWidth}
          />
          <ConditionalWrapper
            condition={activeTab !== Period.MONTHLY}
            wrapper={children => <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">{children}</div>}>
            <Fragment>
              <DataTable
                activePage={"quality"}
                activeTab={activeTab}
                dataDaily={dataDaily.data as IBaseDataProduction[]}
                dataBiweekly={dataBiweekly.data as IBaseDataProduction[]}
                dataMonthly={dataMonthly.data as IBaseDataProduction[]}
                monthlyDataFields={monthlyDataFields}
              />
              <Caption
                activePage={"quality"}
                className={classNames({ "mt-4": activeTab === Period.MONTHLY })}
                activeTab={activeTab} />
            </Fragment>
          </ConditionalWrapper>

        </div>
      )}

    </div>
  );
}
