import { useQueries } from "react-query";
import { getDataAcumulation } from "../services/quality/api";
import { Spinner, Text } from "@glorialabs/gl-components-module-ui";
import { ONE_HOUR } from "../constant/staleTimes";
import { Period } from "../enum/Period.enum";

export const useProductionData = (cowbarn: number) => {
  const [
    dataDaily,
    dataBiweekly,
    dataMonthly
  ] = useQueries([
    {
      queryKey:  ["getDataDaily", cowbarn],
      queryFn:   () => getDataAcumulation(cowbarn, 0),
      enabled:   cowbarn !== 0,
      staleTime: ONE_HOUR
    },
    {
      queryKey:  ["getDataBiweekly", cowbarn],
      queryFn:   () => getDataAcumulation(cowbarn, 1),
      enabled:   cowbarn !== 0,
      staleTime: ONE_HOUR

    },
    {
      queryKey:  ["getDataMonthly", cowbarn],
      queryFn:   () => getDataAcumulation(cowbarn, 2),
      enabled:   cowbarn !== 0,
      staleTime: ONE_HOUR

    }
  ]);

  return {
    dataDaily,
    dataBiweekly,
    dataMonthly
  };
};

export const optionsTab = [
  {
    value: Period.DAILY,
    label: "Diaria"
  },
  {
    value: Period.BIWEEKLY,
    label: "Quincenal"
  },
  {
    value: Period.MONTHLY,
    label: "Mensual"
  }
];

export const infoTab: { [key: number]: string } = {
  1: "Resultados de los últimos 30 días.",
  0: "Resultados de las últimas 06 quincenas cerradas.",
  2: "Comparativa del año anterior versus el actual."
};

export const renderLoading = () => (
  <div
    className="bg-red flex justify-center align-middle items-center flex-col"
    style={{ height: "calc(100vh - 210px)" }}
  >
    <Spinner />
    <Text size="2xl" className="text-[#07196D]">
      Cargando
    </Text>
  </div>
);
