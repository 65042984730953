import {
  Dispatch, FunctionComponent, SetStateAction
} from "react";
import { FormikProps } from "formik";
import { Button } from "@glorialabs/gl-components-module-ui";
import { IVerificationCodeForm } from "./form";

interface IConfirmationForgetPassword {
  formik: FormikProps<IVerificationCodeForm>;
  formikCompleteEmail: FormikProps<{ email: string }>;
  isLoadingChange: boolean;
  setScreen: Dispatch<
    SetStateAction<
      | "login"
      | "validateMFA"
      | "newPassword"
      | "completeEmail"
      | "confirmationForgetPassword"
      | "verificationCode"
      | "registerAuth"
      | "alreadyExistRegister"
      | "confirmationRegister"
      | "notFoundRegister"
    >
  >;
}

export const ConfirmationForgetPassword: FunctionComponent<
  IConfirmationForgetPassword
> = ({
  formik, formikCompleteEmail, isLoadingChange, setScreen
}) => {
  console.log(isLoadingChange);
  return (
    <>
      {/* <div className="block xl:hidden mb-8"> */}
      {/* <GloriaLogo /> */}

      {/* <img alt="Logo-gloria" src={"/logogloria.svg"} width={87} height={61} /> */}
      {/* </div> */}

      <div className="w-[361px] text-center">
        <p className="text-2xl color-[#000000] tracking-wider text-center">
          ¡Felicidades! Ya puedes iniciar sesión con tu correo electrónico y la
          contraseña que acabas de generar
        </p>

        <Button
          name="Iniciar Sesión"
          variant="primary"
          className="w-[361px] rounded-2xl mt-8"
          onClick={() => {
            formik.resetForm();
            formikCompleteEmail.resetForm();
            setScreen("login");
          }}
        >
          Ir a inicio de sesión
        </Button>
      </div>
    </>
  );
};
