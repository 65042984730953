import { IBaseDataProduction } from "../../../services/quality/interface";
import { formatBiweeklyDate, formatDailyDate } from "./formatDate";

export interface DataMonthlyChart {
  [key: string]: number | string;
  period: string;
}

export const transformMonthlyData = (
  inputData: IBaseDataProduction[],
  key: keyof IBaseDataProduction
): DataMonthlyChart[] => {
  if (!inputData) {return [];}
  return inputData.reduce((result: DataMonthlyChart[], item) => {

    const [year, month] = item.lot_milk_date!.split("_");
    const period = month.toUpperCase();

    if (!result.some((entry) => entry.period === period)) {
      result.push({ period });
    }
    let entry = result.find((entry) => entry.period === period);

    if (!entry) {
      entry = { period };
      result.push(entry);
    }

    entry[year] = item[key] as number;

    return result;

  }, []);
};

export const formatMonthlyDataChart = (
  data: DataMonthlyChart[]
): { x: string; y: number }[][] => {
  const years = data.reduce((acc, item) => {
    Object.keys(item).forEach((key) => {
      if (key !== "period" && !acc.includes(key)) {
        acc.push(key);
      }
    });
    return acc;
  }, [] as string[]);

  const yearDataArrays = years.map((year) =>
    data
      .filter((item) => Object.prototype.hasOwnProperty.call(item, year))
      .map((item) => ({ x: item.period.substr(0, 3), y: item[year] || 0 }))
  );

  return yearDataArrays as { x: string; y: number }[][];
};

export const formatDataChart = (
  data: IBaseDataProduction[],
  key: keyof IBaseDataProduction,
  period: "daily" | "biweekly",
  text?: "full" | "short"
): { x: string; y: number }[] => {
  return data.map((item) => {

    let dateFormatted: string;

    if (period === "daily") {
      dateFormatted = formatDailyDate(item.lot_milk_date);
    } else {
      dateFormatted = formatBiweeklyDate(item.lot_milk_date, "chart", text);
    }
    return {
      x: dateFormatted,
      y: item[key] as number
    };
  });
};

export const generateValuesAxisY = (
  data: IBaseDataProduction[],
  // type: "daily" | "biweekly",
  property: keyof IBaseDataProduction
): number[] => {

  let yAxisData: number[] = [];
  let values = [];

  values = data.map((item: IBaseDataProduction) => item[property] as number);

  const max = Math.max(...values);

  const maxAllowed = max * 1.15;

  const numValues = 5;

  const step = maxAllowed / (numValues - 1);

  const yAxisDataKg = [];

  for (let i = 0; i < numValues; i++) {
    yAxisDataKg.push(Math.trunc(step * i));
  }

  yAxisData = yAxisDataKg;

  return yAxisData;
};

export function generateMonthlyAxisY(
  data: { x: string; y: number }[][]
): number[] {
  // Juntar todos los valores 'y' en un solo array
  const allValues = data.reduce((acc, currentArray) => {
    currentArray.forEach((item) => acc.push(item.y));
    return acc;
  }, [] as number[]);

  // Ordenar los valores de forma ascendente
  const sortedValues = allValues.sort((a, b) => a - b);

  // Calcular el valor máximo permitido (115% del valor más alto)
  const maxAllowed = sortedValues[sortedValues.length - 1] * 1.15;

  // Calcular los valores intermedios de manera que estén entre el mínimo y el máximo
  const numValues = 3;
  const step = (maxAllowed - sortedValues[0]) / (numValues + 1);

  const yAxisData = [0];

  // Calcular los valores intermedios
  for (let i = 1; i <= numValues; i++) {
    yAxisData.push(sortedValues[0] + step * i);
  }

  // Agregar el último valor (115% del valor más alto)
  yAxisData.push(maxAllowed);

  return yAxisData;
}
