import moment from "moment";
import { formatMilesNumber } from "../../../utils";
import {
  DetailInfo, GeneralInfo, IPurchaseInfo, PurchaseDetail
} from "../interface";
import { DateFormat } from "../../../enum/DateFormat.enum";
import { sortAdaptedArray } from "../utils/sortArray.util";
import { IGetPurchasesResponse } from "../response.interface";

interface PurchaseHeader {
  detail_purchase: string;
  pending_capital: number;
  actual_installment: number;
  created_by: string;
  updated_by: string;
  created_at: string;
  updated_at: string;
  id: number;
  cowfarmerId: number;
  documentTypeId: number;
  document_number: string;
  document_ref: string;
  application_date: string;
  purchase_date: string;
  currency: string;
  status: boolean;
  subtotal: number;
  igv: number;
  total: number;
  purchase_installments: number;
  purchaseDetails: PurchaseDetail[];
  purchaseInstallments: IPurchaseInfo[];
}

export const adapterShopping = (dataArray: PurchaseHeader[]) => {
  const numberRef = (actualInstallment: number, arrayLength: number) => {
    let num: number;
    if (actualInstallment === arrayLength) {
      num = actualInstallment - 1;
    } else {
      num = actualInstallment;
    }

    return num;
  };

  // Verificar si hay datos en el array
  if (dataArray.length > 0) {
    // Mapear el array original a un nuevo array de objetos adaptados
    const adaptedArray = dataArray.map((item) => {
      const {
        detail_purchase,
        pending_capital,
        actual_installment,
        document_ref,
        purchase_date,
        purchase_installments,
        purchaseInstallments,
        purchaseDetails,
        total
      } = item;

      const isPaidOff = purchaseInstallments.every((installment) => installment.status);

      const nextInstallmentIndex = numberRef(actual_installment, purchaseInstallments.length);

      const generalInfo = {
        id:          item.id,
        title:       "Próxima cuota",
        totalAmount: isPaidOff
          ? "-"
          : `S/ ${formatMilesNumber(
            purchaseInstallments[nextInstallmentIndex].purchase_installments_amount +
              purchaseInstallments[nextInstallmentIndex].purchase_installments_interest
          )}`,
        expiration: isPaidOff
          ? "-"
          : `${moment(
            purchaseInstallments[nextInstallmentIndex].installment_due_date,
            DateFormat.POSTGRES
          ).format("DD/MM/YYYY")}`
      } as GeneralInfo;

      const detailInfo = {
        columns: [
          {
            name:   "Detalle de venta",
            detail: detail_purchase
          },
          {
            name:   "Monto Total",
            detail: `S/ ${formatMilesNumber(parseFloat(total.toFixed(2)))}`
          },
          {
            name:   "Saldo pendiente",
            detail: isPaidOff
              ? "-"
              : `S/ ${formatMilesNumber(parseFloat(pending_capital.toFixed(2)))}`
          },
          {
            name:   "Cuotas",
            detail: purchase_installments
          }
        ],
        additionalInfo: [
          {
            name:   "Factura",
            detail: document_ref,
            bold:   false
          },
          {
            name:   "Fecha de compra:",
            detail: `${moment(purchase_date, DateFormat.POSTGRES).format(
              "DD/MM/YYYY"
            )}`
          }
        ]
      } as DetailInfo;

      const tableInfo = purchaseInstallments;

      const purchaseInfo = purchaseDetails;

      // Devolver los resultados como un objeto
      return {
        generalInfo,
        detailInfo,
        tableInfo,
        purchaseInfo,
        isPaidOff,
        nextDueDate: purchaseInstallments.find(
          installment => !installment.status
        )?.installment_due_date ?? moment().format(DateFormat.POSTGRES)
      };
    });

    return sortAdaptedArray(adaptedArray) as IGetPurchasesResponse[];

  } else {
    // Devolver un array vacío si el array original está vacío
    return [];
  }
};
