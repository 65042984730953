import {
  useContext, useEffect, useRef, useState, ReactNode
} from "react";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@glorialabs/gl-components-module-ui";

import Person from "../../assets/images/person.png";
import "./style.css";
import {
  HasNewNotifications,
  getMyNotifications,
  hasUnreadNews,
  readAllNotifications
  // readAllNotifications
} from "../../services/services/api";
import DGanaderoLogo from "../../assets/images/Desarrollo-Ganadero-logo.png";

import { AuthContext } from "../../context/AuthContext";
import { logOutUser } from "../../services/master/api";
import { navigationConfig } from "../../configs/navigation.config";
import { ChildrenMenu } from "./Submenu/ChildrenMenu";
import { NavigationCollapse } from "../../configs/interfaces";
import { ISubMenu, SubMenuType } from "./interfaces";
import { NotificationsMenu } from "./Submenu/NotificationsMenu";
import { LogoutMenu } from "./Submenu/LogoutMenu";
import { MobileMenu } from "./Submenu/MobileMenu/MobileMenu";
import { DesktopMenu } from "./DesktopMenu/DesktopMenu";
import { ROLES } from "../../configs/roles.config";
import { useNewsStore } from "../../stores/news.store";

export const Navbar = () => {
  const navigate = useNavigate();

  const { logOut, cowfarmer, roles } = useContext(AuthContext);
  const { setHasUnreadNews } = useNewsStore();
  const [subMenu, setSubMenu] = useState<ISubMenu>({ show: false, type: null });
  const [newNotifications, setNewNotifications] = useState<boolean>(false);
  const [readContent, setReadContent] = useState(false);

  const navbarRef = useRef<HTMLDivElement>(null);

  const handleDocumentClick = (e: MouseEvent) => {
    if (navbarRef.current && !navbarRef.current.contains(e.target as Node)) {
      if ((e.target as HTMLElement).tagName.toLowerCase() === "svg" ||
          (e.target as HTMLElement).tagName.toLowerCase() === "path") {
        // No se ejecutará closeAll() si el e.target es una etiqueta "svg" o "path"
        return;
      }
      setSubMenu({ show: false, type: null });
    }
  };

  useQuery(
    "hasNewNotifications",
    () => HasNewNotifications(cowfarmer?.id as number),
    {
      onSuccess: (data) => {
        if (data.hasUnreadNotifications) {
          setNewNotifications(data.hasUnreadNotifications);
        }
      }
    }
  );

  const { data: dataNotification } = useQuery(
    "myNotifications",
    () =>
      getMyNotifications({ id: cowfarmer?.id as number, limit: 1, offset: 0 })
    // {
    //   enabled: showNotificationsContent,
    // },
  );

  useQuery(
    "readAllNotifications",
    () => readAllNotifications(cowfarmer?.id as number),
    {
      enabled:   Boolean(readContent),
      onSuccess: (data) => {
        if (data.success) {
          setNewNotifications(false);
          setReadContent(false);
        }
      }
    }
  );

  useQuery("hasUnreadNews", () => hasUnreadNews(cowfarmer?.id as number), {
    onSuccess: (data) => {
      setHasUnreadNews(data);
    }
  });

  const closeAll = () => {
    setSubMenu({ show: false, type: null });
  };

  const seeAllNotifications = () => {
    closeAll();
    navigate("/notificaciones");
  };

  const btnLogout = async () => {
    const isSuccess = await logOutUser();
    console.log("Logout", isSuccess);
    logOut();
  };

  const handleSubMenu = (type: SubMenuType) => {
    setSubMenu((prevSubMenu) => ({
      show: prevSubMenu.show ? prevSubMenu.type !== type : true,
      type
    }));
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  });

  const navigation = navigationConfig();

  const contentSubMenu: Record<SubMenuType, ReactNode> = {
    production: (
      <ChildrenMenu
        items={
          (
            navigation.find(
              (item) => item.type === "collapse" && item.id === "production"
            ) as NavigationCollapse
          )?.children
        }
        closeAll={closeAll}
        paddingLeft={270}
      />
    ),
    services: (
      <ChildrenMenu
        items={
          (
            navigation.find(
              (item) => item.type === "collapse" && item.id === "services"
            ) as NavigationCollapse
          )?.children
        }
        closeAll={closeAll}
        paddingLeft={
          roles?.id.role.transactionRol === ROLES.FINANCIAL_COWBARN ? 280 : 465
        }
      />
    ),
    notifications: (
      <NotificationsMenu
        dataNotification={dataNotification}
        seeAllNotifications={seeAllNotifications}
      />
    ),
    logout:     <LogoutMenu closeAll={closeAll} btnLogout={btnLogout} />,
    mobileMenu: <MobileMenu closeAll={closeAll} logOut={btnLogout} />
  };

  return (
    <nav
      ref={navbarRef}
      className="fixed top-0 bg-opacity-30 w-full bg-[#F0EDFF] z-10"
    >
      <div className="flex w-full px-8 py-4 justify-between">
        <div className="flex items-center">
          <Link to="/inicio/" className="mr-14" onClick={closeAll}>
            <img src={DGanaderoLogo} width={80} alt="" />
          </Link>

          {/* Contenido para pantallas grandes */}
          <div className="hidden md:hidden lg:flex flex-row gap-12">
            <DesktopMenu closeAll={closeAll} handleSubMenu={handleSubMenu} />
          </div>
        </div>
        <div className="flex items-center"></div>
        {/* Aquí controlamos la visibilidad en pantallas pequeñas */}
        <div className="flex items-center">
          <div className="flex items-center">
            {newNotifications && (
              <p className="mr-1 hidden xl:block">
                ¡Hay nuevas notificaciones!
              </p>
            )}
            <button
              className={"relative cursor-pointer"}
              onClick={() => {
                handleSubMenu("notifications");
                setReadContent(true);
              }}
              style={{ background: "none", border: "none", padding: 0 }}
            >
              <Icon
                name="CampanaIcon"
                color="#0A0036"
                size={32}
                style={{ fontWeight: "700" }}
              />
              {newNotifications && (
                <div className="absolute right-0 top-0 w-4 h-4 rounded-full bg-[#B60014]" />
              )}
            </button>
          </div>
          <button
            onClick={() => handleSubMenu("logout")}
            className="hidden lg:block ml-4 cursor-pointer"
            style={{ background: "none", border: "none", padding: 0 }}
          >
            <div className="rounded-full bg-[#F0EDFF] p-3">
              <img src={Person} alt="icon-person" width={32} height={32} />
            </div>
          </button>

          <button
            className="lg:hidden ml-4 cursor-pointer"
            onClick={() => handleSubMenu("mobileMenu")}
            style={{ background: "none", border: "none", padding: 0 }}
          >
            <Icon name="MenuIcon" />
          </button>
        </div>
      </div>
      <div className="w-full h-px bg-[#E1E1E1]"></div>
      {subMenu.show && subMenu.type && (
        <div>{contentSubMenu[subMenu.type]}</div>
      )}
    </nav>
  );
};
