import {
  useContext, useEffect, useMemo, useState
} from "react";
import { useMutation, useQuery } from "react-query";
import {
  getCategoriesNews, getNews, readAllNews
} from "../../services/services/api";
import { Link } from "react-router-dom";
import {
  Card, Dropdown, Spinner, Text
} from "@glorialabs/gl-components-module-ui";
import moment from "moment";
import { INew } from "../../services/services/interface";
import { ONE_HOUR } from "../../constant/staleTimes";
import { useNewsStore } from "../../stores/news.store";
import { AuthContext } from "../../context/AuthContext";

export default function NewsPage() {
  const [category, setCategory] = useState<number | null>(null);
  const { cowfarmer } = useContext(AuthContext);
  const { hasUnreadNews, setHasUnreadNews } = useNewsStore();

  const { data: dataCategories } = useQuery(
    "categoriesNews",
    getCategoriesNews,
    {
      staleTime: ONE_HOUR
    }
  );

  const { data: dataNews, isLoading: isLoadingNews } = useQuery(
    "news",
    getNews,
    {
      staleTime: ONE_HOUR
    }
  );

  const optionsCategories = useMemo(() => {
    return dataCategories?.map((item) => ({
      label: item.description,
      value: item.id
    }));
  }, [dataCategories]);

  const filteredNews = useMemo(() => {
    return dataNews?.filter((newItem) => {
      if (!category) {
        return true;
      }
      return newItem.categoryNew.id === category;
    });
  }, [dataNews, category]);

  const renderNews = (filteredNews: INew[]) => {
    if (filteredNews?.length > 0) {
      return (
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
          {filteredNews.map((item) => (
            <Link to={`/noticias/${item.slug}`} key={item.id}>
              <Card.Container className="bg-[#F0EDFF] p-3 rounded-2xl">
                <figure className="flex justify-center mb-2">
                  <img
                    src={item.image1}
                    alt="noticia-imagen-portada"
                    width={0}
                    height={198}
                    sizes="100vw"
                    style={{ width: "100%", height: 198, objectFit: "cover" }}
                  />
                </figure>
                <p className="text-black text-2xl mb-2 truncate">{item.title}</p>
                <p className="text-black text-xs mb-2 font-bold">
                  {item.categoryNew.description}
                </p>
                <p className="text-black text-sm">
                  Publicada el{" "}
                  {moment(item.publication_date).format("DD/MM/YYYY")}
                </p>
              </Card.Container>
            </Link>
          ))}
        </div>
      );
    } else {
      return <div className="w-full px-2 py-2">No hay noticias que mostrar</div>;
    }
  };

  const markAsReadMutation = useMutation(() => readAllNews(cowfarmer?.id as number));

  useEffect(() => {
    if (hasUnreadNews) {
      markAsReadMutation.mutate();
      setHasUnreadNews(false);
    }
  }, [hasUnreadNews]);

  return (
    <div>
      <Text size="4xl" bold={true} className="text-3xl mb-6  w-[361px]">
        Noticias
      </Text>
      <div className="w-[361px] mb-4">
        <Dropdown
          options={optionsCategories || []}
          label="Categoría"
          allowClear
          classname="px-0"
          placeholder="Seleccion una opción"
          onChange={(value) => setCategory(value?.value as number)}
        />
      </div>
      {isLoadingNews ? (
        <div className="bg-red flex justify-center align-middle items-center flex-col">
          <Spinner/>
          <Text size="2xl" className="text-[#07196D]">
            Cargando
          </Text>
        </div>
      ) : filteredNews && renderNews(filteredNews)}
    </div>
  );
}
