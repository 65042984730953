// analytics.js
import ReactGA from "react-ga4";

export const initializeGA = (trackingId: string) => {
  ReactGA.initialize(trackingId);
};

export const logPageView = (pathname: string) => {
  ReactGA.send({ hitType: "pageview", page: pathname });
};
