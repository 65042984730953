import { FormikProps } from "formik";
import {
  Button, Input, Text
} from "@glorialabs/gl-components-module-ui";
import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useState
} from "react";
import { IVerificationCodeForm } from "./form";
import axios from "axios";
import { UseMutateAsyncFunction } from "react-query";
import { IResponseSendEmailForgetPassword } from "../../../../services/master/response.interface";
import { IBodySendEmailForgetPassword } from "../../../../services/master/request.interface";

interface IVerificationCode {
  formik: FormikProps<IVerificationCodeForm>;
  isLoadingChange: boolean;
  setScreen: Dispatch<
    SetStateAction<
      | "login"
      | "validateMFA"
      | "newPassword"
      | "completeEmail"
      | "confirmationForgetPassword"
      | "verificationCode"
      | "registerAuth"
      | "alreadyExistRegister"
      | "confirmationRegister"
      | "notFoundRegister"
    >
  >;
  error: unknown;
  email: string;
  refreshEmail: UseMutateAsyncFunction<
    IResponseSendEmailForgetPassword,
    unknown,
    IBodySendEmailForgetPassword,
    unknown
  >;
  loadingEmail: boolean;
}

export const VerificationCode: FunctionComponent<IVerificationCode> = ({
  formik,
  isLoadingChange,
  error,
  email,
  refreshEmail,
  loadingEmail
}) => {
  const { values, handleSubmit, errors } = formik;

  const [counter, setCounter] = useState<number>(20);

  useEffect(() => {
    const interval = setInterval(() => {
      if (counter > 0) {
        setCounter(counter - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [counter]);

  return (
    <>
      <div className="w-[361px] mb-8">
        <p className="text-base text-[#0A0036] text-center mb-4">
          Te enviamos un código de verificación a tu correo electrónico.
        </p>
        <p className="text-base text-[#0A0036] text-center">
          Ingresa el código de verificación y tu nueva contraseña en los campos
          a continuación
        </p>
      </div>

      <form onSubmit={handleSubmit} className="w-[361px]">
        <Input
          {...formik.getFieldProps("verificationCodePassword")}
          label="Código de verificación"
          className="w-full lg:w-[361px] h-fit mb-4"
          suggestionText="Ingresa el código enviado por correo electrónico"
          maxLength={6}
          onChange={(e) => {
            const value = e.target.value;
            if (/^\d*$/.test(value)) {
              formik.handleChange(e);
            }
          }}
          variant={
            axios.isAxiosError(error) || errors.verificationCodePassword
              ? "error"
              : undefined
          }
          feedback={
            axios.isAxiosError(error)
              ? error.response?.data.message
              : errors.verificationCodePassword ?? undefined
          }
        />
        <Input
          label="Crea tu nueva contraseña"
          value={values.newPassword}
          onChange={(e) => formik.setFieldValue("newPassword", e.target.value)}
          maxLength={45}
          className="w-full lg:w-[361px] h-fit mb-4"
          variant={errors.newPassword ? "error" : undefined}
          feedback={errors.newPassword ?? undefined}
          suggestionText={
            "La contraseña debe tener al menos 8 caracteres, 1 mayúscula, 1 minúscula, 1 carácter especial y 1 número"
          }
        />
        <Input
          {...formik.getFieldProps("newPasswordRepeat")}
          label="Repite tu nueva contraseña"
          maxLength={45}
          className="w-full lg:w-[361px] h-fit mb-4"
          suggestionText="Debe coincidir la nueva contraseña"
          variant={errors.newPasswordRepeat ? "error" : undefined}
          feedback={errors.newPasswordRepeat ?? undefined}
        />
        <Button
          loading={isLoadingChange}
          className="w-[361px] mb-4"
          type="submit"
        >
          Crear contraseña
        </Button>
      </form>

      {counter === 0 ? (
        <Button
          loading={loadingEmail}
          onClick={async () => {
            await refreshEmail({ email: email });
            setCounter(20);
          }}
          className="w-[361px] mb-4"
          type="button"
          variant="secondary"
        >
          Reenviar correo
        </Button>
      ) : (
        <Text isButton className="rounded-2xl mt-4 align-middle">
          Reenviar correo en {counter} segundos
        </Text>
      )}
    </>
  );
};
