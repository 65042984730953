import {
  Card, Icon, Text
} from "@glorialabs/gl-components-module-ui";
import moment from "moment";
import { IGeneralCertificates } from "../../../services/services/interface";
import { InfoType } from "../../../configs/interfaces";

interface Props {
  readonly info: InfoType
}

const CertificatesCard: React.FC<Props> = ({ info }) => {

  const { current_certificate, next_end_date } = info as IGeneralCertificates;

  return (
    <Card.Container
      style={{
        display:         "flex",
        flexDirection:   "column",
        justifyContent:  "space-between",
        backgroundColor: "#F0EDFF",
        paddingTop:      20,
        paddingBottom:   20,
        borderRadius:    16,
        paddingInline:   50,
        height:          225
      }}
    >
      <Card.Header className="text-center flex flex-row justify-center">
        <Icon name="CertificadosIcon" size={32}></Icon>
        <Text size="2xl" className="ml-4">
          Certificados
        </Text>
      </Card.Header>
      <Card.Body className="text-center ">
        <Text size="base" className="text-[#0A0036]">
          Vigentes
        </Text>
        <Text size="2xl" className="text-[#0A0036] mt-2">
          {current_certificate || 0}
        </Text>
        <Text size="xs" bold={true} className="text-[#0A0036] mt-2">
          Próximo vencimiento:{" "}
          {next_end_date && next_end_date !== null
            ? moment(next_end_date).format("DD/MM/YYYY")
            : "( - )"}
        </Text>
      </Card.Body>
      <Card.Footer
        className="text-center"
        style={{ color: "#B60014", textDecoration: "underline" }}
      >
        <Text
          size="base"
          bold={true}
          className="cursor-pointer"
          style={{ color: "#B60014" }}
        >
          Ver más
        </Text>
      </Card.Footer>
    </Card.Container>
  );
};

export default CertificatesCard;
