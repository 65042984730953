import React, { FunctionComponent, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { TransactionRole } from "../../services/master/interface";

interface IRestrictedComponent {
  children: React.ReactNode;
  rolesRequired: TransactionRole[];
}

export const RestrictedComponent: FunctionComponent<IRestrictedComponent> = ({
  rolesRequired,
  children
}) => {
  const { roles } = useContext(AuthContext);

  if (
    rolesRequired.includes(roles?.id.role.transactionRol as TransactionRole)
  ) {
    return <>{children}</>;
  }
  return null;
};
