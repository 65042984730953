import { useContext, useState } from "react";
import {
  Button,
  ColumnProps,
  Pagination,
  Spinner,
  Table,
  Text
} from "@glorialabs/gl-components-module-ui";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { getListUserByMaster } from "../../services/master/api";
import { AuthContext } from "../../context/AuthContext";
import { IResponseListUserMaster, IResponseUserMaster } from "../../services/master/response.interface";
import { countActives } from "./utils/countActives";
import { userColumns } from "./utils/userColumns";
import { ONE_HOUR } from "../../constant/staleTimes";

export const UsersPage = () => {
  const { user } = useContext(AuthContext);
  const itemsPerPage = 10;

  const [offset, setOffset] = useState<number>(0);
  const [page, setPage] = useState<number>(1);

  const navigate = useNavigate();
  const { data: listUserByMaster, isFetching } = useQuery(
    ["getListUserByMaster", offset],
    () =>
      getListUserByMaster(
        user?.origin_master_email ?? "",
        itemsPerPage,
        offset
      ),
    {
      staleTime: ONE_HOUR
    }
  );

  const totalPages = Math.ceil((listUserByMaster?.total ?? 0) / itemsPerPage);

  const onChangePage = (page: number) => {
    setPage(page);
    setOffset((page - 1) * itemsPerPage);
  };

  const renderUserTable = (
    listUserByMaster: IResponseListUserMaster,
    dataColumns: ColumnProps[],
    isLoading: boolean,
    onChangePage: ((pageNumber: number) => void) | undefined,
    itemsPerPage: number
  ) => (
    <div className="hidden lg:flex">
      <Table
        className="mb-3 mt-8"
        columns={dataColumns}
        emptyMessage="No hay usuarios que mostrar"
        data={listUserByMaster?.data}
        pagination={
          listUserByMaster?.data.length
            ? {
              position: "start",
              total:    Math.ceil((listUserByMaster?.total || 10) / itemsPerPage),
              onChange: onChangePage
            }
            : undefined
        }
        paginationOnServer={true}
        loading={isLoading}
        rowKey={"id"}
        backgroundColor="#F0EDFF"
      />
    </div>
  );

  const renderMobileUserDetails = (
    listUserByMaster: IResponseListUserMaster,
    totalPages: number,
    page: number,
    onChangePage: ((pageNumber: number) => void) | undefined
  ) => (
    <div className="block lg:hidden">
      {listUserByMaster.data.map((record: IResponseUserMaster) => (
        <div className="bg-[#F0EDFF] p-4 rounded-2xl mb-4" key={record.id}>
          <p className="pb-3 font-bold">
            {record.firstname + " " + record.lastname} -{" "}
            {record.is_active ? "Habilitado" : "Inhabilitado"}
          </p>
          <p className="pb-4">
            {record.email_account} -{" "}
            {record.profile.description === "Usuario administrador"
              ? "Administrador"
              : record.profile.description}
          </p>
          <div className="flex justify-center">
            <Link to={`/usuarios/detail/ver/${record.id}`}>
              <Button variant="primary" outline={true} className="w-[139px]">
                Ver
              </Button>
            </Link>
            <div className="mx-4"></div>
            <Link to={`/usuarios/detail/editar/${record.id}`}>
              <Button variant="primary" outline={true} className="w-[139px]">
                Editar
              </Button>
            </Link>
          </div>
        </div>
      ))}
      {Boolean(listUserByMaster?.data.length) && totalPages > 1 && (
        <div className="flex justify-center mt-4 lg:hidden">
          <Pagination totalPages={totalPages} page={page} onChange={onChangePage} />
        </div>
      )}
    </div>
  );

  const renderUserPage = (listUserByMaster: IResponseListUserMaster) => {
    if (listUserByMaster.data.length > 0) {return (
      <>
        {renderUserTable(listUserByMaster, userColumns, isFetching, onChangePage, itemsPerPage)}
        {renderMobileUserDetails(listUserByMaster, totalPages, page, onChangePage)}
      </>
    ); }
    else {
      return (
        <div>No hay usuarios creados</div>
      );
    }

  };

  return (
    <div>
      <div className="flex flex-col lg:flex-row justify-between items-center">
        <Text
          size="4xl"
          bold={true}
          className="text-3xl mb-6 w-full lg:w-[361px]"
        >
          Usuarios
        </Text>
        <div className="flex flex-col justify-end align-bottom w-full lg:w-fit">
          <div className="flex justify-end">
            <Button
              variant="primary"
              disabled={countActives(listUserByMaster!) >= 5}
              className="w-full lg:w-[110px]"
              onClick={() => navigate("/usuarios/agregar")}
            >
              Añadir
            </Button>
          </div>

          {countActives(listUserByMaster!) >= 5 && (
            <p className="text-sm text-[#B60014] font-bold pt-3 text-center lg:text-left">
              Llegaste al límite máximo de usuarios activos (5), Inhabilita
              algún usuario para poder añadir uno nuevo
            </p>
          )}
        </div>
      </div>

      <div className="pt-6">
        {listUserByMaster ? (
          renderUserPage(listUserByMaster)
        ) : (
          <div className="flex justify-center items-center w-full">
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};
