import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { navigationConfig } from "../../../../configs/navigation.config";
import { MobileItemOption } from "./MobileMenuOption/MobileItemOption";
import { MobileCollapseMenuOption } from "./MobileMenuOption/MobileCollapseOption";
import { Text } from "@glorialabs/gl-components-module-ui";

interface IMobileMenu {
  closeAll: () => void;
  logOut: () => Promise<void>;
}

export const MobileMenu: FunctionComponent<IMobileMenu> = ({
  closeAll,
  logOut
}) => {
  const navigation = navigationConfig();

  return (
    <div className="text-center p-10 flex flex-col gap-6 lg:hidden">
      {navigation.map((item) => {
        if (item.type === "item") {
          return <MobileItemOption item={item} key={item.id} closeAll={closeAll} />;
        }

        return <MobileCollapseMenuOption item={item} key={item.id} closeAll={closeAll} />;
      })}
      <Link
        to="/"
        onClick={() => {
          closeAll();
          logOut();
        }}
      >
        <Text size="base" bold className="text-[#0A0036] tracking-wider">
          Cerrar sesión
        </Text>
      </Link>
    </div>
  );
};
