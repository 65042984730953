import { BarChart, DualBarChart } from "@glorialabs/gl-components-module-ui";
import { IBaseDataProduction } from "../../../../services/quality/interface";
import {
  formatDataChart, formatMonthlyDataChart, transformMonthlyData
} from "../../utils/formatChartData";
import { useContext } from "react";
import { AuthContext } from "../../../../context/AuthContext";

interface DataChartsProps {
  activeTab: number;
  dataDaily: IBaseDataProduction[];
  dataBiweekly: IBaseDataProduction[];
  dataMonthly: IBaseDataProduction[];
  windowWidth: number;
}

export const DataCharts = ({
  activeTab, dataDaily, dataBiweekly, dataMonthly, windowWidth
} : DataChartsProps) => {

  const { cowbarn } = useContext(AuthContext);

  if (activeTab === 2) {
    return (
      <>
        <DualBarChart
          key={`monthlydata-kg-${cowbarn}`}
          title="Comparativa anual grasa (%)"
          data1={
            formatMonthlyDataChart(
              transformMonthlyData(
                dataMonthly,
                "fatAverageWeighted"
              )
            )[0] || []
          }
          labelData1="2023"
          data2={
            formatMonthlyDataChart(
              transformMonthlyData(
                dataMonthly,
                "fatAverageWeighted"
              )
            )[1] || []
          }
          labelData2="2024"
          valuesAxisY={[0, 1.25, 2.5, 3.75, 5]}
          scrollbar={true}
          className="mb-4"
          baseLine={{ value: 3.4 }}
        />
        <DualBarChart
          key={`monthlydata-temperature-${cowbarn}`}
          title="Comparativa anual proteína (%)"
          data1={
            formatMonthlyDataChart(
              transformMonthlyData(
                dataMonthly,
                "proteinAverageWeighted"
              )
            )[0] || []
          }
          labelData1="2023"
          data2={
            formatMonthlyDataChart(
              transformMonthlyData(
                dataMonthly,
                "proteinAverageWeighted"
              )
            )[1] || []
          }
          labelData2="2024"
          valuesAxisY={[0, 1.25, 2.5, 3.75, 5]}
          scrollbar={true}
          baseLine={{ value: 3.0, decimals: 1 }}
          className="mb-4"
        />
      </>
    );
  } else if (activeTab === 0) {
    return (
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <BarChart
          key={`biweekly-fat-${cowbarn}`}
          title="Grasa (%)"
          data={formatDataChart(
            dataBiweekly as IBaseDataProduction[],
            "fatAverageWeighted",
            "biweekly",
            windowWidth > 650 ? "full" : "short"
          )}
          valuesAxisY={[0, 1.25, 2.5, 3.75, 5]}
          baseLine={{ value: 3.4, highlightedValues: "down" }}
          scrollbar={true}
          className="mb-4"
        />
        <BarChart
          title="Proteína (%)"
          key={`biweekly-protein-${cowbarn}`}
          data={formatDataChart(
            dataBiweekly as IBaseDataProduction[],
            "proteinAverageWeighted",
            "biweekly",
            windowWidth > 650 ? "full" : "short"
          )}
          valuesAxisY={[0, 1.25, 2.5, 3.75, 5]}
          scrollbar={true}
          baseLine={{ value: 3.0, highlightedValues: "down", decimals: 1 }}
          className="mb-4"
        />
      </div>
    );
  } else {
    return (
      <div>
        <BarChart
          title="Grasa (%)"
          key={`dailydata-fat-${cowbarn}`}
          data={formatDataChart(
            dataDaily as IBaseDataProduction[],
            "fatAverageWeighted",
            "daily"
          )}
          valuesAxisY={[0, 1.25, 2.5, 3.75, 5]}
          baseLine={{ value: 3.4, highlightedValues: "down" }}
          scrollbar={true}
          className="mb-4"
        />
        <BarChart
          title="Proteína (%)"
          key={`dailydata-protein-${cowbarn}`}
          data={formatDataChart(
            dataDaily as IBaseDataProduction[],
            "proteinAverageWeighted",
            "daily"
          )}
          valuesAxisY={[0, 1.25, 2.5, 3.75, 5]}
          scrollbar={true}
          baseLine={{ value: 3.0, highlightedValues: "down", decimals: 1 }}
          className="mb-4"
        />
      </div>
    );
  }
};
