import React from "react";
import { FormikProps } from "formik";
import {
  Button, Dropdown, Input, Text
} from "@glorialabs/gl-components-module-ui";
import { Link } from "react-router-dom";
import axios, { AxiosError } from "axios";
import { IFormUser } from "../../form";

interface UserFormProps {
  formikAddUser: FormikProps<IFormUser>;
  optionsUserProfile: { label: string; value: number | string }[];
  isFormIncomplete: boolean;
  isLoadingAddUserMutation: boolean;
  error: unknown;
}

interface ErrorResponse {
  message: string;
}

const AddUserForm: React.FC<UserFormProps> = ({
  formikAddUser,
  optionsUserProfile,
  isFormIncomplete,
  isLoadingAddUserMutation,
  error
}) => {
  const { errors, values } = formikAddUser;

  const translateError = (error: AxiosError<ErrorResponse>) => {
    switch (error.response?.data.message) {
      case "User already registered.":
        return "Usuario ya fue registrado.";
      case "User master does not exist.":
        return "Usuario master no existe.";
      // Añade más casos según sea necesario
      default:
        return error.response?.data.message;
    }
  };

  return (
    <>
      <div className="pt-9">
        <Text size="4xl" className="text-3xl mb-2">Datos del Usuario</Text>
        <Text size="base" className="text-base mb-6">Información básica sobre el usuario a agregar</Text>
        <div className="flex flex-wrap">
          <Input
            {...formikAddUser.getFieldProps("name")}
            label="Nombres"
            maxLength={50}
            className="w-full lg:w-[361px] mb-4"
            suggestionText="Ingresa los nombres del nuevo usuario"
            variant={errors.name ? "error" : undefined}
            feedback={errors.name ?? undefined}
            onChange={(e) => {
              const { name, value } = e.target;
              const trimmedValue = value.trimStart();
              formikAddUser.setFieldValue(name, trimmedValue);
            }}
            required
          />
          <div className="mx-4"></div>
          <Input
            {...formikAddUser.getFieldProps("first_lastname")}
            label="Apellidos"
            maxLength={50}
            className="w-full lg:w-[361px] mb-4"
            suggestionText="Ingresa sus apellidos"
            variant={errors.first_lastname ? "error" : undefined}
            feedback={errors.first_lastname ?? undefined}
            onChange={(e) => {
              const { name, value } = e.target;
              const trimmedValue = value.trimStart();
              formikAddUser.setFieldValue(name, trimmedValue);
            }}
            required
          />
        </div>
      </div>
      <div className="pt-9">
        <Text size="4xl" className="text-3xl mb-2">Datos de acceso</Text>
        <Text size="base" className="text-base mb-6">Información relacionada a los accesos del nuevo usuario</Text>
        <div className="flex flex-wrap">
          <div className="w-full lg:w-[361px] mb-4 lg:mb-0">
            <Dropdown
              options={optionsUserProfile || []}
              label="Tipo de usuario"
              suggestionText="Esto determina a qué tendrá acceso el usuario."
              value={optionsUserProfile?.find((userProfile) => userProfile.value === values.rol)}
              onChange={
                (e) => formikAddUser.setFieldValue("rol", (
                  e as {
                    label: string;
                    value: number | string
                  }
                )?.value)
              }
              variant={errors.rol ? "error" : undefined}
              feedback={errors.rol ?? undefined}
              required
            />
          </div>
          <div className="mx-4"></div>
          <div className="w-full lg:w-[361px] mb-4 lg:mb-2 xl:mb-0">
            <Input
              {...formikAddUser.getFieldProps("email")}
              label="Correo electrónico"
              maxLength={50}
              className="w-full lg:w-[361px]"
              suggestionText="Con este correo el usuario iniciará sesión"
              feedback={
                axios.isAxiosError(error)
                  ? translateError(error)
                  : errors.email ?? undefined
              }
              variant={axios.isAxiosError(error) || errors.email ? "error" : undefined}
              required
            />
          </div>
          <div className="mx-4"></div>
          <div className="w-full lg:w-[361px] mb-4 lg:mb-0">
            <Input
              {...formikAddUser.getFieldProps("phone_number")}
              label="Número de celular"
              maxLength={9}
              className="w-full lg:w-[361px]"
              suggestionText="Este número servirá para validar su identidad"
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  formikAddUser.handleChange(e);
                }
              }}
              variant={errors.phone_number ? "error" : undefined}
              feedback={errors.phone_number ?? undefined}
              required
            />
          </div>
        </div>
      </div>
      <div className="block lg:hidden pt-9">
        <Link to="/usuarios">
          <Button variant="primary" outline={true} className="w-full" type="button">Regresar</Button>
        </Link>
        <div className="mx-4"></div>
        <Button
          variant="primary"
          className="w-full mt-4"
          loading={isLoadingAddUserMutation}
          disabled={isFormIncomplete}
          type="submit"
        >
          Guardar
        </Button>
      </div>
    </>
  );
};

export default AddUserForm;
