import LogoGloria from "../../assets/logogloria.svg";
import { TemplateTermsAndCondition } from "./TemplateTermsAndCondition";

export const OnlyViewTermsAndConditions = () => {
  return (
    <div className="min-h-screen pt-8 lg:pt-20 pb-8 px-0 md:px-10 lg:px-20 flex flex-col justify-center gap-6">
      <div className="flex justify-center lg:absolute left-4 top-4">
        <LogoGloria />
      </div>
      <div className="bg-[#F0EDFF] p-4 rounded-2xl flex flex-col mt-8">
        <TemplateTermsAndCondition onlyView={true}/>
      </div>
    </div>
  );
};
