import { useQuery } from "react-query";
import { getLoansByCowfarmer } from "../../../services/services/api";
import { Fragment, useContext } from "react";
import { loanDataColumns } from "../utils/loansColumns";
import { Spinner, Text } from "@glorialabs/gl-components-module-ui";
import { AuthContext } from "../../../context/AuthContext";
import { IGetLoansResponse } from "../../../services/services/response.interface";
import CardItem from "../components/CardItem";
import { ONE_HOUR } from "../../../constant/staleTimes";

export default function LoansPage() {
  const { cowfarmer } = useContext(AuthContext);

  const { data, isLoading } = useQuery(["loans"], () =>
    getLoansByCowfarmer(cowfarmer!.id),
  { staleTime: ONE_HOUR }
  );

  if (isLoading)
  {return (
    <div
      className="bg-red flex justify-center align-middle items-center flex-col"
      style={{ height: "calc(100vh - 210px)" }}
    >
      <Spinner/>
      <Text size="2xl" className="text-[#07196D]">
        Cargando
      </Text>
    </div>
  );}
  return (
    <div>
      <Text size="4xl" bold={true} className="text-3xl mb-6  w-[361px]">
        Préstamos
      </Text>

      <div>
        {data!.length > 0 ? (
          data?.map((res: IGetLoansResponse) => (
            <Fragment key={res.generalInfo.id}>
              <CardItem
                generalInfo={res.generalInfo}
                detailInfo={res.detailInfo}
                tableInfo={res.tableInfo}
                columnsTable={loanDataColumns}
                divider={true}
              />
            </Fragment>
          ))
        ) : (
          <div className="bg-[#F0EDFF] rounded-2xl w-full px-6 py-8">
            No hay prestamos que mostrar
          </div>
        )}
      </div>
    </div>
  );
}
