import { Navigate, Outlet } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

type ProtectedRouteType = {
  rolesRequired?: string[];
};

export default function ProtectedRoute({ rolesRequired }: ProtectedRouteType) {
  const { status, roles } = useContext(AuthContext);

  if (rolesRequired && status === "authenticated") {
    return rolesRequired.includes(
      roles?.id.role.transactionRol as string
    ) ? (
        <Layout>
          <Outlet />
        </Layout>
      ) : (
        <Navigate to={"/"} />
      );
  } else {
    return status === "authenticated" ? (
      <Layout>
        <Outlet />
      </Layout>
    ) : (
      <Navigate to={"/"} />
    );
  }
}
