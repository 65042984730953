import { Text } from "@glorialabs/gl-components-module-ui";
import { Link } from "react-router-dom";
import { NavigationItem } from "../../../configs/interfaces";
import { FunctionComponent } from "react";

interface IChildrenMenu {
  items: NavigationItem[];
  closeAll: () => void;
  paddingLeft: number;
}

export const ChildrenMenu: FunctionComponent<IChildrenMenu> = ({ items, closeAll, paddingLeft }) => {
  return (
    <div className="p-4 flex gap-4" style={{ paddingLeft }}>
      <div className="grid grid-rows-3 grid-flow-col gap-4">
        {items.map((item) => (
          <Link
            to={item.navLink}
            onClick={() => closeAll()}
            key={item.id}
          >
            <Text
              size="base"
              bold={true}
              className="text-[#0A0036] tracking-wider cursor-pointer"
            >
              {item.title}
            </Text>
          </Link>
        ))}
      </div>
    </div>
  );
};
