import * as Yup from "yup";

const whiteListToOneMessages = [
  "Por seguridad, tu usuario ha sido bloqueado. Ponte en contacto con tu asesor de campo para reestablecerlo."
];

export interface IAuthForm {
  email: string;
  password: string;
}

export const initialValues = {
  email:    "",
  password: ""
};

export const validationSchemaLogin = Yup.object({
  email: Yup.string()
    .email("Ingresa un correo válido")
    .required("Debe ingresar un correo"),
  password: Yup.string().required("Debe ingresar una contraseña")
});

export const showCustomMessage = (message: string) => {
  if (whiteListToOneMessages.includes(message)) {
    return " ";
  }

  return message;
};
