import {
  Button, Checkbox, Text
} from "@glorialabs/gl-components-module-ui";
import { useFormik } from "formik";
import {
  IFormTermsConditions,
  initialValues,
  validationSchemaTerms
} from "./form";
import { useMutation } from "react-query";
import { updateTerms } from "../../services/master/api";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import LogoGloria from "../../assets/logogloria.svg";
import { TemplateTermsAndCondition } from "./TemplateTermsAndCondition";
import { IBodyTerms } from "../../services/master/request.interface";

export const TermsAndConditions = () => {
  const navigate = useNavigate();
  const { user, acceptTerms } = useContext(AuthContext);

  const termsAndConditionsOk = Boolean(user?.condition_1 && user.condition_2);

  const { mutateAsync: updateTermsAndConditions, isLoading } =
    useMutation(
      async (payload: IBodyTerms) => {
        return await updateTerms(payload);
      },
      {
        onSuccess: (data) => {
          if (data) {
            acceptTerms();
          }
        }
      }
    );

  const formik = useFormik<IFormTermsConditions>({
    initialValues,
    validationSchema: validationSchemaTerms,
    validateOnChange: false,
    validateOnBlur:   false,
    onSubmit:         (values) => {
      updateTermsAndConditions({ ...values, idUser: user!.id });
    }
  });

  const { handleSubmit, errors } = formik;

  useEffect(() => {
    if (termsAndConditionsOk) {
      return navigate("/");
    }
  }, [navigate, termsAndConditionsOk]);

  return (
    <form
      className="min-h-screen pt-8 lg:pt-20 pb-8 px-0 md:px-10 lg:px-20 flex flex-col justify-center gap-6 "
      onSubmit={handleSubmit}
    >
      <div className="flex justify-center lg:absolute left-4 top-4">
        <LogoGloria />
      </div>
      <div>
        {errors.condition_1 || errors.condition_2 ? (
          <Text
            size="2xl"
            style={{ color: "#E11808" }}
            className="text-2xl text-center leading-9 text-red-600 tracking-widest"
          >
            Para continuar, da clic en las cajas de selección de términos y
            condiciones de uso del Portal Ganadero y la política de privacidad
            de Leche Gloria
          </Text>
        ) : (
          <Text
            size="2xl"
            className="text-2xl text-center leading-9 tracking-widest"
          >
            Para continuar, lee y acepta los términos y condiciones de uso del
            Portal Ganadero y la política de privacidad de Leche Gloria
          </Text>
        )}
      </div>
      <div className="bg-[#F0EDFF] p-4 rounded-2xl h-[550px] flex flex-col">
        <div className="mb-2">
          <div className="mb-2">
            <label className="flex gap-2">
              <div className="w-5 h-5 mt-[2px]">
                <Checkbox
                  {...formik.getFieldProps("condition_1")}
                  onChange={(value) =>
                    formik.setFieldValue("condition_1", value)
                  }
                  checked={formik.values.condition_1}
                />
              </div>
              <Text>He leído y acepto los términos y condiciones</Text>
            </label>
          </div>
          <div>
            <label className="flex gap-2">
              <div className="w-5 h-5 mt-[2px]">
                <Checkbox
                  {...formik.getFieldProps("condition_2")}
                  onChange={(value) =>
                    formik.setFieldValue("condition_2", value)
                  }
                  checked={formik.values.condition_2}
                />
              </div>
              <Text>
                He leído y estoy de acuerdo con la Política de Privacidad de Leche Gloria (
                <a
                  className="text-[#0A0036] font-bold"
                  href="https://www.gloria.com.pe/images/Pol%C3%ADtica%20de%20Privacidad.pdf"
                  target="_blank"
                >
                  https://www.gloria.com.pe/images/Política de Privacidad.pdf
                </a>
                ) y doy mi consentimiento para que dé tratamiento a mis datos personales
              </Text>
            </label>
          </div>
        </div>
        <TemplateTermsAndCondition />
      </div>
      <div className="flex justify-end">
        <Button type="submit" loading={isLoading}>
          Continuar
        </Button>
      </div>
    </form>
  );
};
