import React from "react";
import {
  Dropdown,
  IOption,
  Input,
  Text
} from "@glorialabs/gl-components-module-ui";
import { FormikProps } from "formik";
import { IFormUser } from "../../form";
import { IResponseUserMaster } from "../../../../services/master/response.interface";
interface DetailUserFormProps {
  formikEditUser: FormikProps<IFormUser>;
  optionsUserProfile: { label: string; value: number | string }[];
  edit: boolean;
  dataUserById?: IResponseUserMaster;
}

export const DetailUserForm: React.FC<DetailUserFormProps> = ({
  formikEditUser,
  optionsUserProfile,
  edit,
  dataUserById
}) => {

  const { errors } = formikEditUser;

  return (
    <>
      <div className="pt-9">
        <Text size="4xl" className="text-3xl mb-2">
          Datos del Usuario
        </Text>

        <Text size="base" className="text-base mb-6">
          Información básica sobre el usuario a agregar
        </Text>

        <div className="flex flex-wrap">
          <Input
            {...formikEditUser.getFieldProps("name")}
            label="Nombres"
            maxLength={50}
            className="w-full lg:w-[361px] mb-4"
            suggestionText="Ingresa los nombres del nuevo usuario"
            variant={errors.name ? "error" : undefined}
            feedback={errors.name ?? undefined}
            disabled={edit}
            onChange={(e) => {
              const { name, value } = e.target;
              const trimmedValue = value.trimStart(); // Elimina espacios en blanco al principio
              formikEditUser.setFieldValue(name, trimmedValue);
            }}
            // value={dataUserById?.names}
          />
          <div className="mx-4"></div>
          <Input
            {...formikEditUser.getFieldProps("first_lastname")}
            label="Apellidos"
            maxLength={50}
            className="w-full lg:w-[361px] mb-4"
            suggestionText="Ingresa sus apellidos"
            variant={errors.first_lastname ? "error" : undefined}
            feedback={errors.first_lastname ?? undefined}
            disabled={edit}
            onChange={(e) => {
              const { name, value } = e.target;
              const trimmedValue = value.trimStart(); // Elimina espacios en blanco al principio
              formikEditUser.setFieldValue(name, trimmedValue);
            }}
          />
        </div>
      </div>
      <div className="pt-9">
        <Text size="4xl" className="text-3xl mb-2">
          Datos de acceso{" "}
        </Text>

        <Text size="base" className="text-base mb-6">
          Información relacionada a los accesos del nuevo usuario
        </Text>

        <div className="flex flex-wrap">
          <div className="w-full lg:w-[361px] mb-4 lg:mb-0">
            <Dropdown
              options={optionsUserProfile || []}
              label="Tipo de usuario"
              suggestionText={"El tipo de usuario no se puede editar"}
              value={
                dataUserById
                  ? {
                    value: dataUserById?.profile.id,
                    label: dataUserById?.profile.description
                  }
                  : undefined
              }
              onChange={(e) =>
                formikEditUser.setFieldValue("rol", (e as IOption)?.value)
              }
              disabled
            />
          </div>
          <div className="mx-4"></div>
          <div className="w-full lg:w-[361px] mb-4 lg:mb-2 xl:mb-0">
            <Input
              {...formikEditUser.getFieldProps("email")}
              label="Correo electrónico"
              maxLength={50}
              className="w-full lg:w-[361px]"
              suggestionText="El correo no se puede editar"
              disabled
            />
          </div>
          <div className="mx-4"></div>
          <div className="w-full lg:w-[361px] mb-4 lg:mb-0">
            <Input
              {...formikEditUser.getFieldProps("phone_number")}
              label="Número de celular"
              maxLength={9}
              className="w-full lg:w-[361px]"
              suggestionText="Indica los nueve digitos del número celular"
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  // Esta expresión regular solo permite números
                  formikEditUser.handleChange(e);
                }
              }}
              // value={dataUserById?.phone}
              variant={errors.phone_number ? "error" : undefined}
              feedback={errors.phone_number ?? undefined}
              disabled={edit}
            />
          </div>
        </div>
      </div>
    </>
  );
};
