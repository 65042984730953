import { InfoType } from "../../../configs/interfaces";
import { IPurchaseInfo } from "../../../services/services/interface";
import BaseCard from "./BaseServicesCard";

interface PurchaseCardProps {
  readonly info: InfoType
}

const ShopCard: React.FC<PurchaseCardProps> = ({ info }) => {

  const shopInfo = info as IPurchaseInfo;

  return (
    <BaseCard
      info={shopInfo}
      iconName="ComprasIcon"
      headerText="Compras"
      amount={shopInfo.purchase_installments_amount}
      interest={shopInfo.purchase_installments_interest}
    />
  );
};
export default ShopCard;
