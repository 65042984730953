import { Button } from "@glorialabs/gl-components-module-ui";
import moment from "moment";
import { FunctionComponent } from "react";
import { INotificationResponse } from "../../../services/services/response.interface";

interface INotificationsMenu {
  dataNotification: INotificationResponse | undefined;
  seeAllNotifications: () => void;
}

export const NotificationsMenu: FunctionComponent<INotificationsMenu> = ({ dataNotification, seeAllNotifications }) => {

  const renderNotifications = (notifications: INotificationResponse) => {
    if (notifications.data.length > 0) {
      return (
        <>
          <h3 className="text-[#07196D] text-base font-bold mb-2 sm:text-right">
            {dataNotification?.data[0].title}
          </h3>
          <p className="text-xs text-[#07196D] font-bold mb-2 sm:text-right">
            {moment(dataNotification?.data[0].date).utc().format("DD/MM/YY")}
          </p>
          <p className="text-base text-[#2D2D30] sm:text-right line-clamp-3 mb-4">
            {dataNotification?.data[0].content}
          </p>
        </>
      );
    } else {
      return (
        <div></div>
      );
    }
  };

  return (
    <div id="notifications" className="flex justify-end sm:pr-20">
      <div className="p-4 w-full sm:w-[352px] flex flex-col md:items-end">
        {
          dataNotification && renderNotifications(dataNotification)
        }
        <Button variant="secondary" onClick={() => seeAllNotifications()}>
          Ver todas
        </Button>
      </div>
    </div>
  );
};
