import { Text } from "@glorialabs/gl-components-module-ui";

type Props = {
  onlyView?: boolean
};
export const TemplateTermsAndCondition = ({ onlyView = false }: Props) => {
  return (
    <div className={onlyView ? "terms-conditions w-full flex-grow" : "terms-conditions overflow-auto w-full flex-grow"}>
      <Text className="text-base font-bold leading-9 pt-2">
        TÉRMINOS Y CONDICIONES DE USO DEL SITIO WEB Y POLÍTICA DE PROTECCIÓN
        DE DATOS PERSONALES
      </Text>
      <Text className="font-bold py-4">1. Disposiciones Generales</Text>
      <Text className="pb-4">
        Los presentes Términos y Condiciones tienen por objeto regular el
        acceso y uso del Portal Ganadero (en adelante, el “Portal”), lo cual
        comprende todo tipo de contenido, información, producto o servicio
        que se encuentre a disposición de los usuarios del Portal.
      </Text>
      <Text className="pb-4">
        Leche Gloria S.A. (en adelante, “Leche Gloria”), es la titular
        exclusiva del Portal y, en tal condición, tiene derecho de modificar
        en cualquier momento, sin expresión de causa y sin necesidad de
        aviso previo, el contenido, información, funcionalidad, productos,
        servicios y configuración que pudiera estar contenida en este
        Portal. En este sentido, el usuario del Portal reconoce y acepta que
        Leche Gloria en cualquier momento podrá interrumpir, desactivar,
        modificar o cancelar cualquiera de los elementos que componen el
        Portal o el acceso a los mismos.
      </Text>
      <Text className="pb-4">
        El acceso y uso de este sitio se rigen por los Términos y
        Condiciones, así como por la legislación que se aplique en Perú para
        cada supuesto derivado o vinculado por la interpretación o ejecución
        de los Términos y Condiciones. En consecuencia, todas las visitas y
        todos los actos que se realicen en este sitio, así como sus efectos
        jurídicos, quedarán regidos por estas reglas y sometidas a esa
        legislación. Los Términos y Condiciones contenidos en este
        instrumento formarán parte de todos los actos que se ejecuten o
        celebren a través de este Portal, entre los usuarios de esta
        plataforma y Leche Gloria.
      </Text>
      <Text>
        Leche Gloria se compromete a realizar su mejor esfuerzo para
        asegurar la disponibilidad continua de sus servicios, así como para
        asegurar la ausencia de errores en cualquier transmisión de
        información que pudiera tener lugar en las interacciones en el
        Portal; sin embargo, Leche Gloria no se hace responsable cuando la
        disponibilidad o funcionalidades del Portal se vean afectadas por la
        naturaleza misma del internet. Asimismo, los usuarios declaran
        conocer que el acceso a los servicios del Portal podría
        ocasionalmente verse suspendido o restringido por la realización de
        trabajos de mantenimiento, actualización o administración.
      </Text>
      <Text className="font-bold py-4">
        2. Creación y acceso a la cuenta de usuario
      </Text>
      <Text className="pb-4">
        El Portal está dirigido a personas naturales que cuenten con la
        mayoría de edad y personas jurídicas que se encuentren debidamente
        representadas, en ese sentido, Leche Gloria no tendrá
        responsabilidad alguna por el incumplimiento de este requisito. El
        usuario declara ser mayor de edad y disponer de la capacidad
        jurídica necesaria para sujetarse a los presentes Términos y
        Condiciones.
      </Text>
      <Text className="pb-4">
        Para acceder al Portal, el usuario debe estar previamente
        registrado, para lo cual deberá solicitar la creación de una cuenta
        principal, con la opción “Quiero registrarme” donde deberá ingresar
        el dato solicitado, es responsabilidad del usuario ingresar
        información veraz y actualizada. Luego de ingresada la solicitud,
        esta pasará a revisión de Leche Gloria y de ser aprobada se hará
        envío de las credenciales de acceso mediante correo electrónico. Es
        responsabilidad del usuario proteger su(s) clave(s) y acceso(s)
        frente a terceros.
      </Text>
      <Text className="pb-4">
        En caso el usuario con la cuenta principal requiera crear cuentas
        secundarias para usuarios que laboren en su entidad, deberá
        generarlas a través de la opción “Usuarios” del Portal en donde se
        ingresará la información solicitada para la creación de la cuenta
        secundaria. Las credenciales de estas cuentas serán compartidas vía
        correo electrónico. Es responsabilidad de la cuenta principal el uso
        de las cuentas secundarias y la protección de claves y accesos
        frente a terceros.
      </Text>
      <Text className="pb-4">
        Al ingresar al Portal por primera vez con las credenciales
        recibidas, se solicitará el cambio de contraseña y el ingreso de un
        código autenticador que será compartido vía mensaje de texto al
        número telefónico registrado. Es responsabilidad del usuario
        proteger sus claves y accesos frente a terceros.
      </Text>
      <Text className="pb-4">
        El usuario deberá mantener la confidencialidad de su contraseña
        registrada y cuidar el acceso a su computador, equipo móvil o
        dispositivo desde el cual tenga acceso al Portal. El usuario podrá
        cambiar o recuperar su contraseña mediante la opción “Olvidé mi
        contraseña”. Es responsabilidad del usuario proteger sus claves y
        accesos frente a terceros. Está prohibida la venta, cesión, préstamo
        o transferencia de la clave y/o cuenta de usuario, bajo cualquier
        título.
      </Text>
      <Text className="pb-4">
        Las claves y accesos al Portal son de uso personal y su entrega a
        terceros no involucra responsabilidad de Leche Gloria en caso su
        utilización sea indebida.
      </Text>
      <Text className="pb-4">
        Es facultad de Leche Gloria solicitar datos adicionales a los
        mínimos necesarios para registrar una cuenta o usar una cuenta
        registrada, para corroborar la información ingresada para el
        registro de un usuario. En caso no pueda confirmar la información
        ingresada, es facultad de Leche Gloria rechazar el registro o uso de
        la cuenta. Asimismo, en caso de que Leche Gloria tenga motivos
        fundados o comprobación de que se ha entregado información falsa o
        desactualizada, o de que se haya usado el Portal fraudulentamente o
        en forma contraria a estos Términos y Condiciones, o que un tercero
        haya usado la cuenta del usuario en el Portal, Leche Gloria podrá
        suspender o dar de baja la cuenta. En todo caso, el usuario podrá
        presentar una aclaración escrita, la cual deberá contener una
        explicación y/o justificación de la conducta realizada. El usuario
        también podrá comprobar que los antecedentes o datos entregados no
        son falsos. La evaluación de dichas explicaciones, justificaciones y
        antecedentes será hecha por Leche Gloria, a su arbitrio razonable.
        Leche Gloria se reserva el derecho de rechazar cualquier solicitud
        de registro o compra, o de cancelar un registro previamente
        aceptado, en caso de detectar cualquier conducta fraudulenta o uso
        contrario a la ley o a los presentes Términos y Condiciones.
      </Text>
      <Text>
        Si el usuario desea solicitar la eliminación de su cuenta, puede
        hacerlo escribiendo a la casilla de correo
        desarrollo.ganadero@gloria.com.pe
      </Text>
      <Text className="font-bold py-4">3. Comunicaciones Electrónicas</Text>
      <Text>
        La aceptación de estos Términos y Condiciones supone el
        consentimiento al envío de comunicaciones vinculadas a las
        actividades, información u operación del Portal identificándose
        Leche Gloria como remitente y en el asunto se detallará a qué se
        refiere.
      </Text>
      <Text className="font-bold py-4">4. Propiedad Intelectual</Text>
      <Text className="pb-4">
        Todos los contenidos incluidos en este Portal, como textos, material
        gráfico, logotipos, íconos de botones, códigos fuente, imágenes,
        audio clips, descargas digitales, compilaciones de datos, y en
        general el “look and feel” del Portal son propiedad de Leche Gloria
        o de sus proveedores de contenidos, y están protegidos por las leyes
        aplicables y convenios internacionales vigentes sobre propiedad
        intelectual. Los materiales gráficos, logotipos, encabezados de
        páginas, frases publicitarias, iconos de botones, textos escritos y
        nombres de servicios incluidos en este Sitio son marcas comerciales,
        creaciones o imágenes comerciales de propiedad de Leche Gloria.
        Dichas marcas, creaciones e imágenes comerciales no se pueden usar
        con relación a ningún producto o servicio que pueda causar confusión
        entre los clientes y en ninguna forma que desprestigie o desacredite
        a Leche Gloria.
      </Text>
      <Text>
        Todos los derechos no expresamente otorgados en estos Términos y
        Condiciones son reservados por Leche Gloria o sus cesionarios,
        proveedores, editores, titulares de derechos u otros proveedores de
        contenidos. Ningún producto, imagen o sonido pueden ser
        reproducidos, duplicados, copiados, vendidos, revendidos, visitados
        o explotados para ningún fin, en todo o en parte, sin el
        consentimiento escrito previo de Leche Gloria. No se puede enmarcar
        o utilizar técnicas de enmarcación para encerrar alguna marca
        comercial, logotipo u otra información registrada o patentada
        (incluyendo imágenes, texto, disposición de páginas, o formulario)
        de Leche Gloria, sin nuestro consentimiento escrito previo. Tampoco
        se puede usar meta etiquetas ni ningún otro “texto oculto” que use
        el nombre o marcas comerciales de Leche Gloria, sin autorización
        escrita previa de esta empresa. Se prohíbe hacer un uso indebido de
        este Portal o de estas marcas, licencias o patentes.{" "}
      </Text>
      <Text className="font-bold py-4">5. Domicilio y Jurisdicción </Text>
      <Text>
        Para efecto de las interacciones que se realicen a través del
        Portal, Leche Gloria señala como su domicilio el ubicado en la
        avenida República de Panamá N° 2461, Urbanización Santa Catalina,
        distrito de La Victoria, provincia y departamento de Lima, República
        del Perú y el usuario renuncia a la jurisdicción de su domicilio,
        sometiéndose a la competencia de las autoridades administrativas o
        judiciales del Cercado de Lima.
      </Text>
      <Text className="font-bold py-4">
        6. Cláusula sobre el tratamiento de datos personales
      </Text>
      <Text>
        El uso del Portal, con relación al tratamiento de datos personales, sigue las mismas
        condiciones descritas en la Política de Privacidad de Leche Gloria, para acceder se debe hacer clic en:
        <a
          className="text-[#0A0036] font-bold"
          href="https://www.gloria.com.pe/images/Pol%C3%ADtica%20de%20Privacidad.pdf"
          target="_blank"
        >
          https://www.gloria.com.pe/images/Política de Privacidad.pdf
        </a>
      </Text>
      <Text className="font-bold py-4">7. Políticas de Seguridad </Text>
      <Text className="pb-4">
        Leche Gloria adoptará las medidas necesarias y prudentes para
        resguardar la seguridad de los datos y clave secreta del usuario. En
        caso de detectarse cambios en la información que ha registrado en el
        Portal, o bien, ante cualquier irregularidad en las operaciones
        relacionadas con la identificación o datos del usuario, Leche Gloria
        podrá contactar por vía telefónica o correo electrónico al usuario,
        a fin de corroborar los datos e intentar evitar posibles fraudes.
      </Text>
      <Text>
        En caso de no poder establecer el contacto en un plazo de 72 horas,
        atendiendo a la necesidad de salvaguardar la seguridad de los
        usuarios, Leche Gloria podrá optar por suspender el acceso al Portal
        del usuario afectado, circunstancia que será informada vía
        telefónica o por correo electrónico, con las indicaciones para que
        se pueda reestablecer el acceso.{" "}
      </Text>
      <Text className="font-bold py-4">
        8. Validez y Vigencia de los Términos y Condiciones{" "}
      </Text>
      <Text>
        Leche Gloria podrá actualizar y/o modificar los presentes Términos y
        Condiciones sin previo aviso. Por este motivo recomendamos revisar
        los Términos y Condiciones cada vez que utilice la página web, ya
        que la versión aplicable será la que se encuentre colgada al momento
        de la consulta. En caso el cambio en cuestión sea significativo,
        Leche Gloria notificará al usuario a través de un banner, pop-up,
        una notificación push o enviará un correo electrónico. Toda
        modificación a los Términos y Condiciones que disponga Leche Gloria
        solo podrá tener efectos a futuro y se contarán desde su publicación
        en la web.{" "}
      </Text>
    </div>
  );
};
