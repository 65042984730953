import qs from "qs";
import { apiCognito } from "../api";
import apiConfig from "../../configs/api.config";

const { COGNITO_OAUTH2 } = apiConfig;

export const getCognitoToken = async (): Promise<string> => {
  const data = {
    client_id:     COGNITO_OAUTH2.clientId,
    client_secret: COGNITO_OAUTH2.clientSecret,
    grant_type:    COGNITO_OAUTH2.grantType
  };

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    "Cookie":       "XSRF-TOKEN=1f6fd003-2c32-4d87-85fb-9575e84856d1"
  };

  const config = {
    method:        "post",
    maxBodyLength: Infinity,
    url:           "/",
    headers:       headers,
    data:          data
  };

  try {
    const response = await apiCognito(config);
    return response.data.access_token;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const refreshTokenCognito = async () => {
  const refreshToken = localStorage.getItem("refresh_token_cognito");

  const data = qs.stringify({
    client_id:     COGNITO_OAUTH2.clientId,
    client_secret: COGNITO_OAUTH2.clientSecret,
    grant_type:    "refresh_token",
    refresh_token: refreshToken
  });

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    "Cookie":       "XSRF-TOKEN=1f6fd003-2c32-4d87-85fb-9575e84856d1"
  };

  const config = {
    method:        "post",
    maxBodyLength: Infinity,
    url:           "/",
    headers:       headers,
    data:          data
  };

  try {
    const response = await apiCognito(config);
    return response.data.id_token;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
