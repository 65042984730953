import * as Yup from "yup";

export interface IVerificationCodeForm {
  verificationCodePassword: string;
  newPassword: string;
  newPasswordRepeat: string;
}

export const initialValuesForgetPassword = {
  verificationCodePassword: "",
  newPassword:              "",
  newPasswordRepeat:        ""
};

export const validationSchemaForgetPassword = Yup.object({
  verificationCodePassword: Yup.number()
    .typeError("El código de verificación deben ser números")
    .required("Debe completar el codigo de verificación"),
  newPassword: Yup.string()
    .required("La contraseña es requerida")
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*\W).{8,}$/,
      "La contraseña debe tener al menos 8 caracteres, 1 mayúscula, 1 minúscula, 1 carácter especial y 1 número"
    ),
  newPasswordRepeat: Yup.string()
    .oneOf(
      [Yup.ref("newPassword"), undefined],
      "Las contraseñas deben coincidir"
    )
    .required("Debe completar este campo")
});
