import {
  DetailInfo, GeneralInfo, ILoanInfo, IPurchaseInfo, PurchaseDetail
} from "../interface";

interface IarrayLoanData {
  generalInfo: GeneralInfo;
  detailInfo: DetailInfo;
  tableInfo: ILoanInfo[];
  isPaidOff: boolean;
  nextDueDate: string;
}

interface IarrayPurchaseData {
  generalInfo: GeneralInfo;
  detailInfo: DetailInfo;
  tableInfo: IPurchaseInfo[];
  purchaseInfo: PurchaseDetail[];
  isPaidOff: boolean;
  nextDueDate: string;
}

type IAdapterParam = IarrayLoanData[] | IarrayPurchaseData[];

// export const sortAdaptedArray = (adaptedArray: IAdapterParam) => {
//   return adaptedArray.sort((a, b) => {
//     if (a.isPaidOff && !b.isPaidOff) {return 1;}
//     if (!a.isPaidOff && b.isPaidOff) {return -1;}
//     return new Date(a.nextDueDate).getTime() - new Date(b.nextDueDate).getTime();
//   });
// };

export const sortAdaptedArray = (adaptedArray: IAdapterParam) => {
  return adaptedArray.sort((a, b) => {
    if (a.isPaidOff && !b.isPaidOff) {
      return 1;
    }
    if (!a.isPaidOff && b.isPaidOff) {
      return -1;
    }

    const getOldestInstallmentDate = (param: IarrayLoanData | IarrayPurchaseData) => {
      if (param.isPaidOff) {
        return param.tableInfo.reduce((oldestDate, installment) => {
          const installmentDate = new Date(installment.installment_due_date);
          return installmentDate < oldestDate ? installmentDate : oldestDate;
        }, new Date());
      } else {
        return new Date(param.nextDueDate);
      }
    };

    const dateA = getOldestInstallmentDate(a as IarrayLoanData | IarrayPurchaseData);
    const dateB = getOldestInstallmentDate(b as IarrayLoanData | IarrayPurchaseData);

    return dateA.getTime() - dateB.getTime();
  });
};
