import {
  Dropdown,
  Text,
  DatePicker,
  Button,
  Checkbox,
  Spinner,
  IOption
} from "@glorialabs/gl-components-module-ui";
import * as utils from "../../../utils/localstorage";
import { Formik, FormikHelpers } from "formik";
import { PeriodId } from "../../../enum/periodId.enum";
import { FormatDownloadId } from "../../../enum/fotmatDownloadId.enum";
import { useMutation } from "react-query";
import { getDownloadQualityInfo } from "../../../services/quality/api";
import moment from "moment";
import { DateFormat } from "../../../enum/DateFormat.enum";
import { saveAs } from "file-saver";
import { QualityIndicator } from "../../../enum/QualityIndicator.enum";
import * as ExcelJS from "exceljs";
import { ConfirmationDownload } from "./screens/ConfirmationDownload";
import { EmptyDownload } from "./screens/EmptyDownload";
import { useNavigate } from "react-router-dom";
import { ErrorDownload } from "./screens/ErrorDownload";
import { Cowfarmer } from "../../../context/AuthInterface";
import { useState } from "react";
import cn from "classnames";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { navBar$ } from "../../../utils/observables/subject.obs";
import {
  getBodyFormat, getHeaders, sortMilkRecords
} from "./utils/downloadInfo.utils";
import { IDownloadQualityInfo } from "../../../services/quality/interface";
import {
  IDownloadDataForm, downloadInfoSchema, initialValues
} from "./form";
import { hasAnyOptionSeleected } from "./utils/hasAnyOptionSelected";
import { getIndicatorIds } from "./utils/getIndicatorIds.utils";
import { IBodyDownloadQualityInfo } from "../../../services/quality/request.interface";
import { periodDataOptions } from "./constants/periodDataOptions.const";
import { formatDownloadOptions } from "./constants/formatDownloadOptions.const";

function DownloadInfoPage() {
  const navigate = useNavigate();

  const [screenKey, setScreenKey] = useState<keyof typeof screens>("main");
  const cowbarns = utils.getStorageByField("cowbarns");

  const doc = new jsPDF();

  const downloadExcel = async (
    data: IDownloadQualityInfo[],
    periodId: PeriodId,
    indicatorIds: number[],
    name = "datos_de_leche"
  ) => {

    async function getWorkbook(
      data: IDownloadQualityInfo[],
      periodId: PeriodId,
      indicatorIds: number[]
    ) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Datos de Leche");

      // Agregar headers al worksheet
      worksheet.columns = getHeaders(indicatorIds);

      const dataMapped = getBodyFormat(data, periodId);

      // Agregar datos
      dataMapped.forEach((lotMilk) => {

        // Agregar filas
        worksheet.addRow({
          lot_milk_date: lotMilk.lot_milk_date,
          fat:           lotMilk.fat,
          protein:       lotMilk.protein,
          UFC:           lotMilk.UFC,
          RCS:           lotMilk.RCS,
          volume:        lotMilk.volume,
          temperature:   lotMilk.temperature
        });
      });

      // change default width column
      if (periodId === PeriodId.QUINCENAL) {
        worksheet.getColumn("lot_milk_date").width = 30;
      } else if (periodId === PeriodId.LOTE) {
        worksheet.getColumn("lot_milk_date").width = 20;
      }

      // Estilos
      worksheet.getRow(1).alignment = { horizontal: "center" };
      worksheet.getRow(1).font = { bold: true };

      const milesComma = "#,##0";

      if (indicatorIds.includes(QualityIndicator.RANGE_VOLUME)) {
        worksheet.getColumnKey("volume").numFmt = milesComma;
      }

      if (indicatorIds.includes(QualityIndicator.RANGE_UFC)) {
        worksheet.getColumnKey("UFC").numFmt = milesComma;
      }

      if (indicatorIds.includes(QualityIndicator.RANGE_RCS)) {
        worksheet.getColumnKey("RCS").numFmt = milesComma;
      }

      return workbook;
    }

    const workbook = await getWorkbook(data, periodId, indicatorIds);

    // Guardar el archivo Excel
    const buffer = await workbook.xlsx.writeBuffer();
    setScreenKey("success");
    saveAs(new Blob([buffer]), name);
  };

  function getNameFileFormat(name: string, extension: string) {
    const cowfarmerLS = utils.getStorage2<Cowfarmer>("cowfarmer");

    const UNDERCORE_FORMAT_DATE = "DD_MM_YYYY";
    const SPACES_AND_GUIONS = /[-\s]+/g;

    const dniOrRuc = cowfarmerLS?.ruc?.trim() ?? cowfarmerLS?.dni?.trim();
    const groupAndParcel = name.replace(SPACES_AND_GUIONS, "_");
    const currentDate = moment().format(UNDERCORE_FORMAT_DATE);
    return `Resultados_acopio_${dniOrRuc}_${groupAndParcel}_${currentDate}.${extension}`;

  }

  const downloadPDF = async (
    data: IDownloadQualityInfo[],
    periodId: PeriodId,
    indicatorIds: number[],
    name = "datos_de_leche"
  ) => {
    const headers = getHeaders(indicatorIds);
    const dataMapped = getBodyFormat(data, periodId);

    autoTable(doc, {
      body:       dataMapped,
      columns:    headers,
      theme:      "grid",
      headStyles: {
        halign: "center"
      },
      bodyStyles: {
        halign: "right"
      },
      columnStyles: {
        lot_milk_date: { halign: "left" }
      }
    });

    doc.save(`${name}`);
  };

  function onClickGoBack() {
    setScreenKey("main");
  }

  function onClickSuccessDownload() {
    navigate("/inicio");
  }

  const { mutateAsync: downloadInfoMutation } = useMutation({
    mutationFn: async (payload: IBodyDownloadQualityInfo) =>
      await getDownloadQualityInfo(payload),
    onSuccess: async (data, variables, _) => {
      if (!data.length) {
        setScreenKey("empty");
        return;
      }

      const cowbarnOptionSelected =
        cowbarns.groupAndPlot.find((gp) => gp.value === variables.cowbarnId)
          ?.label ?? "Datos de leche";

      //! Si el periodo es lote entonces se ordena por lotmilk id y fecha para que coincida con tabla de graficos
      if (variables.periodId === PeriodId.LOTE) {
        data = sortMilkRecords(data);
      }

      if (variables.downloadType === FormatDownloadId.PDF) {
        const nameFile = getNameFileFormat(cowbarnOptionSelected, "pdf");
        await downloadPDF(
          data,
          variables.periodId,
          variables.indicatorIds,
          nameFile
        );

        setScreenKey("success");
        return;
      }

      const nameFile = getNameFileFormat(cowbarnOptionSelected, "xlsx");
      await downloadExcel(
        data,
        variables.periodId,
        variables.indicatorIds,
        nameFile
      );
    },
    onError: (error, _, __) => {
      console.log({ error });
      setScreenKey("error");
    }
  });

  const widthMaxMobile = "max-w-[361px]";

  navBar$.getValue().subscribe((evento) => {
    if (evento["NAVBAR_DOWNLOAD"]) {

      setScreenKey("main");
    }
  });

  const screens = {
    loading: (
      <div className="w-full flex justify-center items-center h-[calc(100vh-225px)]">
        <div>
          <Spinner />
          <Text>Preparando tu descarga</Text>
        </div>
      </div>
    ),
    main: (
      <Formik
        initialValues={initialValues}
        onSubmit={(
          values: IDownloadDataForm,
          helper: FormikHelpers<IDownloadDataForm>
        ) => {
          setScreenKey("loading");
          const isValidForm = hasAnyOptionSeleected(values);

          if (!isValidForm) {
            return;
          }

          // Clean form
          helper.resetForm();
          helper.setFieldValue("option_lotmilk", false);
          helper.setFieldValue("option_temperature", false);
          helper.setFieldValue("option_fat", false);
          helper.setFieldValue("option_ufc", false);
          helper.setFieldValue("option_rcs", false);
          helper.setFieldValue("downloadFormatId", null);
          helper.setFieldValue("periodId", null);
          helper.setFieldValue("dateFrom", "");
          helper.setFieldValue("dateTo", "");

          downloadInfoMutation({
            cowbarnId:    (+values.cowbarnId === 0) ? + cowbarns.groupAndPlot[0]?.value : +values.cowbarnId,
            periodId:     values.periodId!,
            indicatorIds: getIndicatorIds(values),
            dateFrom:     moment(values.dateFrom).format(DateFormat.POSTGRES),
            dateTo:       moment(values.dateTo).format(DateFormat.POSTGRES),
            downloadType: values.downloadFormatId!
          });
        }}
        validationSchema={downloadInfoSchema}
      >
        {(formik) => (
          <form
            className="flex flex-col justify-center items-center w-full xl:justify-start xl:items-center "
            onSubmit={formik.handleSubmit}
          >
            <div className="">
              <div className="flex justify-start flex-col font-['Roboto'] md:flex-row xl:justify-normal ">
                {/* start col 1 */}
                <div
                  className={`${widthMaxMobile} w-full md:mr-[99px] xl:mr-0 `}
                >
                  {cowbarns.groupAndPlot?.length > 1 && (
                    <Dropdown
                      classname={cn("mb-4")}
                      {...formik.getFieldProps("cowbarnId")}
                      options={cowbarns.groupAndPlot}
                      label="Grupo y parcela"
                      suggestionText="Selecciona el grupo y parcela de tu establo"
                      value={cowbarns.groupAndPlot?.find(
                        (option) => option.value === formik.values.cowbarnId
                      ) || cowbarns.groupAndPlot[0] }
                      onChange={(option: IOption | null) =>
                        formik.setFieldValue("cowbarnId", option!.value)
                      }
                    />
                  )}
                  <div className={"leading-6 mb-4 ml-1 w-[361px]"}>
                    <Text size="base" bold>
                      Selecciona los resultados que deseas exportar
                    </Text>
                  </div>
                  {/* start checkbox */}
                  <div className="w-[361px]">
                    <div className={`${widthMaxMobile} mb-2 ml-[5px]`}>
                      <label htmlFor="option_lotmilk" className="flex gap-2">
                        <div className="w-5 mt-[2px]">
                          <Checkbox
                            id="option_lotmilk"
                            {...formik.getFieldProps("option_lotmilk")}
                            onChange={(value) =>
                              formik.setFieldValue("option_lotmilk", value)
                            }
                            checked={formik.values.option_lotmilk}
                          />
                        </div>
                        <p className="font-normal tracking-wide leading-6">
                          Leche acopiada (kg)
                        </p>
                      </label>
                    </div>

                    <div className={`${widthMaxMobile} mb-2 ml-[5px]`}>
                      <label htmlFor="option_temperature" className="flex gap-2">
                        <div className="w-5 h-5 mt-[2px]">
                          <Checkbox
                            id="option_temperature"
                            {...formik.getFieldProps("option_temperature")}
                            onChange={(value) =>
                              formik.setFieldValue("option_temperature", value)
                            }
                            checked={formik.values.option_temperature}
                          />
                        </div>
                        <p className="font-normal tracking-wide leading-6">
                          Temperatura (°C)
                        </p>
                      </label>
                    </div>

                    <div className={`${widthMaxMobile} mb-2 ml-[5px]`}>
                      <label htmlFor="option_fat" className="flex gap-2">
                        <div className="w-5 h-5 mt-[2px]">
                          <Checkbox
                            id="option_fat"
                            {...formik.getFieldProps("option_fat")}
                            onChange={(value) =>
                              formik.setFieldValue("option_fat", value)
                            }
                            checked={formik.values.option_fat}
                          />
                        </div>
                        <p className="font-normal tracking-wide leading-6">
                          Grasa (%)
                        </p>
                      </label>
                    </div>

                    <div className={`${widthMaxMobile} mb-2 ml-[5px]`}>
                      <label htmlFor="option_protein" className="flex gap-2">
                        <div className="w-5 h-5 mt-[2px]">
                          <Checkbox
                            id="option_protein"
                            {...formik.getFieldProps("option_protein")}
                            onChange={(value) =>
                              formik.setFieldValue("option_protein", value)
                            }
                            checked={formik.values.option_protein}
                          />
                        </div>
                        <p className="font-normal tracking-wide leading-6">
                          Proteína (%)
                        </p>
                      </label>
                    </div>

                    <div className={`${widthMaxMobile} mb-2 ml-[5px]`}>
                      <label htmlFor="option_ufc" className="flex gap-2">
                        <div className="w-5 h-5 mt-[2px]">
                          <Checkbox
                            id="option_ufc"
                            {...formik.getFieldProps("option_ufc")}
                            onChange={(value) =>
                              formik.setFieldValue("option_ufc", value)
                            }
                            checked={formik.values.option_ufc}
                          />
                        </div>
                        <div className="font-normal tracking-wide leading-6">
                          Unidades formadoras de colonias
                          <br /> <span>(ufc/ml)</span>
                        </div>
                      </label>
                    </div>

                    <div className={`${widthMaxMobile} mb-2 ml-[5px]`}>
                      <label htmlFor="option_rcs" className="flex gap-2">
                        <div className="w-5 h-5 mt-[2px]">
                          <Checkbox
                            id="option_rcs"
                            {...formik.getFieldProps("option_rcs")}
                            onChange={(value) =>
                              formik.setFieldValue("option_rcs", value)
                            }
                            checked={formik.values.option_rcs}
                          />
                        </div>
                        <p className="font-normal tracking-wide leading-6">
                          Recuento de células somáticas
                          <br />
                          <span>(rcs/ml)</span>
                        </p>
                      </label>
                    </div>
                    {/* Boton subtmit para tablets y desktops */}
                    <Button
                      className={`${widthMaxMobile} hidden w-[361px] md:w-[142px] md:block`}
                      type="submit"
                      disabled={
                        !formik.isValid ||
                        formik.isSubmitting ||
                        !hasAnyOptionSeleected(formik.values)
                      }
                      variant="primary"
                    >
                      Descargar
                    </Button>
                  </div>
                  {/* end checkbox */}
                </div>
                {/* end col 1 */}

                {/* start col 2 */}
                <div className={`${widthMaxMobile} xl:max-w-full  w-full`}>
                  {/* start dropwdowns */}
                  <div
                    className={`${widthMaxMobile} xl:flex ${cn({
                      "xl:mt-32": cowbarns.groupAndPlot?.length > 1
                    })}`}
                  >
                    <div className="mt-4 md:mt-0 xl:ml-[22px] xl:mr-4">
                      <Dropdown
                        options={periodDataOptions}
                        label="Selecciona la frecuencia de resultados"
                        placeholder="Selecciona una opción"
                        onChange={(option: IOption | null) =>
                          formik.setFieldValue("periodId", option!.value)
                        }
                        value={periodDataOptions.find(option => option.value === formik.values.periodId)}
                      />
                    </div>

                    <div className="mt-4 xl:mt-0">
                      <Dropdown
                        options={formatDownloadOptions}
                        label="Selecciona el formato de descarga"
                        placeholder="Selecciona una opción"
                        onChange={(option: IOption | null) =>
                          formik.setFieldValue("downloadFormatId", option!.value)
                        }
                        value={formatDownloadOptions.find(option => option.value === formik.values.downloadFormatId)}

                      />
                    </div>
                  </div>
                  {/* end dropwdowns */}

                  {/* start datepickers */}
                  <div className=" xl:flex">
                    <div className="xl:mx-4">
                      <Text className="mt-4 mb-5 w-[361px]" size="base" bold>
                        Seleccione un periodo de tiempo
                      </Text>
                      <div className="w-[361px] ">
                        <div>
                          <DatePicker
                            className="w-[361px]"
                            label="Desde"
                            placeholder="Selecciona una fecha de inicio"
                            onChange={(option) =>
                              formik.setFieldValue(
                                "dateFrom",
                                option?.toString() ?? undefined
                              )
                            }
                            value={formik.values.dateFrom}
                            disabled={[
                              {
                                from: new Date(
                                  moment(formik.values.dateTo || new Date())
                                    .subtract(100, "year")
                                    .toString()
                                ),
                                to: new Date(
                                  moment(formik.values.dateTo || new Date())
                                    .startOf("day")
                                    .subtract(1, "year")
                                    .subtract(1, "day")
                                    .toString()
                                )
                              },
                              {
                                to:   new Date(moment().add(1, "day").toString()),
                                from: new Date(
                                  moment().add(100, "year").toString()
                                )
                              },
                              (day: Date) => {
                                const date = moment(day).endOf("month");

                                if (formik.values.periodId === PeriodId.QUINCENAL) {
                                  const whiteList = [1, 15, date.date()];

                                  return !whiteList.includes(day.getDate());
                                }

                                return false;
                              }
                            ]}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 xl:mt-[3.75rem]">
                      <DatePicker
                        className="w-[361px]"
                        label="Hasta"
                        placeholder="Selecciona una fecha de fin"
                        onChange={(option) =>
                          formik.setFieldValue(
                            "dateTo",
                            option?.toString() ?? undefined
                          )
                        }
                        value={formik.values.dateTo}
                        // value={formik.values.dateTo}
                        disabled={[
                          {
                            from: new Date(moment().add(1, "day").toString()),
                            to:   new Date(moment().add(100, "year").toString())
                          },
                          (day: Date) => {
                            const date = moment(day).endOf("month");

                            if (formik.values.periodId === PeriodId.QUINCENAL) {
                              const whiteList = [1, 15, date.date()];

                              return !whiteList.includes(day.getDate());
                            }

                            return false;
                          }
                        ]}
                      />
                    </div>
                    {/* end datepickers */}
                  </div>
                </div>
                {/* end col 2 */}
              </div>

              {/* start submit button */}
              {/* Boton para pantallas mobile */}
              <div
                className={"flex justify-center items-center w-full md:block md:w-[821px] xl:justify-normal "}
              >
                <Button
                  className={`${widthMaxMobile} w-[361px] md:hidden`}
                  type="submit"
                  disabled={
                    !formik.isValid ||
                    formik.isSubmitting ||
                    !hasAnyOptionSeleected(formik.values)
                  }
                  variant="primary"
                >
                  Descargar
                </Button>
              </div>
              {/* end submit button */}
            </div>
          </form>
        )}
      </Formik>
    ),
    success: <ConfirmationDownload onClick={onClickSuccessDownload} />,
    error:   <ErrorDownload onClick={onClickSuccessDownload} />,
    empty:   <EmptyDownload onClick={onClickGoBack} />
  };

  return screens[screenKey];
}

export default DownloadInfoPage;
