import { FormikProps } from "formik";
import "./../../login.css";
import {
  Button, Input, Text
} from "@glorialabs/gl-components-module-ui";
import {
  Dispatch, FunctionComponent, SetStateAction
} from "react";
import Light from "../../../../assets/Light.svg";

interface IRegisterAuth {
  formik: FormikProps<{ rucOrDni: string }>;
  isLoadingChange: boolean;
  setScreen: Dispatch<
    SetStateAction<
      | "login"
      | "validateMFA"
      | "newPassword"
      | "completeEmail"
      | "confirmationForgetPassword"
      | "verificationCode"
      | "registerAuth"
      | "alreadyExistRegister"
      | "confirmationRegister"
      | "notFoundRegister"
    >
  >;
}

export const RegisterAuth: FunctionComponent<IRegisterAuth> = ({
  formik,
  isLoadingChange,
  setScreen
}) => {
  const { values, handleSubmit, errors } = formik;

  return (
    <>
      <p className="mb-8 text-2xl	color-[#000000] tracking-wider">
        Regístrate al Portal Ganadero
      </p>

      <form onSubmit={handleSubmit}>
        <Input
          {...formik.getFieldProps("rucOrDni")}
          label="Número de RUC o DNI"
          className="w-[361px] h-fit"
          suggestionText="Ingresa tu número de RUC o DNI"
          maxLength={12}
          onChange={(e) => {
            const value = e.target.value;
            if (/^\d*$/.test(value)) {
              // Esta expresión regular solo permite números
              formik.handleChange(e);
            }
          }}
          variant={errors.rucOrDni ? "error" : undefined}
          feedback={errors.rucOrDni ?? undefined}
          // onChange={(e) => console.log(e)}
          // value={""}
        />

        <div className="flex flex-row rounded-2xl bg-[#E4F0FF] w-[361px] p-4 mt-4">
          <div className="flex justify-center items-center lex-1">
            <Light />
          </div>
          <div className="flex-[5_5_0%] ml-4">
            <p className="text-sm text-[#07196D]">
              Ingresa el número del documento con el que te registraste como
              proveedor ganadero de Gloria.
            </p>
          </div>
        </div>

        <Button
          name="Iniciar Sesión"
          variant="primary"
          className="w-[361px] rounded-2xl mt-8"
          type="submit"
          loading={isLoadingChange}
          disabled={!(
            values.rucOrDni?.trim().length === 8 ||
            values.rucOrDni?.trim().length === 11 ||
            values.rucOrDni?.trim().length === 12
          )}
        >
          Siguiente
        </Button>
        <Text
          isButton
          className="rounded-2xl mt-4 align-middle text-center"
          onClick={() => {
            setScreen("login");
            formik.resetForm();
          }}
        >
          Regresar
        </Text>
      </form>
    </>
  );
};
