import {
  useCallback,
  useContext, useMemo, useState
} from "react";
import {
  getActives,
  getAssetTypes,
  getSummaryActives
} from "../../services/services/api";
import { debounce } from "lodash";
import { useQuery } from "react-query";
import {
  Card,
  Input,
  Pagination,
  Text,
  Dropdown,
  Table,
  Spinner,
  IOption
} from "@glorialabs/gl-components-module-ui";
import { AuthContext } from "../../context/AuthContext";
import { activeResponsiveColumns, activesColumns } from "./utils/activesColumns";
import { ONE_HOUR } from "../../constant/staleTimes";

export default function ActivesPage() {
  const itemsPerPage = 6;
  const [filter, setFilter] = useState<{
    type: number | undefined;
    code: string | undefined;
  }>({ type: undefined, code: undefined });
  const [valueSearch, setValueSearch] = useState<string>("");
  const [type, setType] = useState<{
    label: string;
    value: number | string;
  } | null>(null);
  const [offset, setOffset] = useState<number>(0);
  const [page, setPage] = useState<number>(1);

  const { cowfarmer } = useContext(AuthContext);

  const { data: activesData, isLoading } = useQuery(
    ["posts", offset, filter],
    ({ signal }) =>
      getActives({
        params: {
          cowfarmerId:   cowfarmer!.id,
          limit:         itemsPerPage,
          offset,
          codeEquipment: filter.code,
          typeProductId: filter.type
        },
        signal
      }),
    { staleTime: ONE_HOUR }
  );

  const { data: dataSummaryActives } = useQuery("summaryActives", () =>
    getSummaryActives(cowfarmer!.id),
  {
    staleTime: ONE_HOUR
  }
  );

  const totalPages = Math.ceil((activesData?.total ?? 0) / itemsPerPage);

  const { data: dataTypes } = useQuery("activesTypes", getAssetTypes);

  const onSelectChange = (
    selectedOption: { label: string; value: number | string } | null
  ) => {
    setOffset(0);
    setType(selectedOption);
    if (selectedOption) {
      setFilter({
        type: selectedOption.value as number,
        code: undefined
      });
    } else {
      setFilter({
        type: undefined,
        code: undefined
      });
    }
    setValueSearch("");
  };

  const debouncedSearch = useCallback(
    debounce((value?: string) => {
      setOffset(0);
      setFilter(({ type: undefined, code: value }));
    }, 500),
    []
  );

  const onSearch = (value: string) => {
    if (valueSearch === value) {
      return;
    }
    setValueSearch(value);
    if (value.length >= 3) {
      debouncedSearch(value);
    } else if (filter.code !== undefined && !value.length) {
      debouncedSearch(undefined);
    }
    if (type) {setType(null);}
  };

  const onChangePage = (page: number) => {
    setPage(page);
    setOffset((page - 1) * itemsPerPage);
  };

  const optionsTypes = useMemo(() => {
    return dataTypes?.map((item) => ({
      label: item.description,
      value: item.id
    }));
  }, [dataTypes]);

  if (isLoading === null)
  {return (
    <div
      className="bg-red flex justify-center align-middle items-center flex-col"
      style={{ height: "calc(100vh - 210px)" }}
    >
      <Spinner />
      <Text size="2xl" className="text-[#07196D]">
        Cargando
      </Text>
    </div>
  );}

  const renderSummaryActives = (data: { [key: string]: number }): string => {
    const formatQuantity = (quantity: number) => {
      return quantity === 1 ? "01" : quantity.toString().padStart(2, "0");
    };

    const keys = Object.keys(data);

    if (keys.length === 1) {
      const key = keys[0];
      const quantityFormatted = formatQuantity(data[key]);
      return `Actualmente tienes ${quantityFormatted} ${data[key] > 1 ? key + "s" : key}`;
    }

    const text = keys
      .map((key, index) => {
        const quantityFormatted = formatQuantity(data[key]);
        if (index === keys.length - 1) {
          return `y ${quantityFormatted} ${data[key] > 1 ? key + "s" : key}`;
        } else if (index === keys.length - 2) {
          return `${quantityFormatted} ${data[key] > 1 ? key + "s" : key} `;
        } else {
          return `${quantityFormatted} ${data[key] > 1 ? key + "s" : key}, `;
        }
      })
      .join("");

    return `Actualmente tienes ${text}`;
  };

  const renderActivesData = () => {

    if (activesData?.data.length) {
      return (activesData?.data || []).map((item) => (
        <Card.Container
          key={item.id}
          className="bg-[#F0EDFF] rounded-lg mb-4 p-4 text-sm"
        >
          <Card.Header className="mb-3">
            <p className="font-bold text-sm">
              {item.product.description}
            </p>
          </Card.Header>
          <Card.Body>
            <p className="mb-3">
              Cod: {item.assets_code}
              <span className="font-bold ml-2">
                Tipo: {item.product.typeProduct.description}
              </span>
            </p>
            <Table
              data={[item]}
              columns={activeResponsiveColumns}
              backgroundColor="#transparent"
              className="!p-0"
              rowKey="id"
            />
          </Card.Body>
        </Card.Container>));
    } else {
      return (
        <div className="bg-[#F0EDFF] rounded-2xl w-full px-6 py-8">
          No hay activos que mostrar
        </div>
      );
    }
  };

  return (
    <div>
      <Text size="4xl" bold={true} className="text-3xl mb-6 w-[361px]">
        Activos
      </Text>
      <div className="flex gap-4 flex-col-reverse lg:flex-row">
        <Dropdown
          options={optionsTypes || []}
          label="Filtrar por tipo de activo"
          placeholder="Selecciona una opción"
          allowClear
          value={type}
          classname="w-full lg:w-[361px]"
          onChange={(activeType) => {
            return onSelectChange(activeType as IOption);
          }}
        />
        <Input
          label="Buscar por código de equipo"
          className="w-full lg:w-[361px] h-full"
          placeholder="Ingresa un código de equipo"
          onChange={(e) => {
            if (!e.target.value.trim()) {
              onSearch("");
            } else {
              onSearch(e.target.value);
            }
          }}
          value={valueSearch}
        />
      </div>

      <div className="hidden lg:flex">
        <Table
          className="mb-3 mt-8"
          columns={activesColumns}
          emptyMessage="No hay activos que mostrar"
          data={activesData?.data}
          pagination={
            activesData?.data.length
              ? {
                position: "start",
                total:    Math.ceil((activesData?.total ?? 10) / itemsPerPage),
                onChange: onChangePage
              }
              : undefined
          }
          paginationOnServer={true}
          loading={isLoading}
          rowKey={"id"}
          backgroundColor="#F0EDFF"
        />
      </div>

      <div className="lg:hidden mt-8">
        {isLoading ? (
          <div className="bg-red flex justify-center align-middle items-center flex-col">
            <Spinner />
            <Text size="2xl" className="text-[#07196D]">
              Cargando
            </Text>
          </div>
        ) : renderActivesData()}
      </div>
      {dataSummaryActives &&
        Boolean(Object.keys(dataSummaryActives).length) &&
        !isLoading && (
        <p className="font-bold text-xs mt-3">
          {renderSummaryActives(dataSummaryActives)}
        </p>
      )}
      {Boolean(activesData?.data.length) && totalPages > 1 && (
        <div className="flex justify-center mt-4 lg:hidden">
          <Pagination
            totalPages={totalPages}
            page={page}
            onChange={onChangePage}
          />
        </div>
      )}
    </div>
  );
}
