import { type StateCreator, create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { readAllNews } from "../services/services/api";

interface NewsState {
  hasUnreadNews: boolean;
}

interface Actions {
  readAllNews: (id: number) => Promise<void>;
  setHasUnreadNews: (hasUnreadNews: boolean) => void;
}

const storeAPI: StateCreator<NewsState & Actions, [["zustand/devtools", never]]> = (set) => ({
  hasUnreadNews: false,
  readAllNews:   async (id: number) => {
    await readAllNews(id);
    set({ hasUnreadNews: false });
  },
  setHasUnreadNews: (hasUnreadNews) => set({ hasUnreadNews })
});

export const useNewsStore = create<NewsState & Actions>()(
  devtools(
    persist(storeAPI, {
      name: "news-store"
    })
  )
);
