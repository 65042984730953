import { Button } from "@glorialabs/gl-components-module-ui";
import { FormikProps } from "formik";
import {
  Dispatch, FunctionComponent, SetStateAction
} from "react";

interface IAlreadyExistRegister {
  formik: FormikProps<{ rucOrDni: string }>;
  setScreen: Dispatch<
    SetStateAction<
      | "login"
      | "validateMFA"
      | "newPassword"
      | "completeEmail"
      | "confirmationForgetPassword"
      | "verificationCode"
      | "registerAuth"
      | "alreadyExistRegister"
      | "confirmationRegister"
      | "notFoundRegister"
    >
  >;
}

export const AlreadyExistRegister: FunctionComponent<IAlreadyExistRegister> = ({
  formik,
  setScreen
}) => {
  return (
    <>
      <p className="mb-8 text-2xl	color-[#000000] tracking-wider">
        Registrate al Portal Ganadero
      </p>

      <div className="w-[361px]">
        <p className="text-base text-[#0A0036] font-bold text-center mb-2">
          El RUC indicado esta asociado a una cuenta ya existente
        </p>
        <p className="text-base text-[#0A0036] text-center">
          Inicia sesión con tu email y contraseña o recupera tu contraseña dando{" "}
          <button
            className="cursor-pointer font-bold"
            onClick={() => setScreen("completeEmail")}
            style={{ background: "none", border: "none", padding: 0 }}
          >
            clic aquí
          </button>
        </p>
      </div>

      <Button
        name="Iniciar Sesión"
        variant="primary"
        className="w-[361px] rounded-2xl mt-8"
        onClick={() => {
          formik.resetForm();
          setScreen("login");
        }}
      >
        Ir a inicio de sesión
      </Button>

      <Button
        name="Forget Password"
        variant="primary"
        outline={true}
        className="w-[361px] rounded-2xl mt-4"
        onClick={() => setScreen("completeEmail")}
      >
        Recuperar contraseña
      </Button>
    </>
  );
};
