import moment from "moment";
import { formatMilesNumber } from "../../../utils";
import {
  DetailInfo, GeneralInfo, ILoanInfo
} from "../interface";
import { DateFormat } from "../../../enum/DateFormat.enum";
import { sortAdaptedArray } from "../utils/sortArray.util";
import { IGetLoansResponse } from "../response.interface";

export interface LoanData {
  total_interest: number;
  pending_capital: number;
  pending_interest: number;
  actual_installment: number;
  created_by: string;
  updated_by: string;
  created_at: string;
  updated_at: string;
  id: number;
  cowFarmerId: number;
  documentTypeId: number;
  document_number: string;
  document_ref: string;
  application_date: string;
  loan_date: string;
  due_date: string;
  currency: string;
  status: boolean;
  interest_rate: number;
  subtotal: number;
  igv: number;
  total: number;
  loan_installments: number;
  detraction: boolean;
  loanInstallment: ILoanInfo[];
}

export const adapterLoan = (dataArray: LoanData[]) => {
  // Verificar si hay datos en el array

  // console.log("DATA ARRAY ADAPTER", dataArray)

  const numberRef = (actualInstallment: number, arrayLength: number) => {
    let num: number;
    if (actualInstallment === arrayLength) {
      num = actualInstallment - 1;
    } else {
      num = actualInstallment;
    }

    return num;
  };

  if (dataArray.length > 0) {
    // Mapear el array original a un nuevo array de objetos adaptados
    const adaptedArray = dataArray.map((loanData) => {

      const isPaidOff = loanData.loanInstallment.every((installment) => installment.status);

      // Adaptador para el primer objeto (generalInfo)
      const generalInfo = {
        id:          loanData.id,
        title:       "Próxima cuota",
        totalAmount: isPaidOff
          ? "-"
          : `S/ ${formatMilesNumber(
            loanData.loanInstallment[numberRef(loanData.actual_installment, loanData.loanInstallment.length)]
              .loan_installments_amount +
                loanData.loanInstallment[numberRef(loanData.actual_installment, loanData.loanInstallment.length)]
                  .loan_installments_interest
          )}`,
        expiration: isPaidOff
          ? "-"
          : `${moment(
            loanData.loanInstallment[numberRef(loanData.actual_installment, loanData.loanInstallment.length)]
              .installment_due_date,
            DateFormat.POSTGRES
          ).format("DD/MM/YYYY")}`
      } as GeneralInfo;

      // Adaptador para el segundo objeto (detailInfo)
      const detailInfo = {
        columns: [
          {
            name:   "Capital Total",
            detail: `S/ ${formatMilesNumber(parseFloat(loanData.total.toFixed(2)))}`
          },
          {
            name:   "Capital Pendiente",
            detail: isPaidOff ? "-" : `S/ ${formatMilesNumber(parseFloat(loanData.pending_capital.toFixed(2)))}`
          }
          // {
          //   name:   "Interés Total",
          //   detail: `S/ ${formatMilesNumber(parseFloat(loanData.total_interest.toFixed(2)))}`
          // },
          // {
          //   name:   "Interés Pendiente",
          //   detail: isPaidOff ? "-" : `S/ ${formatMilesNumber(parseFloat(loanData.pending_interest.toFixed(2)))}`
          // }
        ],
        additionalInfo: [
          {
            name:   "Recibo RE/",
            detail: `RE-${loanData.document_ref}`
          },
          {
            name:   "Fecha del préstamo:",
            detail: `${moment(loanData.loan_date, DateFormat.POSTGRES).format(
              "DD/MM/YYYY"
            )}`
          },
          {
            name:   "Moneda:",
            detail: `${loanData.currency}`
          }
        ]
      } as DetailInfo;

      // Adaptador para el tercer objeto (tableInfo)
      const tableInfo = loanData.loanInstallment;

      // Devolver los resultados como un objeto
      return {
        generalInfo,
        detailInfo,
        tableInfo,
        isPaidOff,
        nextDueDate: loanData.loanInstallment.find(
          installment => !installment.status
        )?.installment_due_date ?? moment().format(DateFormat.POSTGRES)
      };
    });

    return sortAdaptedArray(adaptedArray) as IGetLoansResponse[];

  } else {
    // Devolver un array vacío si el array original está vacío
    return [];
  }
};
