import {
  Card, Icon, Text
} from "@glorialabs/gl-components-module-ui";
import moment from "moment";
import { ILastMeasurementQualityResponse } from "../../../services/quality/response.interface";
import { InfoType } from "../../../configs/interfaces";

interface Props {
  readonly info: InfoType
}

const CompositionCard: React.FC<Props> = ({ info }) => {

  const { lastFatMeasurement, lastProteinMeasurement } = info as ILastMeasurementQualityResponse;

  return (
    <Card.Container
      style={{
        display:         "flex",
        flexDirection:   "column",
        justifyContent:  "space-between",
        backgroundColor: "#F0EDFF",
        paddingTop:      20,
        paddingBottom:   20,
        borderRadius:    16,
        paddingInline:   50,
        // width: 361,
        height:          225
      }}
    >
      <Card.Header className="text-center flex flex-row justify-center">
        <Icon name="CompositionIcon" size={32}></Icon>
        <Text size="2xl" className="ml-4">
          Composición
        </Text>
      </Card.Header>
      <Card.Body className="text-text-center flex justify-center flex-col">
        <div className="flex text-center justify-center items-center">
          <Text size="2xl" className="text-[#0A0036] flex tracking-wider">
            {lastFatMeasurement?.result.toFixed(2)} % grasa
          </Text>
          <Text size="xs" bold className="ml-1">
            {lastFatMeasurement?.lot_milk_date.length > 0
              ? `(${moment(lastFatMeasurement?.lot_milk_date).format("DD/MM/YYYY")})`
              : "( - )"}
          </Text>
        </div>

        <div className="flex text-center justify-center items-center mt-1">
          <Text
            size="2xl"
            className="text-[#0A0036] flex items-end tracking-wider	"
          >
            {lastProteinMeasurement?.result.toFixed(2)} % proteína
          </Text>
          <Text size="xs" bold className="ml-1">
            {lastProteinMeasurement?.lot_milk_date.length > 0
              ? `(${moment(lastProteinMeasurement?.lot_milk_date).format("DD/MM/YYYY")})`
              : "( - )"}
          </Text>
        </div>
      </Card.Body>
      <Card.Footer
        className="text-center"
        style={{ color: "#B60014", textDecoration: "underline" }}
      >
        <Text
          size="base"
          bold={true}
          className="cursor-pointer"
          style={{ color: "#B60014" }}
        >
          Ver más
        </Text>
      </Card.Footer>
    </Card.Container>
  );
};

export default CompositionCard;
