import { useQueries, useQuery } from "react-query";
import { Link } from "react-router-dom";
import {
  getLastMeasurementAcopio,
  getLastMeasurementQualityAndComposition
} from "../../services/quality/api";
import {
  getLastLoanByCowfarmer,
  getLastPurchaseByCowfarmer
} from "../../services/services/api";
import {
  getGeneralCertificates,
  getGroupsAndPlotsByCowfarmer
} from "../../services/cowfarmers/api";
import {
  Dropdown,
  IOption,
  Spinner,
  Text
} from "@glorialabs/gl-components-module-ui";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import * as util from "../../utils/localstorage";
import { homeConfig } from "../../configs/home.config";
import { IdHomeConfig, InfoType } from "../../configs/interfaces";
import { RestrictedComponent } from "../../components/RestrictedComponent/RestrictedComponent";
import { ILastMeasurementAcopio } from "../../services/quality/interface";
import { ILastMeasurementQualityResponse } from "../../services/quality/response.interface";
import {
  IGeneralCertificates,
  ILoanInfo,
  IPurchaseInfo
} from "../../services/services/interface";
import { paths } from "../../routes/paths";
import ReactGA from "react-ga4";
import { ONE_HOUR } from "../../constant/staleTimes";

export default function Home() {
  const { cowfarmer, setCowbarn, cowbarn } = useContext(AuthContext);

  ReactGA.send({
    hitType: "pageview",
    page:    paths.home,
    title:   "Pagina de inicio"
  });

  const { data, isLoading } = useQuery(
    ["dataCowbarns"],
    () => getGroupsAndPlotsByCowfarmer(cowfarmer!.id),
    {
      onSuccess(data) {
        util.setStorage("cowbarns", { groupAndPlot: data });
        setCowbarn(data[0]?.value);
      },
      staleTime: ONE_HOUR
    }
  );

  const onSelectChange = (value: IOption | null) => {
    if (value) {
      setCowbarn(value!.value as number);

      // Registra un evento en Google Analytics cuando se selecciona una opción en el Dropdown
      ReactGA.event({
        category: "Dropdown",
        action:   "Select Option",
        label:    value.label
      });
    }
  };

  const [
    lastMeasurementAcopio,
    lastMeasurementQualityAndComposition,
    lastLoanByCowfarmer,
    lastPurchaseByCowfarmer,
    lastCertificatesByCowfarmer
  ] = useQueries([
    {
      queryKey:  ["getLastMeasurementAcopio", cowbarn],
      queryFn:   () => getLastMeasurementAcopio(cowbarn),
      enabled:   cowbarn !== 0,
      staleTime: ONE_HOUR
    },
    {
      queryKey:  ["getLastMeasurementQualityAndComposition", cowbarn],
      queryFn:   () => getLastMeasurementQualityAndComposition(cowbarn),
      enabled:   cowbarn !== 0,
      staleTime: ONE_HOUR

    },
    {
      queryKey:  ["getLastLoanByCowfarmer", cowfarmer],
      queryFn:   () => getLastLoanByCowfarmer(cowfarmer!.id),
      staleTime: ONE_HOUR

    },
    {
      queryKey:  ["getLastPurchaseByCowfarmer", cowfarmer],
      queryFn:   () => getLastPurchaseByCowfarmer(cowfarmer!.id),
      staleTime: ONE_HOUR

    },
    {
      queryKey:  ["getGeneralCertificates", cowbarn],
      queryFn:   () => getGeneralCertificates(cowbarn),
      enabled:   cowbarn !== 0,
      staleTime: ONE_HOUR

    }
  ]);

  if (
    isLoading ||
    lastMeasurementAcopio.isLoading ||
    lastMeasurementQualityAndComposition.isLoading ||
    lastLoanByCowfarmer.isLoading ||
    lastPurchaseByCowfarmer.isLoading ||
    lastCertificatesByCowfarmer.isLoading
  ) {
    return (
      <div
        className="bg-red flex justify-center align-middle items-center flex-col"
        style={{ height: "calc(100vh - 210px)" }}
      >
        <Spinner />
        <Text size="2xl" className="text-[#07196D]">
          Cargando
        </Text>
      </div>
    );
  }

  const dataCard: { [key in IdHomeConfig]: InfoType } = {
    production_acopio: lastMeasurementAcopio.data as ILastMeasurementAcopio,
    production_quality:
      lastMeasurementQualityAndComposition.data ||
      ({} as ILastMeasurementQualityResponse),
    production_composition:
      lastMeasurementQualityAndComposition.data ||
      ({} as ILastMeasurementQualityResponse),
    certificates:
      lastCertificatesByCowfarmer.data || ({} as IGeneralCertificates),
    services_loans:    lastLoanByCowfarmer.data || ({} as ILoanInfo),
    services_shopping: lastPurchaseByCowfarmer.data || ({} as IPurchaseInfo)
  };

  // const dataCard: { [key in IdHomeConfig]: InfoType | null | undefined } = {
  //   production_acopio: lastMeasurementAcopio.data as ILastMeasurementAcopio,
  //   production_quality: lastMeasurementQualityAndComposition.data as ILastMeasurementQualityResponse,
  //   production_composition: lastMeasurementQualityAndComposition.data as ILastMeasurementQualityResponse,
  //   certificates: lastCertificatesByCowfarmer.data as IGeneralCertificates,
  //   services_loans: lastLoanByCowfarmer.data as ILoanInfo ,
  //   services_shopping: lastPurchaseByCowfarmer.data as IPurchaseInfo
  // };

  return (
    <div className="flex lg:block flex-col items-center">
      <div className="flex justify-between mb-8 flex-wrap flex-col lg:flex-row">
        <div>
          <Text size="2xl" bold={true} className="lg:text-3xl mb-4">
            Hola {cowfarmer?.business_name}
          </Text>

          <Text size="base" bold={false} className="lg:text-2xl tracking-wider">
            Bienvenido a tu nuevo portal ganadero
          </Text>
        </div>

        {/* {user.cowbarns.length > 1 && ( */}
        {(Array.isArray(data) && data.length > 1) && (
          <div className="mt-4 lg:mt-0 w-[361px]">
            <Dropdown
              options={data as IOption[]}
              label="Grupo y parcela"
              suggestionText="Selecciona el grupo y parcela de tu establo"
              value={data.find((option: IOption) => option.value === cowbarn)}
              onChange={(value) => onSelectChange(value)}
            />
          </div>
        )}

        {/* )} */}
      </div>

      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3 lg:gap-4 justify-center">
        {homeConfig.map((item) => (
          <RestrictedComponent rolesRequired={item.permissions} key={item.id}>
            <div className="flex justify-center items-center lg:block">
              <div className="min-w-[361px] max-w-[361px] lg:max-w-screen-lg cursor-pointer">
                <Link to={item.path} onClick={() => ReactGA.event({
                  category: "Navigation Card",
                  action:   "Clic home cards",
                  label:    item.path
                })}>
                  <item.component info={dataCard[item.id]} />
                </Link>
              </div>
            </div>
          </RestrictedComponent>
        ))}
      </div>
    </div>
  );
}
