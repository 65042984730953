import { BarChart, DualBarChart } from "@glorialabs/gl-components-module-ui";
import { IBaseDataProduction } from "../../../../services/quality/interface";
import {
  formatDataChart, formatMonthlyDataChart, generateMonthlyAxisY, generateValuesAxisY, transformMonthlyData
} from "../../utils/formatChartData";
import { useContext } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import { Period } from "../../../../enum/Period.enum";

interface DataChartsProps {
  activeTab: number;
  dataDaily: IBaseDataProduction[];
  dataBiweekly: IBaseDataProduction[];
  dataMonthly: IBaseDataProduction[];
  windowWidth: number;
}

export const DataCharts = ({
  activeTab, dataDaily, dataBiweekly, dataMonthly, windowWidth
} : DataChartsProps) => {

  const { cowbarn } = useContext(AuthContext);

  if (activeTab === Period.MONTHLY) {
    return (
      <>
        <DualBarChart
          key={`monthlydata-kg-${cowbarn}`}
          title="Leche acopiada (kg)"
          data1={formatMonthlyDataChart(transformMonthlyData(dataMonthly, "sumAmountCollect"))[0] || []}
          labelData1="2023"
          data2={formatMonthlyDataChart(transformMonthlyData(dataMonthly, "sumAmountCollect"))[1] || []}
          labelData2="2024"
          valuesAxisY={
            generateMonthlyAxisY(
              formatMonthlyDataChart(transformMonthlyData(dataMonthly, "sumAmountCollect"))
            )
          }
          scrollbar={true}
          className="mb-4"
          width={1200}
          height={328}
        />
      </>
    );
  } else if (activeTab === Period.BIWEEKLY) {
    return (
      <BarChart
        key={`biweeklydata-kg-${cowbarn}`}
        title="Leche acopiada (kg)"
        data={formatDataChart(
          dataBiweekly,
          "sumAmountCollect",
          "biweekly",
          windowWidth > 650 ? "full" : "short"
        )}
        valuesAxisY={generateValuesAxisY(
          dataBiweekly,
          "sumAmountCollect"
        )}
        scrollbar={true}
        width={1200}
        height={396}
      />
    );
  } else {
    return (
      <BarChart
        title="Leche acopiada (kg)"
        key={`dailydata-kg-${cowbarn}`}
        data={formatDataChart(
          dataDaily,
          "sumAmountCollect",
          "daily"
        )}
        valuesAxisY={generateValuesAxisY(
          dataDaily,
          "sumAmountCollect"
        )}
        scrollbar={true}
        className="mb-4"
        width={1200}
      />
    );
  }
};
