import LogoGloria from "../../assets/logogloria.svg";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <div className="bg-[#F2F2F2]">
      <div className="p-8">
        <div className="mb-4">
          <LogoGloria />
        </div>
        <Link target="_blank" to={"/ver/terminos-y-condiciones-de-uso"}>
          <p className="text-[#07196D] font-bold font-['Roboto'] hover:opacity-75">
            Ver términos y condiciones
          </p>
        </Link>
        <p className="mt-4 font-['Roboto']">
          © 2024 Gloria, Perú. Todos los Derechos Reservados.
        </p>
      </div>
    </div>
  );
};
