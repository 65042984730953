import moment from "moment";
import {
  ColumnProps,
  ExpandableColumnProps
} from "@glorialabs/gl-components-module-ui";
import { IBaseDataProduction, IdisaggregateData } from "../../../../services/quality/interface";
import { formatBiweeklyDate } from "../../utils/formatDate";

interface IPeriod {
  period: string;
  2023: number | string;
  2024: number | string;
}

export const qualityDailyDataColumns: ColumnProps[] = [
  {
    title:  "Periodo",
    key:    "period",
    render: (_: number, record: IBaseDataProduction, isExpanded, expandRow) => (
      <p>
        {moment(record.lot_milk_date).format("DD/MM/YYYY")}{" "}
        {record.items!.length > 0 && (
          <>
            {" "}
            -{" "}
            <span
              onClick={expandRow}
              className="cursor-pointer font-semibold"
              style={{ background: "none", border: "none", padding: 0 }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && expandRow) {
                  expandRow();
                }
              }}
              tabIndex={0}
            >
              {isExpanded ? "Ocultar Entregas" : "Ver Entregas"}
            </span>
          </>
        )}{" "}
      </p>
    )
  },
  {
    title:  "ufc / ml",
    key:    "ufc",
    align:  "right",
    render: (_: number, record: IBaseDataProduction) => {

      return <p>{!record.UFCAverageWeighted ? "-" : record.UFCAverageWeighted.toLocaleString("en-US") || "-"}</p>;
    }
  },
  {
    title:  "rcs / ml",
    key:    "rcs",
    align:  "right",
    render: (_: number, record: IBaseDataProduction) => {

      return <p>{!record.RCSAverageWeighted ? "-" : record.RCSAverageWeighted.toLocaleString("en-US") || "-"}</p>;
    }
  }
];

export const qualityExpandableDailyDataColumns: ExpandableColumnProps[] = [
  {
    title:  "Periodo",
    key:    "periodExpandable",
    render: (index: number) => {
      return <p className="text-[#07196D]">Entrega {index + 1}</p>;
    }
  },
  {
    title:  "ufc / ml",
    key:    "ufcExpandable",
    align:  "right",
    render: (_: number, record: IdisaggregateData) => {
      return (
        <p className="text-[#07196D]">
          {!record.UFCAverageWeighted ? "-" : record.UFCAverageWeighted.toLocaleString("en-US") || "-"}
        </p>
      );
    }
  },
  {
    title:  "rcs / ml",
    key:    "rcsExpandable",
    align:  "right",
    render: (_: number, record: IdisaggregateData) => {
      return (
        <p className="text-[#07196D]">
          {!record.RCSAverageWeighted ? "-" : record.RCSAverageWeighted.toLocaleString("en-US") || "-"}
        </p>
      );
    }
  }
];

export const qualityBiweeklyDataColumns: ColumnProps[] = [
  {
    title:  "Periodo",
    key:    "period",
    render: (_: number, record: IBaseDataProduction) => (
      <p>{record.lot_milk_date ? formatBiweeklyDate(record.lot_milk_date) : "-"}</p>
    )
  },
  {
    title:  "ufc / ml",
    key:    "biweekly-ufcAverage",
    align:  "right",
    render: (_: number, record: IBaseDataProduction) => (
      <p>
        {!record.UFCAverageWeighted
          ? "-"
          : record.UFCAverageWeighted?.toLocaleString("en-US") || "-"}
      </p>
    )
  },
  {
    title:  "rcs / ml",
    key:    "biweekly-rcsAverage",
    align:  "right",
    render: (_: number, record: IBaseDataProduction) => (
      <p>
        {!record.RCSAverageWeighted
          ? "-"
          : record.RCSAverageWeighted?.toLocaleString("en-US") || "-"}
      </p>
    )
  }
];

export const qualityMonthlyDataColumns: ColumnProps[] = [
  {
    title:  "Periodo",
    key:    "period",
    render: (_: number, record: IPeriod) => (
      <p className="capitalize">
        {record.period[0] + record.period.substring(1).toLowerCase()}
      </p>
    )
  },
  {
    title:  "2023",
    key:    "2023",
    align:  "right",
    render: (_: number, record: IPeriod) => (
      <p>
        {typeof record[2023] === "number" && record[2023] !== 0
          ? record[2023].toLocaleString("en-US")
          : "-"}
      </p>
    )
  },
  {
    title:  "2024",
    key:    "2024",
    align:  "right",
    render: (_: number, record: IPeriod) => (
      <p>
        {typeof record[2024] === "number" && record[2024] !== 0
          ? record[2024].toLocaleString("en-US")
          : "-"}
      </p>
    )
  }
];

