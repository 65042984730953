import AccumulationCard from "../pages/Home/cards/AccumulationCard";
import CertificatesCard from "../pages/Home/cards/CertificatesCard";
import CompositionCard from "../pages/Home/cards/CompositionCard";
import LoanCard from "../pages/Home/cards/LoanCard";
import QualityCard from "../pages/Home/cards/QualityCard";
import ShopCard from "../pages/Home/cards/ShopCard";
import { paths } from "../routes/paths";
import { HomeConfig, InfoType } from "./interfaces";
import { ROLES } from "./roles.config";

export const homeConfig: HomeConfig<InfoType>[] = [
  {
    id:          "production_acopio",
    permissions: [
      ROLES.ADMIN_COWBARN,
      ROLES.ADMIN_TI,
      ROLES.OWNER_COWBARN,
      ROLES.VET_COWBARN
    ],
    path:      paths.acumulation,
    component: AccumulationCard
  },
  {
    id:          "production_quality",
    permissions: [
      ROLES.ADMIN_COWBARN,
      ROLES.ADMIN_TI,
      ROLES.OWNER_COWBARN,
      ROLES.VET_COWBARN
    ],
    path:      paths.quality,
    component: QualityCard
  },
  {
    id:          "production_composition",
    permissions: [
      ROLES.ADMIN_COWBARN,
      ROLES.ADMIN_TI,
      ROLES.OWNER_COWBARN,
      ROLES.VET_COWBARN
    ],
    path:      paths.composition,
    component: CompositionCard
  },
  {
    id:          "certificates",
    permissions: [
      ROLES.ADMIN_COWBARN,
      ROLES.ADMIN_TI,
      ROLES.OWNER_COWBARN,
      ROLES.VET_COWBARN
    ],
    path:      paths.certificates,
    component: CertificatesCard
  },
  {
    id:          "services_loans",
    permissions: [
      ROLES.ADMIN_COWBARN,
      ROLES.ADMIN_TI,
      ROLES.OWNER_COWBARN,
      ROLES.FINANCIAL_COWBARN
    ],
    path:      paths.loans,
    component: LoanCard
  },
  {
    id:          "services_shopping",
    permissions: [
      ROLES.ADMIN_COWBARN,
      ROLES.ADMIN_TI,
      ROLES.OWNER_COWBARN,
      ROLES.FINANCIAL_COWBARN
    ],
    path:      paths.shopping,
    component: ShopCard
  }
];
