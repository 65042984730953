import { FunctionComponent } from "react";

interface ICertificate {
  indicator: "GRAY" | "RED" | "GREEN" | "AMBER";
  className?: string;
}

export const CertificateItem: FunctionComponent<ICertificate> = ({
  indicator,
  className
}) => {
  const certificateIconColor = {
    GRAY: {
      color: "#5A5A5F",
      text:  "No tiene",
      icon:  "X"
    },
    RED: {
      color: "#E11808",
      text:  "Vencida",
      icon:  "!"
    },
    GREEN: {
      color: "#72BE6C",
      text:  "Vigente",
      icon:  "-"
    },
    AMBER: {
      color: "#EF863D",
      text:  "Por Vencer",
      icon:  "-"
    }
  };

  const currentCertificate = certificateIconColor[indicator];

  return (
    <div className={`${className}`} style={{ color: currentCertificate.color }}>
      <div
        className="w-10 h-10 rounded-full flex justify-center items-center"
        style={{ backgroundColor: currentCertificate.color }}
      >
        <div className="w-5 h-5 rounded-full bg-[#E4F0FF] flex justify-center items-center">
          <span className="font-bold">{currentCertificate.icon}</span>
        </div>
      </div>
      <span className="text-base font-bold">{currentCertificate.text}</span>
    </div>
  );
};
