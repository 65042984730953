import { BarChart, DualBarChart } from "@glorialabs/gl-components-module-ui";
import { IBaseDataProduction } from "../../../../services/quality/interface";
import {
  formatDataChart,
  formatMonthlyDataChart,
  generateMonthlyAxisY,
  generateValuesAxisY,
  transformMonthlyData
} from "../../utils/formatChartData";
import { AuthContext } from "../../../../context/AuthContext";
import { useContext } from "react";

interface DataChartsProps {
  activeTab: number;
  dataDaily: IBaseDataProduction[];
  dataBiweekly: IBaseDataProduction[];
  dataMonthly: IBaseDataProduction[];
  windowWidth: number;
}

export const DataCharts = ({
  activeTab,
  dataDaily,
  dataBiweekly,
  dataMonthly,
  windowWidth
}: DataChartsProps) => {
  const { cowbarn } = useContext(AuthContext);

  if (activeTab === 2) {
    return (
      <>
        <DualBarChart
          key={`monthlydata-ufc-${cowbarn}`}
          title="Comparativa anual UFC (ml)"
          data1={
            formatMonthlyDataChart(
              transformMonthlyData(dataMonthly, "UFCAverageWeighted")
            )[0] || []
          }
          labelData1="2023"
          data2={
            formatMonthlyDataChart(
              transformMonthlyData(dataMonthly, "UFCAverageWeighted")
            )[1] || []
          }
          labelData2="2024"
          valuesAxisY={generateMonthlyAxisY(
            formatMonthlyDataChart(
              transformMonthlyData(dataMonthly, "UFCAverageWeighted")
            )
          )}
          scrollbar={true}
          baseLine={{ value: 50000 }}
          className="mb-4"
          width={1200}
          height={328}
        />
        <DualBarChart
          key={`monthlydata-rcs-${cowbarn}`}
          title="Comparativa anual RCS (ml)"
          data1={
            formatMonthlyDataChart(
              transformMonthlyData(dataMonthly, "RCSAverageWeighted")
            )[0] || []
          }
          labelData1="2023"
          data2={
            formatMonthlyDataChart(
              transformMonthlyData(dataMonthly, "RCSAverageWeighted")
            )[1] || []
          }
          labelData2="2024"
          valuesAxisY={generateMonthlyAxisY(
            formatMonthlyDataChart(
              transformMonthlyData(dataMonthly, "RCSAverageWeighted")
            )
          )}
          scrollbar={true}
          baseLine={{ value: 200000 }}
          className="mb-4"
          width={1200}
          height={328}
        />
      </>
    );
  } else if (activeTab === 0) {
    return (
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <BarChart
          key={`biweeklydata-ufc-${cowbarn}`}
          title="ufc / ml"
          data={formatDataChart(
            dataBiweekly,
            "UFCAverageWeighted",
            "biweekly",
            windowWidth > 650 ? "full" : "short"
          )}
          valuesAxisY={generateValuesAxisY(dataBiweekly, "UFCAverageWeighted")}
          baseLine={{ value: 50000, highlightedValues: "up" }}
          scrollbar={true}
          className="mb-4"
          width={1200}
          height={328}
        />
        <BarChart
          title="rcs / ml"
          key={`biweeklydata-rcs-${cowbarn}`}
          data={formatDataChart(
            dataBiweekly,
            "RCSAverageWeighted",
            "biweekly",
            windowWidth > 650 ? "full" : "short"
          )}
          valuesAxisY={generateValuesAxisY(dataBiweekly, "RCSAverageWeighted")}
          scrollbar={true}
          baseLine={{ value: 200000, highlightedValues: "up" }}
          className="mb-4"
          width={1200}
          height={328}
        />
      </div>
    );
  } else {
    return (
      <div>
        <BarChart
          title="ufc / ml"
          key={`dailydata-ufc-${cowbarn}`}
          data={formatDataChart(dataDaily, "UFCAverageWeighted", "daily")}
          valuesAxisY={generateValuesAxisY(dataDaily, "UFCAverageWeighted")}
          scrollbar={true}
          className="mb-4"
          width={1200}
          baseLine={{ value: 50000, highlightedValues: "up" }}
        />
        <BarChart
          title="rcs / ml"
          key={`dailydata-rcs-${cowbarn}`}
          data={formatDataChart(dataDaily, "RCSAverageWeighted", "daily")}
          valuesAxisY={generateValuesAxisY(dataDaily, "RCSAverageWeighted")}
          scrollbar={true}
          baseLine={{ value: 200000, highlightedValues: "up" }}
          className="mb-4"
          width={1200}
          height={328}
        />
      </div>
    );
  }
};
