import * as Yup from "yup";
import {
  boolean, string, number
} from "yup";

export class IDownloadDataForm {
  option_lotmilk: boolean;
  option_temperature: boolean;
  option_fat: boolean;
  option_protein: boolean;
  option_ufc: boolean;
  option_rcs: boolean;
  cowbarnId: number;
  downloadFormatId: number | null;
  periodId: number | null;
  dateFrom: Date | undefined;
  dateTo: Date | undefined;
}

export const initialValues = {
  option_lotmilk:     false,
  option_temperature: false,
  option_fat:         false,
  option_protein:     false,
  option_ufc:         false,
  option_rcs:         false,
  cowbarnId:          0,
  downloadFormatId:   null,
  periodId:           null,
  dateFrom:           undefined,
  dateTo:             undefined
} as IDownloadDataForm;

export const downloadInfoSchema = Yup.object({
  option_lotmilk:     boolean(),
  option_temperature: boolean(),
  option_fat:         boolean(),
  option_protein:     boolean(),
  option_ufc:         boolean(),
  option_rcs:         boolean(),

  cowbarnId:        number().required(),
  downloadFormatId: number().required(),
  periodId:         number().required(),
  dateFrom:         string().required(),
  dateTo:           string().required()
});

