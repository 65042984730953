import { FunctionComponent, useState } from "react";
import { Icon, Text } from "@glorialabs/gl-components-module-ui";
import { RestrictedComponent } from "../../../../RestrictedComponent/RestrictedComponent";
import { Link } from "react-router-dom";
import { NavigationCollapse } from "../../../../../configs/interfaces";

interface IMobileCollapseMenuOption {
  item: NavigationCollapse;
  closeAll: () => void;
}

export const MobileCollapseMenuOption: FunctionComponent<IMobileCollapseMenuOption> = ({
  item,
  closeAll
}) => {
  const [expand, setExpand] = useState<boolean>(false);
  return (
    <RestrictedComponent rolesRequired={item.permissions}>
      <div className="relative">
        <Text
          size="base"
          bold
          className="text-[#0A0036] tracking-wider cursor-pointer flex justify-center"
          onClick={() => {
            setExpand(!expand);
          }}
          isButton
        >
          {item.title}
          {expand ? (
            <Icon
              name="ChevronUpIcon"
              color="#0A0036"
              style={{ fontWeight: "700" }}
              className="ml-2"
            />
          ) : (
            <Icon
              name="ChevronDownIcon"
              color="#0A0036"
              style={{ fontWeight: "700" }}
              className="ml-2"
            />
          )}
        </Text>

        {expand && (
          <div>
            {item.children.map((child) => (
              <Link
                to={child.navLink}
                key={child.id}
                onClick={() => closeAll()}
              >
                <Text
                  size="base"
                  bold
                  className="text-[#0A0036] tracking-wider cursor-pointer mt-4"
                >
                  {child.title}
                </Text>
              </Link>
            ))}
          </div>
        )}
      </div>
    </RestrictedComponent>
  );
};
