import * as Yup from "yup";

export interface IFormTermsConditions {
  condition_1: boolean;
  condition_2: boolean;
}

export const initialValues = {
  condition_1: false,
  condition_2: false
};

export const validationSchemaTerms = Yup.object({
  condition_1: Yup.bool()
    .oneOf(
      [true],
      `Para continuar, da click en las cajas de selección de términos y 
      condiciones de uso y la política de protección de datos personales`
    )
    .required(),
  condition_2: Yup.bool()
    .oneOf(
      [true],
      `Para continuar, da click en las cajas de selección de términos y 
      condiciones de uso y la política de protección de datos personales`
    )
    .required()
});
