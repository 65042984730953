import { apiCowfarmers } from "../api";
import {
  ICertificatesResponse, IGeneralCertificatesResponse, IGroupsAndPlotsResponse
} from "./response.interface";

export const getGroupsAndPlotsByCowfarmer = async (
  cowfarmerId: number
): Promise<IGroupsAndPlotsResponse> => {
  try {
    const response =
      await apiCowfarmers.get("/cowbarns/getGroupsAndPlots", {
        params: {
          cowfarmerId: cowfarmerId
        }
      });
    // console.log({ response });
    return response.data.data;
  } catch (error) {
    console.log("Hubo error", error);
    return [];
  }
};

export const getCertificates = async (
  cowbarnId: number
): Promise<ICertificatesResponse> => {
  const response = await apiCowfarmers.get("/cowbarns/getCertificates", {
    params: {
      cowbarnId
    }
  });

  return response.data.data;
};

export const getGeneralCertificates = async (
  cowbarnId: number
): Promise<IGeneralCertificatesResponse> => {
  const response = await apiCowfarmers.get("/cowbarns/getGeneralCertificates", {
    params: {
      cowbarnId
    }
  });

  return response.data.data;
};
