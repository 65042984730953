import { useContext, useState } from "react";
import { getMyNotifications } from "../../services/services/api";
import { useQuery } from "react-query";
import { NotificationCard } from "./components/NotificationCard";
import {
  Pagination, Spinner, Text
} from "@glorialabs/gl-components-module-ui";
import { AuthContext } from "../../context/AuthContext";
import { INotificationResponse } from "../../services/services/response.interface";

export default function NotificationsPage() {
  const { cowfarmer } = useContext(AuthContext);

  const itemsPerPage = 10;
  const [page, setPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const { data: dataNotifications, isLoading } = useQuery(
    ["myNotifications", offset],
    () =>
      getMyNotifications({
        id:    cowfarmer?.id as number,
        limit: itemsPerPage,
        offset
      })
  );

  const totalPages = Math.ceil((dataNotifications?.total ?? 1) / itemsPerPage);
  const onChangePage = (page: number) => {
    setPage(page);
    setOffset((page - 1) * itemsPerPage);
  };

  const renderNotifications = (notifications: INotificationResponse) => {
    if (notifications.data.length > 0) {
      return (
        <>
          {notifications.data.map((notification) => (
            <NotificationCard
              key={notification.id}
              notification={notification}
              className="mb-4"
            />
          ))}
          {notifications.total > itemsPerPage && (
            <div className="flex justify-center md:justify-start">
              <Pagination
                totalPages={totalPages}
                onChange={onChangePage}
                page={page}
              />
            </div>
          )}
        </>
      );
    } else {
      return (
        <Text size="2xl" style={{ color: "#0A0036" }}>No hay notificaciones para mostrar</Text>
      );
    }
  };

  return (
    <div>
      <Text size="4xl" bold={true} className="text-3xl mb-8  w-[361px]">
        Notificaciones
      </Text>
      {isLoading ? (
        <div className="flex justify-center items-center p-4">
          <Spinner/>
        </div>
      ) : (
        dataNotifications && renderNotifications(dataNotifications)
      )}
    </div>
  );
}
