import { ColumnProps } from "@glorialabs/gl-components-module-ui";
import { Daum } from "../../../services/services/interface";
const sharedColumns: ColumnProps[] = [
  {
    title:  "Serie",
    key:    "serie",
    align:  "left",
    render: (_: number, record: Daum) => <p>{record.serie}</p>
  },
  {
    title:  "Fabricante",
    key:    "maker",
    align:  "left",
    render: (_: number, record: Daum) => <p>{record.product.brand}</p>
  },
  {
    title:  "Volumen (L)",
    key:    "volume",
    align:  "left",
    render: (_: number, record: Daum) => <p>{record.volume.toLocaleString("en-US")}</p>
  }
];

const additionalColumnsForLargeScreen: ColumnProps[] = [
  {
    title:  "Tipo de Activo",
    key:    "activeType",
    align:  "left",
    render: (_: number, record: Daum) => (
      <p>{record.product.typeProduct.description}</p>
    )
  },
  {
    title:  "Código de Equipo",
    key:    "machine",
    align:  "left",
    render: (_: number, record: Daum) => <p>{record.assets_code}</p>
  },
  {
    title:  "Nombre del equipo",
    key:    "nameMachine",
    align:  "left",
    render: (_: number, record: Daum) => <p>{record.product.description}</p>
  }
];

export const activesColumns: ColumnProps[] = [
  ...additionalColumnsForLargeScreen,
  ...sharedColumns
];

export const activeResponsiveColumns: ColumnProps[] = [
  ...sharedColumns
];
