import * as Yup from "yup";

export interface IValidateMFAForm {
  codeMFA: string;
}

export const initialValuesValidateMFA = {
  codeMFA: ""
};

export const validationSchemaValidateMFA = Yup.object({
  codeMFA: Yup.number()
    .typeError("El código de verificación deben ser números")
    .required("Debe completar el codigo de verificación")
});
