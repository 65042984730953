
export const formatDailyDate = (date: string) => {

  const dateParts = date.split("-");

  const year = dateParts[0];

  const month = dateParts[1];

  const day = dateParts[2];

  return `${day}/${month}\n${year}`;
};

export const formatBiweeklyDate = (
  date: string,
  type: "chart" | "basic" = "basic",
  textLength: "full" | "short" = "full"
) => {
  const isChart = type === "chart";
  const parts = date.split("_");

  const month = parts[1] as
    | "ENERO"
    | "FEBRERO"
    | "MARZO"
    | "ABRIL"
    | "MAYO"
    | "JUNIO"
    | "JULIO"
    | "AGOSTO"
    | "SEPTIEMBRE"
    | "OCTUBRE"
    | "NOVIEMBRE"
    | "DICIEMBRE";
  const monthNames = {
    ENERO:      { full: "Enero", short: "Ene." },
    FEBRERO:    { full: "Febrero", short: "Feb." },
    MARZO:      { full: "Marzo", short: "Mar." },
    ABRIL:      { full: "Abril", short: "Abr." },
    MAYO:       { full: "Mayo", short: "May." },
    JUNIO:      { full: "Junio", short: "Jun." },
    JULIO:      { full: "Julio", short: "Jul." },
    AGOSTO:     { full: "Agosto", short: "Ago." },
    SEPTIEMBRE: { full: "Septiembre", short: "Sep." },
    OCTUBRE:    { full: "Octubre", short: "Oct." },
    NOVIEMBRE:  { full: "Noviembre", short: "Nov." },
    DICIEMBRE:  { full: "Diciembre", short: "Dic." }
  };

  const spanishMonth = monthNames[month][textLength];

  // Construye la cadena resultante
  return `${parts[2]}° ${isChart ? "qna" : "quincena"}${isChart ? "\n" : " "}${spanishMonth}`;
};

