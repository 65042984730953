import { Button, Text } from "@glorialabs/gl-components-module-ui";
import Block from "../../../../assets/Block.svg";
import {
  Dispatch, SetStateAction, useContext
} from "react";
import { UseMutateAsyncFunction } from "react-query";
import { IResponseUserMaster } from "../../../../services/master/response.interface";
import { IBodyDisableUser } from "../../../../services/master/request.interface";
import { AuthContext } from "../../../../context/AuthContext";

interface Props {
  setViewsConf: Dispatch<SetStateAction<string>>;
  isLoadingToggleUserActiveMutation: boolean;
  toggleActiveUserMutation: UseMutateAsyncFunction<
    IResponseUserMaster,
    unknown,
    IBodyDisableUser,
    unknown
  >;
  email: string;
}

export const DisableUser = ({
  setViewsConf,
  isLoadingToggleUserActiveMutation,
  toggleActiveUserMutation,
  email
}: Props) => {
  const { user } = useContext(AuthContext);

  return (
    <div className="flex flex-col items-center">
      <Text size="2xl" bold className="text-3xl mt-3">
        Confirmar acción
      </Text>

      <div className="my-5">
        <Block />
      </div>

      <Text size="base" className="flex flex-col lg:flex-row text-center">
        ¿Estás seguro de inhabilitar a este usuario?
        <span className="font-bold">
          &nbsp;Ya no podrá acceder a tu Portal Ganadero
        </span>
      </Text>

      <div className="flex flex-col lg:flex-row mt-5 w-full justify-center">
        <Button
          name="delete"
          variant="secondary"
          className="w-full lg:w-[141px]"
          loading={isLoadingToggleUserActiveMutation}
          onClick={() =>
            toggleActiveUserMutation({
              email:        email,
              master_email: user?.origin_master_email ?? ""
            })
          }
          type="button"
        >
          Inhabilitar
        </Button>
        <div className="mx-2"></div>
        {/* <Link to={`/noticias/detalle/${id}`}> */}
        <Button
          name="regresar"
          variant="primary"
          className="w-full lg:w-[141px] mt-4 lg:mt-0"
          onClick={() => setViewsConf("formDetailUser")}
          type="button"
        >
          Regresar
        </Button>
        {/* </Link> */}
      </div>
    </div>
  );
};
