export interface IAuthForm {
  email: string;
  password: string;
  codeMFA?: string;
  rucOrDni?: string;
  verificationCodePassword?: string;
  newPassword: string;
  newPasswordRepeat: string;
}

export const initialValues = {
  email:                    "",
  password:                 "",
  codeMFA:                  "",
  rucOrDni:                 "",
  verificationCodePassword: "",
  newPassword:              "",
  newPasswordRepeat:        ""
};
