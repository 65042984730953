import { Navigate, Outlet } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

export default function PublicRoute() {
  const { status } = useContext(AuthContext);
  return status === "not-authenticated" || status === "checking" ? (
    <Outlet />
  ) : (
    <Navigate to={"/inicio"} />
  );
}
