import {
  Fragment,
  useCallback,
  useContext, useState
} from "react";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { useQuery } from "react-query";
import {
  Dropdown,
  IOption,
  Tabs,
  Text
} from "@glorialabs/gl-components-module-ui";

import { AuthContext } from "../../../context/AuthContext";
import { getGroupsAndPlotsByCowfarmer } from "../../../services/cowfarmers/api";
import {
  infoTab,
  optionsTab,
  renderLoading,
  useProductionData
} from "../../../hooks/getDataProductionHook";
import { IBaseDataProduction } from "../../../services/quality/interface";
import { DataCharts } from "./components/DataCharts";
import DataTable, {
  MonthlyDataField
} from "../components/DataTableProductionNew";
import { sortMilkRecordsItems } from "../DownloadInfoPage/utils/downloadInfo.utils";
import ReactGA from "react-ga4";
import { ONE_HOUR } from "../../../constant/staleTimes";
import { Period } from "../../../enum/Period.enum";
import { Caption } from "../components/Caption";
import classNames from "classnames";
import { ConditionalWrapper } from "../../../components/ConditionalWrapper/ConditionalWrapper";

export default function AcumulationPage() {
  const { cowfarmer, cowbarn, setCowbarn } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState<number>(Period.DAILY);
  const { width: windowWidth } = useWindowSize();
  const [startTime, setStartTime] = useState<number>(Date.now());

  const { data, isLoading: isLoadingCowbarns } = useQuery(
    ["dataCowbarns"],
    () => getGroupsAndPlotsByCowfarmer(cowfarmer!.id),
    {
      onSuccess(data) {
        setCowbarn(data[0].value);
      },
      staleTime: ONE_HOUR
    }
  );

  const onSelectChange = (value: IOption | null) => {
    if (value) {
      setCowbarn(value.value as number);
    }
  };

  const { dataDaily, dataBiweekly, dataMonthly } = useProductionData(cowbarn);

  const monthlyDataFields = [
    { field: "sumAmountCollect", title: "Acopio (kg)" } ] as MonthlyDataField[];

  const tabString = (tab: number) => {
    switch(tab) {
      case 0: return "Quincenal";
      case 1: return "Diaria";
      case 2: return "Mensual";
      default: return "";
    }
  };

  const logTabTime = useCallback((tab: number) => {
    const endTime = Date.now();
    const duration = (endTime - startTime) / 1000; // Convert to seconds

    ReactGA.event({
      category: "Tiempo de permanencia",
      action:   "Tiempo en tab acopio",
      label:    tabString(tab),
      value:    duration
    });

    setStartTime(endTime); // Reset start time for the next tab
  }, [startTime]);

  const isLoading = isLoadingCowbarns ||
  dataDaily.isLoading ||
  dataBiweekly.isLoading ||
  dataMonthly.isLoading;

  return (
    <div>
      <Text size="4xl" bold={true} className="text-3xl mb-6  w-[361px]">
        Acopio
      </Text>
      <div className="flex justify-between mb-8 flex-wrap flex-col-reverse lg:flex-row">
        <div className="mt-4 lg:mt-0 w-full lg:w-[361px]">
          <Tabs
            options={optionsTab}
            defaultTab={activeTab}
            onOptionSelect={(tab) => {
              ReactGA.event({
                category: "Seguimiento acopio",
                action:   "Clic a los filtros de acopio",
                label:    tabString(tab as number)
              });
              logTabTime(activeTab);
              setActiveTab(tab as number);
            }}
          />
          <Text size="sm" className="tracking-wider	mt-4">
            {infoTab[activeTab]}
          </Text>
        </div>

        {Array.isArray(data) && data.length > 1 && (
          <div className="w-full lg:w-[361px]">
            <Dropdown
              options={data || []}
              label="Grupo y parcela"
              suggestionText="Selecciona el grupo y parcela de tu establo"
              value={data?.find((option: IOption) => option.value === cowbarn)}
              onChange={(value) => onSelectChange(value as IOption | null)}
            />
          </div>
        )}
      </div>
      {isLoading ? (
        renderLoading()
      ) : (
        <div>
          <div className={classNames({
            "lg:grid lg:grid-cols-2 gap-4 flex flex-col":
              activeTab === Period.BIWEEKLY
          })}>
            <DataCharts
              activeTab={activeTab}
              dataDaily={dataDaily.data as IBaseDataProduction[]}
              dataBiweekly={dataBiweekly.data as IBaseDataProduction[]}
              dataMonthly={dataMonthly.data as IBaseDataProduction[]}
              windowWidth={windowWidth}
            />
            <ConditionalWrapper
              condition={activeTab !== Period.BIWEEKLY}
              wrapper={children => <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">{children}</div>}>
              <Fragment>
                <DataTable
                  activePage={"acumulation"}
                  activeTab={activeTab}
                  dataDaily={sortMilkRecordsItems(dataDaily.data!)}
                  dataBiweekly={dataBiweekly.data as IBaseDataProduction[]}
                  dataMonthly={dataMonthly.data as IBaseDataProduction[]}
                  monthlyDataFields={monthlyDataFields}
                />
                <Caption
                  className={classNames({ "col-span-2": activeTab===Period.BIWEEKLY })}
                  activePage={"acumulation"}
                  activeTab={activeTab} />
              </Fragment>
            </ConditionalWrapper>
          </div>
        </div>
      )}

    </div>
  );
}
