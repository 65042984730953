import { Button, Text } from "@glorialabs/gl-components-module-ui";
import Filesent from "../../../../assets/Filesent.svg";

interface ConfirmationDownloadProps {
  onClick?: () => void;
}

export const ConfirmationDownload = ({
  onClick
}: ConfirmationDownloadProps) => {
  return (
    <div className="flex flex-col items-center">
      <Text size="2xl" bold className="text-3xl my-3 text-center">
        Tu archivo fue descargado con éxito.
      </Text>

      <Text
        size="xl"
        className="flex flex-col lg:flex-row text-center tracking-wider"
      >
        Revísalo en la carpeta de descargas de tu computadora
      </Text>

      <div className="my-5">
        <Filesent />
      </div>

      <div className="flex flex-col lg:flex-row w-full justify-center items-center">
        <Button
          name="regresar"
          variant="primary"
          className="w-[135px]"
          onClick={onClick}
          type="button"
        >
          Ir a inicio
        </Button>
        {/* </Link> */}
      </div>
    </div>
  );
};
