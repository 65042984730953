import { Button, Text } from "@glorialabs/gl-components-module-ui";
import CheckIcon from "../../../../assets/Check.svg";
import { Link } from "react-router-dom";
import { IResponseAddUser } from "../../../../services/master/response.interface";

interface Props {
  formikResponse?: IResponseAddUser;
}
export const ConfirmationAlert = ({ formikResponse }: Props) => {
  return (
    <div className="flex flex-col items-center">
      <div className="max-w-[760px]">
        <Text size="2xl" bold className="text-3xl mt-3 text-center">
          Se enviaron las intrucciones para el inicio de sesión
        </Text>

        <div className="my-5 flex justify-center w-full">
          <CheckIcon />
        </div>

        <Text
          size="base"
          className="flex flex-col lg:flex-row text-center mx-4"
        >
          {formikResponse?.User.Username} recibirá un correo electrónico con su
          primera contraseña y las instrucciones para acceder al Portal
          Ganadero.
        </Text>
        <p className="mt-2 text-center mx-4">
          Por favor indícale que revise su correo. En caso no lo encuentre, que
          busque en su carpeta de no deseados
        </p>

        <div className="flex flex-row mt-8 justify-center">
          <Link to={"/usuarios"}>
            <Button name="listUser" variant="primary">
              Ver usuarios
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
