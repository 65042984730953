import {
  Button, Input, Text
} from "@glorialabs/gl-components-module-ui";
import { FormikProps } from "formik";
import {
  FunctionComponent, useEffect, useState
} from "react";
import { getLastCharacters } from "./utils/getLastCharacters";
import axios from "axios";
import { IValidateMFAForm } from "./form";
import { IResponseChangePassword, IResponseLogin } from "../../../../services/master/response.interface";

interface IValidateMFA {
  formik: FormikProps<IValidateMFAForm>;
  responseLogin: IResponseLogin | undefined;
  responseFirstPass: IResponseChangePassword | undefined;
  isLoadingMFA: boolean;
  error: unknown;
  resendLogin: () => Promise<void>;
  resendChangePass: () => Promise<void>;
  isLoadingResend: boolean;
  originMFA: "login" | "newPassword";
}

export const ValidateMFA: FunctionComponent<IValidateMFA> = ({
  formik,
  responseLogin,
  responseFirstPass,
  isLoadingMFA,
  error,
  resendLogin,
  resendChangePass,
  originMFA,
  isLoadingResend
}) => {
  const { errors, handleSubmit } = formik;
  const [counter, setCounter] = useState<number>(20);
  const codeDeliveryDestination =
    responseFirstPass?.ChallengeParameters.CODE_DELIVERY_DESTINATION ??
    responseLogin?.ChallengeParameters.CODE_DELIVERY_DESTINATION;

  const [localError, setLocalError] = useState<string | undefined>(undefined);

  useEffect(() => {
    const interval = setInterval(() => {
      if (counter > 0) {
        setCounter(counter - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [counter]);

  useEffect(() => {
    if (axios.isAxiosError(error)) {
      setLocalError(error.response?.data.message);
    }
  }, [error]);

  return (
    <div className="flex flex-col justify-center items-center">
      <p className="mb-8 text-2xl	color-[#000000] tracking-wider">
        {originMFA === "newPassword"
          ? "¡Tu contraseña fue actualizada!"
          : "Bienvenido"}
      </p>
      {originMFA === "newPassword" ? (
        <>
          <p className="text-center mb-4 w-3/4 md:w-5/6 lg:w-4/6 px-4">
            Como paso final, y para proteger tu cuenta, te hemos enviado un
            código de verificación por mensaje de texto al número que termina en{" "}
            {getLastCharacters(codeDeliveryDestination as string, 3)}.
          </p>
          <p className="text-center mb-4 w-3/4 md:w-5/6 lg:w-4/6 px-4">
            Una vez que recibas el código, ingrésalo en el campo de abajo para
            que puedas ingresar al Portal Ganadero de Gloria
          </p>
        </>
      ) : (
        <>
          {" "}
          <p className="text-center mb-4 w-3/4 md:w-5/6 lg:w-4/6 px-4">
            Para verificar tu identidad, te hemos enviado un código por mensaje
            de texto a tu celular terminado en{" "}
            {getLastCharacters(codeDeliveryDestination as string, 3)}. Si no lo
            has recibido, verifica tu carpeta de spam.
          </p>
          <p className="text-center mb-4">
            Ingresa el código de verificación en el campo a continuación.
          </p>
        </>
      )}

      <form onSubmit={handleSubmit}>
        <Input
          {...formik.getFieldProps("codeMFA")}
          label="Código de verificación"
          suggestionText="Ingresa el código enviado por mensaje de texto"
          className="w-[361px] mb-6"
          maxLength={6}
          onChange={(e) => {
            const value = e.target.value;
            if (/^\d*$/.test(value)) {
              formik.handleChange(e);
              setLocalError(undefined);
            }
          }}
          variant={localError ?? errors.codeMFA ? "error" : undefined}
          feedback={localError ?? errors.codeMFA ?? undefined}
        />
        <Button className="w-[361px] mb-4" type="submit" loading={isLoadingMFA}>
          Continuar
        </Button>
      </form>
      {counter === 0 ? (
        <Button
          loading={isLoadingResend}
          onClick={async () => {
            if (originMFA === "newPassword") {
              await resendChangePass();
            } else {
              await resendLogin();
            }
            setCounter(20);
          }}
          className="w-[361px] mb-4"
          type="button"
          variant="secondary"
        >
          Reenviar código
        </Button>
      ) : (
        <Text className="rounded-2xl mt-4 align-middle">
          Reenviar código en {counter} segundos
        </Text>
      )}
    </div>
  );
};
