import { useQuery } from "react-query";
import CardItem from "../components/CardItem";
import {
  Fragment, useContext, useState
} from "react";
import { AuthContext } from "../../../context/AuthContext";
import { getPurchasesByCowfarmer } from "../../../services/services/api";
import {
  Input, Spinner, Text
} from "@glorialabs/gl-components-module-ui";
import { IGetPurchasesResponse } from "../../../services/services/response.interface";
import { shoppingDataColumns } from "../utils/shoppingColumns";
import { ONE_HOUR } from "../../../constant/staleTimes";

export default function ShoppingPage() {
  const { cowfarmer } = useContext(AuthContext);

  const { data, isLoading } = useQuery(["shops"], () =>
    getPurchasesByCowfarmer(cowfarmer!.id),
  {
    staleTime: ONE_HOUR
  }
  );

  const [searchValue, setSearchValue] = useState<string | undefined>("");

  if (isLoading)
  {return (
    <div
      className="bg-red flex justify-center align-middle items-center flex-col"
      style={{ height: "calc(100vh - 210px)" }}
    >
      <Spinner />
      <Text size="2xl" className="text-[#07196D]">
        Cargando
      </Text>
    </div>
  );}

  const handleSearch = (value: string) => {
    const sanitizedValue = value.trimStart();

    // Evitar que comiencen con espacios
    setSearchValue(sanitizedValue);
  };

  // Aplicar el filtro
  const filteredData = data?.filter((res: IGetPurchasesResponse) => {
    if (!searchValue) {
      return true;
    }

    if (searchValue.length > 2) {
      const documentRef = res.detailInfo?.additionalInfo?.[0]?.detail?.toLowerCase() || "";

      // Realizar la búsqueda
      return documentRef.includes(searchValue.toLowerCase());

    } else {
      return true;
    }
  });

  return (
    <div>
      <Text size="4xl" bold={true} className="text-3xl mb-6  w-[361px]">
        Compras
      </Text>

      <div>
        <Input
          label="Buscar por factura"
          variant="primary"
          type="text"
          disabled={false}
          value={searchValue}
          name={undefined}
          className={"pb-8 w-full lg:w-[361px]"}
          min={undefined}
          max={undefined}
          inputStyle={undefined}
          labelStyle={undefined}
          onChange={(e) => {
            if (!e.target.value.trim()) {
              // Si solo contiene espacios en blanco, establecer el valor en vacío
              handleSearch("");
            } else {
              // Si contiene caracteres distintos de espacios, actualizar el estado
              handleSearch(e.target.value);
            }
          }}
          placeholder="Ingresa un número de factura"
        />
      </div>

      <div>
        {filteredData!.length > 0 ? (
          filteredData?.map((res: IGetPurchasesResponse) => (
            <Fragment key={res.generalInfo.id}>
              <CardItem
                generalInfo={res.generalInfo}
                detailInfo={res.detailInfo}
                tableInfo={res.tableInfo}
                columnsTable={shoppingDataColumns}
                divider={false}
                purchaseInfo={res.purchaseInfo}
              />
            </Fragment>
          ))
        ) : (
          <div className="bg-[#F0EDFF] rounded-2xl w-full px-6 py-8">
            No hay compras que mostrar
          </div>
        )}
      </div>
    </div>
  );
}
