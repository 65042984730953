import { apiMaster } from "../api";
import {
  IBodyAddUser,
  IBodyChangePassword,
  IBodyConfirmForgetPassword,
  IBodyDisableUser,
  IBodyGetUserById,
  IBodyLogin,
  IBodyMFA,
  IBodyRegister,
  IBodySendEmailForgetPassword,
  IBodyTerms,
  IBodyUpdateUser
} from "./request.interface";
import {
  ILogoutResponse,
  IResponseChangePassword,
  IResponseConfirmForgetPassword,
  IResponseListUserMaster,
  IResponseLogin,
  IResponseMFA,
  IResponseSendEmailForgetPassword,
  IResponseTerms,
  IResponseUserMaster,
  IRegisterAlertResponse,
  IResponseAddUser,
  IResponseGetUserProfiles
} from "./response.interface";

export const login = async (body: IBodyLogin): Promise<IResponseLogin> => {
  const response = await apiMaster.post("user/authUser", body);
  return response.data.data;
};

export const verifyMyMFA = async (body: IBodyMFA): Promise<IResponseMFA> => {
  const response = await apiMaster.post("user/authVerifyMfa", body);
  return response.data.data;
};

export const changeFirstPassword = async (
  body: IBodyChangePassword
): Promise<IResponseChangePassword> => {
  const response = await apiMaster.post("user/changePasswordRequired", body);
  return response.data.data;
};

export const sendEmailForgetPassword = async (
  body: IBodySendEmailForgetPassword
): Promise<IResponseSendEmailForgetPassword> => {
  const response = await apiMaster.post("user/forgotPassword", body);
  return response.data.data;
};

export const confirmForgetPassword = async (
  body: IBodyConfirmForgetPassword
): Promise<IResponseConfirmForgetPassword> => {
  const response = await apiMaster.post("user/confirmForgotPassword", body);
  return response.data.data;
};

export const registerAlert = async (
  body: IBodyRegister
): Promise<IRegisterAlertResponse> => {
  const response = await apiMaster.post("user/newAccountCreationRequest", body);
  return response.data.data;
};

export const addUser = async (body: IBodyAddUser): Promise<IResponseAddUser> => {
  const response = await apiMaster.post(
    "user/invitationNewUserWithMaster",
    body
  );
  return response.data.data;
};

export const getUserProfile = async (): Promise<IResponseGetUserProfiles> => {
  const response = await apiMaster.get("profilesPermissions/getUserProfiles");
  return response.data;
};

export const getListUserByMaster = async (
  email: string,
  limit: number,
  offset: number
): Promise<IResponseListUserMaster> => {
  const response = await apiMaster.get(
    "user/getListUserByMaster",
    {
      params: {
        email,
        limit,
        offset
      }
    }
  );
  return response.data.data;
};

export const disableUser = async (
  params: IBodyDisableUser
): Promise<IResponseUserMaster> => {
  const response = await apiMaster.post("user/toggleUserActive", {
    email:        params.email,
    master_email: params.master_email
  }
  );
  return response.data;
};

export const getUserById = async (
  params: IBodyGetUserById
): Promise<IResponseUserMaster> => {
  const response = await apiMaster.get("user/getUserById", {
    params: {
      id:           params.id,
      master_email: params.master_email
    }
  });
  return response.data;
};

export const updateUser = async (body: IBodyUpdateUser): Promise<string> => {
  const response = await apiMaster.patch("user/updateUserWithMaster", body);

  return response.data;
};

export const updateTerms = async (
  body: IBodyTerms
): Promise<IResponseTerms> => {
  const response = await apiMaster.patch("user/updateTermsAndConditions", body);
  return response.data;
};

export const logOutUser = async (): Promise<ILogoutResponse> => {
  const response = await apiMaster.get(
    "user/logout"
  );
  return response.data.data;
};
