import axios from "axios";
import { getCognitoToken } from "./Cognito/api";
import apiConfig from "../configs/api.config";

const baseURLMasterApi = apiConfig.URL_MASTER_API;
const baseURLQualityApi = apiConfig.URL_QUALITY_API;
const baseURLCowfarmersApi = apiConfig.URL_COWFARMERS_API;
const baseURLServicesApi = apiConfig.URL_SERVICES_API;
const baseURLCognitoApi = apiConfig.COGNITO_OAUTH2.url;

const createApiInstance = (baseURL: string) => {
  const instance = axios.create({ baseURL });

  instance.interceptors.request.use(
    async (config) => {
      const accesToken = localStorage.getItem("token");
      const idToken = localStorage.getItem("idToken");
      const refresh = localStorage.getItem("refresh");

      if (accesToken) {
        config.headers.token = idToken;
        config.headers.app = "1008";
        config.headers.refresh = refresh;
        config.headers.Authorization = `Bearer ${accesToken}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error instanceof Error ? error : new Error(error));
    }
  );

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response && (error.response.status === 401 || error.response.status === 400)) {
        try {
          const accesToken = await getCognitoToken();
          localStorage.setItem("token", accesToken);
          if (accesToken) {
            // Si se obtiene un nuevo token con éxito, reintentar la solicitud original.
            const originalRequest = error.config;
            originalRequest.headers.Authorization = `Bearer ${accesToken}`;
            return axios(originalRequest);
          }
        } catch (refreshError) {
          console.error("Error al refrescar el token:", refreshError);
        }
      }
      return Promise.reject(error instanceof Error ? error : new Error(error));
    }
  );

  return instance;
};

export const apiMaster = createApiInstance(baseURLMasterApi);
export const apiQuality = createApiInstance(baseURLQualityApi);
export const apiCowfarmers = createApiInstance(baseURLCowfarmersApi);
export const apiServices = createApiInstance(baseURLServicesApi);
export const apiCognito = axios.create({
  baseURL: baseURLCognitoApi
});
