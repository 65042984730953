import { Button } from "@glorialabs/gl-components-module-ui";
import { FormikProps } from "formik";
import {
  Dispatch, FunctionComponent, SetStateAction
} from "react";
import { IRegisterAlertResponse } from "../../../../services/master/response.interface";

interface IConfirmationRegister {
  formik: FormikProps<{ rucOrDni: string }>;
  responseRegister: IRegisterAlertResponse;
  setScreen: Dispatch<
    SetStateAction<
      | "login"
      | "validateMFA"
      | "newPassword"
      | "completeEmail"
      | "confirmationForgetPassword"
      | "verificationCode"
      | "registerAuth"
      | "alreadyExistRegister"
      | "confirmationRegister"
      | "notFoundRegister"
    >
  >;
}

export const ConfirmationRegister: FunctionComponent<IConfirmationRegister> = ({
  formik,
  responseRegister,
  setScreen
}) => {
  return (
    <>
      <p className="mb-8 text-2xl	color-[#000000] tracking-wider">
        Regístrate al Portal Ganadero
      </p>

      <div className="w-[361px]">
        <p className="text-base text-[#0A0036] font-bold text-center mb-2">
          Tu solicitud fue enviada con éxito
        </p>
        <p className="text-base text-[#0A0036] text-center">
          Te llegará un correo electrónico a la cuenta registrada (
          {responseRegister?.sendTo}) en máximo 48 horas con los pasos a seguir.
        </p>
      </div>

      <Button
        name="Iniciar Sesión"
        variant="primary"
        className="w-[361px] rounded-2xl mt-8"
        onClick={() => {
          formik.resetForm();
          setScreen("login");
        }}
      >
        Volver a inicio
      </Button>
    </>
  );
};
