export const paths = {
  actives:                "/myservices/actives/",
  acumulation:            "/myproduction/acumulation/",
  certificates:           "/myproduction/certificates/",
  composition:            "/myproduction/composition/",
  downloadProduction:     "/myproduction/descarga/",
  home:                   "/inicio",
  loans:                  "/myservices/loans/",
  news:                   "/noticias",
  newsDetail:             "/noticias/:slug",
  quality:                "/myproduction/quality/",
  shopping:               "/myservices/shopping/",
  notifications:          "/notificaciones",
  termsAndConditions:     "/terminos-y-condiciones-de-uso",
  users:                  "/usuarios",
  usersAdd:               "/usuarios/agregar",
  usersDetail:            "/usuarios/detail/:action/:id",
  viewTermsAndConditions: "/ver/terminos-y-condiciones-de-uso"
};
