import { Route, Routes } from "react-router-dom";

import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";

import { paths } from "./paths";

import { Auth } from "../pages/Auth/Auth";
import Home from "../pages/Home/HomePage";
import AcumulationPage from "../pages/MyProduction/Accumulation/AcumulationPage";
import QualityPage from "../pages/MyProduction/Quality/QualityPage";
import CompositionPage from "../pages/MyProduction/Composition/CompositionPage";
import CertificationPage from "../pages/MyProduction/Certificates/Certificates";
import ActivesPage from "../pages/MyServices/Actives";
import LoansPage from "../pages/MyServices/loans/Loans";
import NotificationsPage from "../pages/Notifications/Notification";
import NewsPage from "../pages/News/NewsPage";
import DetailNew from "../pages/News/DetailNewPage";
import { TermsAndConditions } from "../pages/TermsAndConditions/TermsAndConditions";
import { UsersPage } from "../pages/Users/UsersPage";
import { DetailUserPage } from "../pages/Users/DetailUser/DetailUserPage";
import DownloadInfoPage from "../pages/MyProduction/DownloadInfoPage/DownloadInfoPage";
import { OnlyViewTermsAndConditions } from "../pages/TermsAndConditions/OnlyViewTermsAndConditions";
import { PageNotFound } from "../pages/PageNotFound";
import AddUserPage from "../pages/Users/AddUser/AddUserPage";
import ShoppingPage from "../pages/MyServices/shops/Shopping";
import { ROLES } from "../configs/roles.config";

const MainRoutes = () => (
  <Routes>
    <Route element={<PublicRoute />}>
      <Route path="/" element={<Auth />} />
    </Route>

    <Route element={<ProtectedRoute />}>
      <Route path={paths.home} element={<Home />} />
      <Route path={paths.termsAndConditions} element={<TermsAndConditions />} />
      <Route path={paths.notifications} element={<NotificationsPage />} />
      <Route
        path={paths.viewTermsAndConditions}
        element={<OnlyViewTermsAndConditions />}
      />
      <Route path={paths.news} element={<NewsPage />} />
      <Route path={paths.newsDetail} element={<DetailNew />} />
    </Route>

    <Route
      element={
        <ProtectedRoute rolesRequired={[ROLES.OWNER_COWBARN, ROLES.ADMIN_TI]} />
      }
    >
      <Route path={paths.users} element={<UsersPage />} />
      <Route path={paths.usersAdd} element={<AddUserPage />} />
      <Route path={paths.usersDetail} element={<DetailUserPage />} />
    </Route>

    <Route
      element={
        <ProtectedRoute
          rolesRequired={[
            ROLES.OWNER_COWBARN,
            ROLES.ADMIN_TI,
            ROLES.FINANCIAL_COWBARN,
            ROLES.ADMIN_COWBARN
          ]}
        />
      }
    >
      <Route path={paths.loans} element={<LoansPage />} />
      <Route path={paths.shopping} element={<ShoppingPage />} />
      <Route path={paths.actives} element={<ActivesPage />} />
    </Route>

    <Route
      element={
        <ProtectedRoute
          rolesRequired={[
            ROLES.OWNER_COWBARN,
            ROLES.ADMIN_TI,
            ROLES.VET_COWBARN,
            ROLES.ADMIN_COWBARN
          ]}
        />
      }
    >
      <Route path={paths.acumulation} element={<AcumulationPage />} />
      <Route path={paths.quality} element={<QualityPage />} />
      <Route path={paths.composition} element={<CompositionPage />} />
      <Route path={paths.certificates} element={<CertificationPage />} />
      <Route path={paths.downloadProduction} element={<DownloadInfoPage />} />
    </Route>

    <Route path="*" element={<PageNotFound />} />
  </Routes>
);

export default MainRoutes;
