import moment from "moment";
import { ColumnProps } from "@glorialabs/gl-components-module-ui";
import { formatMilesNumber } from "../../../utils";
import { getInstallmentStatus } from "./getInstallmentStatus";
import { ILoanInfo } from "../../../services/services/interface";
import { getColorByStatus } from "./getColorByStatus";

export const loanDataColumns: ColumnProps[] = [
  {
    title:  "Proxima cuota",
    key:    "fee",
    align:  "left",
    render: (
      _: number,
      row: ILoanInfo,
      __: boolean,
      ___?: (() => void) | undefined,
      ____?: number | undefined
    ) => (
      <p>
        {row.loan_installments_number} de {row.total_installment}
      </p>
    )
  },
  {
    title:  "Vencimiento",
    key:    "expiration",
    align:  "left",
    render: (_: number, record: ILoanInfo) => (
      <p>{moment(record.installment_due_date).format("DD/MM/YYYY")}</p>
    )
  },
  {
    title:  "Capital",
    key:    "capital",
    align:  "left",
    render: (_: number, record: ILoanInfo) => (
      <p>S/ {formatMilesNumber(parseFloat(record.loan_installments_amount.toFixed(2)))}</p>
    )
  },
  {
    title:  "Interés",
    key:    "interest",
    align:  "left",
    render: (_: number, record: ILoanInfo) => (
      <p className={`${record.loan_installments_interest === 0 && "text-[#EF863D]"}`}>
        {
          record.loan_installments_interest === 0 ?
            "Por calcular" :
            `S/ ${formatMilesNumber(parseFloat( record.loan_installments_interest.toFixed(2)))}`
        }
      </p>
    )
  },
  {
    title:  "Estado",
    key:    "state",
    align:  "left",
    render: (_: number, record: ILoanInfo) => {
      const colorStatus = getColorByStatus(record.installment_due_date, record.status);
      return (
        <p style={{ color: colorStatus }}>
          {getInstallmentStatus(record.installment_due_date, record.status)}
        </p>
      );}
  }
];
