import { Button } from "@glorialabs/gl-components-module-ui";
import { AxiosError } from "axios";
import { FormikProps } from "formik";
import {
  Dispatch, FunctionComponent, SetStateAction
} from "react";

interface INotFoundRegister {
  formik: FormikProps<{ rucOrDni: string }>;
  setScreen: Dispatch<
    SetStateAction<
      | "login"
      | "validateMFA"
      | "newPassword"
      | "completeEmail"
      | "confirmationForgetPassword"
      | "verificationCode"
      | "registerAuth"
      | "alreadyExistRegister"
      | "confirmationRegister"
      | "notFoundRegister"
    >
  >;
  error: AxiosError<{ statusCode: number; message: string }> | null;
}

export const NotFoundRegister: FunctionComponent<INotFoundRegister> = ({
  formik,
  setScreen,
  error
}) => {

  if (!error || !error.response) {
    return (
      <div className="error-message">
        <p>Ocurrió un error inesperado.</p>
      </div>
    );
  }

  const { response } = error;

  const message = response.data?.message;

  return (
    <>
      <p className="mb-8 text-2xl	color-[#000000] tracking-wider">
        Registrate al Portal Ganadero
      </p>

      <div className="w-[361px]">
        <p className="text-base text-[#0A0036] font-bold text-center mb-2">
          {message || "No encontramos proveedores ganaderos registrados con ese número de DNI o RUC."}
        </p>
        <p className="text-base text-[#0A0036] text-center">
          Por favor, comunícate con tu asesor de campo para que te pueda ayudar
        </p>
      </div>

      <Button
        name="Iniciar Sesión"
        variant="primary"
        className="w-[361px] rounded-2xl mt-8"
        onClick={() => {
          formik.resetForm();
          setScreen("login");
        }}
      >
        Volver a inicio
      </Button>
    </>
  );
};
