import { apiQuality } from "../api";
import {
  IDataAcumulationResponse,
  IDownloadQualityInfoResponse,
  ILastMeasurementAcopioResponse,
  ILastMeasurementQualityResponse
} from "./response.interface";
import { IBodyDownloadQualityInfo } from "./request.interface";

export const getLastMeasurementAcopio = async (
  cowbarnId: number
): Promise<ILastMeasurementAcopioResponse> => {
  const response = await apiQuality.get("/lotMilk/getLastMeasurementAcopio", {
    params: {
      cowbarnId: cowbarnId
    }
  });
  return response.data.data;
};

export const getLastMeasurementQualityAndComposition = async (
  cowbarnId: number
): Promise<ILastMeasurementQualityResponse> => {
  const response = await apiQuality.get(
    "/lotMilk/getLastMeasurementIndicators",
    {
      params: {
        cowbarnId: cowbarnId
      }
    }
  );

  return response.data.data;
};

export const getDataAcumulation = async (
  cowbarnId: number,
  byPeriod: number
): Promise<IDataAcumulationResponse> => {
  const response = await apiQuality.get("/lotMilk/getDataAcopio", {
    params: {
      cowbarnId,
      byPeriod
    }
  });

  return response.data.data;
};

export const getDownloadQualityInfo = async (
  body: IBodyDownloadQualityInfo
) : Promise<IDownloadQualityInfoResponse> => {
  const response = await apiQuality.post("lotMilk/downloadQualityInfo", body);
  return response.data.data;
};
