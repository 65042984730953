import { IDownloadDataForm } from "../form";

export const hasAnyOptionSeleected = (
  formikInitialValues: IDownloadDataForm
) => {
  const newObj: { [x: string]: boolean } = {};

  Object.entries(formikInitialValues).forEach(([key, value]) => {
    const isIndicator = key.startsWith("option_");

    if (!isIndicator) {
      return;
    }

    newObj[key] = value;
  });

  const isOptionSelected = Object.values(newObj).find((value) => value);

  return !!isOptionSelected;
};
