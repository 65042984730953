import { Button, Input } from "@glorialabs/gl-components-module-ui";
import { FormikProps } from "formik";
import {
  Dispatch, FunctionComponent, SetStateAction, useEffect
} from "react";
import { IAuthForm } from "../../form";
import axios from "axios";
import { IResponseLogin } from "../../../../services/master/response.interface";
import { showCustomMessage } from "./form";

interface ILogin {
  formik: FormikProps<IAuthForm>;
  isLoadingLogin: boolean;
  responseLogin: IResponseLogin | undefined;
  setOriginMFA: (origin: "login" | "newPassword") => void;
  error: unknown;
  setScreen: Dispatch<
    SetStateAction<
      | "login"
      | "validateMFA"
      | "newPassword"
      | "completeEmail"
      | "confirmationForgetPassword"
      | "verificationCode"
      | "registerAuth"
      | "alreadyExistRegister"
      | "confirmationRegister"
      | "notFoundRegister"
    >
  >;
}

export const Login: FunctionComponent<ILogin> = ({
  formik,
  isLoadingLogin,
  setScreen,
  error,
  setOriginMFA
}) => {
  const { handleSubmit, errors } = formik;

  useEffect(() => {
    setOriginMFA("login");
  }, [setOriginMFA]);

  return (
    <>
      <p className="mb-2 text-2xl	color-[#000000] tracking-wider w-full text-center font-bold">
        Te damos la bienvenida <span className="lg:hidden">al
          Nuevo Portal Ganadero</span>
      </p>
      <p className="color-[#000000] text-base mb-8">
        Ingresa tus datos para iniciar sesión
      </p>
      <form onSubmit={handleSubmit}>
        <Input
          {...formik.getFieldProps("email")}
          label="Correo electrónico"
          maxLength={50}
          className="w-full lg:w-[361px] mb-4"
          suggestionText="Ingresa tu correo electrónico"
          type="email"
          feedback={
            axios.isAxiosError(error)
              ? showCustomMessage(error.response?.data.message)
              : errors.email ?? undefined
          }
          variant={
            axios.isAxiosError(error) || errors.email ? "error" : undefined
          }
        />
        <Input
          {...formik.getFieldProps("password")}
          label="Contraseña"
          maxLength={45}
          className="w-full lg:w-[361px] mb-6"
          suggestionText="Ingresa tu contraseña"
          type="password"
          feedback={
            axios.isAxiosError(error)
              ? error.response?.data.message
              : errors.password ?? undefined
          }
          variant={
            axios.isAxiosError(error) || errors.password ? "error" : undefined
          }
        />
        <div className="flex justify-end mb-6">
          <button
            className="cursor-pointer"
            onClick={() => setScreen("completeEmail")}
            style={{ background: "none", border: "none" }}
            type="button"
          >
            <p className="text-center font-bold underline">Olvidé mi contraseña</p>
          </button>
        </div>
        <Button
          name="Iniciar Sesión"
          variant="primary"
          className="w-[361px] rounded-2xl mb-2"
          type="submit"
          loading={isLoadingLogin}
        >
          Iniciar sesión
        </Button>
      </form>
      <Button
        name="register"
        variant="primary"
        outline
        className="w-[361px] rounded-2xl mb-2"
        onClick={() => setScreen("registerAuth")}
      >
        Quiero registrarme
      </Button>

    </>
  );
};
