import moment from "moment";
import { FunctionComponent } from "react";
import { INotification } from "../../../services/services/interface";

interface INotificationCard {
  notification: INotification;
  className?: string;
}

export const NotificationCard: FunctionComponent<INotificationCard> = ({
  notification,
  className
}) => {
  return (
    <div className={`bg-[#F0EDFF] rounded-2xl p-4 w-full ${className}`}>
      <h3 className="font-bold text-[#0A0036] text-base mb-2">
        {notification.title}
      </h3>
      <p className="text-xs text-[#5A5A5F] mb-2">
        Fecha de envío {moment(notification.date).utc().format("DD/MM/YY")}
      </p>
      <p className="text-sm">{notification.content}</p>
    </div>
  );
};
