import { QueryClientProvider } from "react-query";
import MainRoutes from "./routes";
import { AuthProvider } from "./context/AuthContext";
import { getCognitoToken } from "./services/Cognito/api";
import { useEffect } from "react";
import AxiosErrorHandler from "./components/AxiosErrorHandler/AxiosErrorHandler";
import { useLocation } from "react-router-dom";
import { initializeGA, logPageView } from "./configs/analytics.config";
import { queryClient } from "./configs/queryClient.config";

function App() {

  const location = useLocation();

  // Inicializa Google Analytics
  useEffect(() => {
    initializeGA("G-4W2BNDMCRK");
  }, []);

  // Hook para registrar las vistas de página
  useEffect(() => {
    logPageView(location.pathname + location.search);
  }, [location]);

  const getToken = async () => {
    try {
      const currentAccessToken = localStorage.getItem("token");
      if (!currentAccessToken) {
        const token = await getCognitoToken();
        localStorage.setItem("token", token);
      }
    } catch (error) {
      console.error("Error al obtener el token:", error);
    }
  };

  useEffect(() => {
    getToken();
  }, []);

  return (
    <AuthProvider>
      <AxiosErrorHandler>
        <QueryClientProvider client={queryClient}>
          <div id="overlays" />
          <div id="dropdown-floating-menu" />
          <MainRoutes />
        </QueryClientProvider>
      </AxiosErrorHandler>
    </AuthProvider>
  );
}

export default App;
