import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import "@glorialabs/gl-components-module-ui/dist/index.css";
import moment from "moment";
import "moment/dist/locale/es";
import { BrowserRouter } from "react-router-dom";
moment.locale("es");

ReactDOM.createRoot(document.getElementById("root")!).render(
  <BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>
);
