import { InfoType } from "../../../configs/interfaces";
import { ILoanInfo } from "../../../services/services/interface";
import BaseCard from "./BaseServicesCard";
import React from "react";

interface LoanCardProps {
  readonly info: InfoType;
}

const LoanCard: React.FC<LoanCardProps> = ({ info }) => {
  const loanInfo = info as ILoanInfo;

  return (
    <BaseCard
      info={loanInfo}
      iconName="PrestamosIcon"
      headerText="Préstamos"
      amount={loanInfo.loan_installments_amount}
      interest={loanInfo.loan_installments_interest}
    />
  );
};

export default LoanCard;
