import { useMemo } from "react";
import { useQuery } from "react-query";
import pdfIcon from "../../assets/images/pdf.png";
import excelIcon from "../../assets/images/excel.png";
import wordIcon from "../../assets/images/word.png";
import { getDetailNew } from "../../services/services/api";
import moment from "moment";
import {
  Card, Spinner, Text
} from "@glorialabs/gl-components-module-ui";
import { useParams } from "react-router-dom";
import { FileGenerate } from "../../services/services/interface";

export default function DetailNew() {
  const { slug } = useParams();

  const { data, isFetching: isLoading } = useQuery("NewDetail", () => getDetailNew(slug!));

  const attachedFiles = useMemo(() => {
    let files: FileGenerate[] = [];
    if (data) {
      const { attach1, attach2, attach3 } = data;

      const attachments = [attach1, attach2, attach3];

      const filteredAttachments = attachments.filter(
        (attach): attach is FileGenerate => attach !== undefined
      );
      const finalAttachments =
        filteredAttachments.length > 0 ? filteredAttachments : [];

      files = finalAttachments;
    }
    return files;
  }, [data]);

  const handleDownloadFile = (urlFile: string, file: string) => {
    // Lógica para descargar el archivo
    const url = urlFile; // Reemplaza con la URL real del archivo
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", file); // Reemplaza con el nombre deseado para el archivo')
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getImageFile = (type: string, imagePreview: string) => {
    const fileExtension = type.substring(type.lastIndexOf(".") + 1);

    switch (fileExtension.toLowerCase()) {
      case "pdf":
        return pdfIcon;
      case "doc":
      case "docx":
        return wordIcon;
      case "xlsx":
      case "xls":
        return excelIcon;
      case "png":
      case "jpg":
      case "jpeg":
        return imagePreview;
      default:
        return imagePreview;
    }
  };

  const getDataFile = (
    file: string
  ): { fileName: string; fileExtension: string } => {
    const fileName = file;
    const fileExtension = fileName.substring(fileName.lastIndexOf(".") + 1);

    return { fileName, fileExtension };
  };

  if (isLoading) {
    return (
      <div
        className="bg-red flex justify-center align-middle items-center flex-col"
        style={{ height: "calc(100vh - 210px)" }}
      >
        <Spinner />
        <Text size="2xl" className="text-[#07196D]">
          Cargando
        </Text>
      </div>
    );
  }

  const renderAttachs = (attachs:FileGenerate[]) => {
    if (attachs.length > 0) {
      return (
        <div>
          <h2 className="text-black text-2xl mb-2">Descargables</h2>
          <p className="text-black text-base mb-8">
            Clic en la imagen para descargar
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {attachedFiles.map((file) => (
              <button
                key={file!.file.path}
                onClick={(e) => {
                  e.preventDefault(); // Evitar la navegación
                  handleDownloadFile(file.url, file.file.path);
                }}
                className="cursor-pointer"
              >
                <Card.Container className="bg-[#E4F0FF] rounded-2xl pb-4 px-4 pt-1 break-words text-black">
                  <Card.Body className="mt-0">
                    <div className="flex justify-center p-2">
                      <img
                        src={getImageFile(file.file.path, file.url)}
                        className="mb-2"
                        alt="Adjunto de la noticia"
                        width={0}
                        height={180}
                        sizes="100vw"
                        style={
                          getDataFile(file.file.path).fileExtension === "pdf" ||
                          getDataFile(file.file.path).fileExtension === "xls" ||
                          getDataFile(file.file.path).fileExtension === "xlsx" ||
                          getDataFile(file.file.path).fileExtension === "docx" ?
                            { width: 150, height: "auto", objectFit: "contain" } :
                            { width: "100%", height: 150, objectFit: "cover" }} // optional
                      />
                    </div>
                    <p className="mb-1 text-2xl truncate text-left">
                      {getDataFile(file.file.path).fileName}
                    </p>
                    <p className="font-bold text-xs text-left">
                      {getDataFile(file.file.path).fileExtension}
                    </p>
                  </Card.Body>
                </Card.Container>
              </button>
            ))}
          </div>
        </div>
      );
    }
  };

  return (
    <div className="text-[#07196D]">
      <h1 className="text-[#0A0036] font-bold text-3xl mb-8">{data?.title}</h1>
      <img
        src={data?.imgPortrait?.url as string}
        className="mb-9"
        alt="imagen de portada"
        width={0}
        height={0}
        sizes="100vw"
        style={{ width: "100%", height: "auto" }} // optional
      />
      <p className="font-bold text-xs mb-4">
        {moment(data?.publication_date).format("DD/MM/YYYY")}
      </p>
      <p className="font-bold text-xs mb-4">{data?.categoryNew.description}</p>
      <p
        className="text-base leading-6 mb-8 new-description"
        dangerouslySetInnerHTML={{ __html: data?.description as string }}
      />
      {attachedFiles && renderAttachs(attachedFiles)}
    </div>
  );
}
