import { Rol } from "../services/master/interface";
import { Cowfarmer, User } from "./AuthInterface";

export interface AuthState {
  errorMessage: string;
  token: string | null;
  user: User | null;
  status: "checking" | "authenticated" | "not-authenticated";
  cowfarmer: Cowfarmer | null;
  cowbarn: number;
  roles: Rol | null;
}

type AuthAction =
  | { type: "notAuthenticated" }
  | { type: "logout" }
  | {
    type: "signIn";
    payload: { token: string; user: User; cowfarmer: Cowfarmer; roles: Rol };
  }
  | { type: "acceptTerms" }
  | { type: "setCowbarn"; payload: { cowbarn: number } };

export const authReducer = (
  state: AuthState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case "signIn":
      return {
        ...state,
        errorMessage: "",
        status:       "authenticated",
        token:        action.payload.token,
        user:         action.payload.user,
        cowfarmer:    action.payload.cowfarmer,
        roles:        action.payload.roles
      };
    case "setCowbarn":
      return {
        ...state,
        cowbarn: action.payload.cowbarn
      };

    case "logout":
    case "notAuthenticated":
      return {
        ...state,
        errorMessage: "",
        status:       "not-authenticated",
        token:        null,
        user:         null,
        cowfarmer:    null
      };

    case "acceptTerms":
      return {
        ...state,
        user: {
          ...(state.user as User),
          condition_1: true,
          condition_2: true
        }
      };

    default:
      return state;
  }
};
