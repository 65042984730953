import { QualityIndicator } from "../../../../enum/QualityIndicator.enum";
import { IDownloadDataForm } from "../form";

export const getIndicatorIds = (
  formikInitialValues: IDownloadDataForm
) => {
  const indicatorIds: number[] = [];

  if (formikInitialValues.option_fat) {
    indicatorIds.push(QualityIndicator.FAT);
  }
  if (formikInitialValues.option_protein) {
    indicatorIds.push(QualityIndicator.PROTEIN);
  }
  if (formikInitialValues.option_ufc) {
    indicatorIds.push(QualityIndicator.RANGE_UFC);
  }
  if (formikInitialValues.option_rcs) {
    indicatorIds.push(QualityIndicator.RANGE_RCS);
  }
  if (formikInitialValues.option_temperature) {
    indicatorIds.push(QualityIndicator.RANGE_TEMPERATURE);
  }
  if (formikInitialValues.option_lotmilk) {
    indicatorIds.push(QualityIndicator.RANGE_VOLUME);
  }

  return indicatorIds;
};
