import { Environments } from "../interfaces/environments.type";
import { Rol } from "../interfaces/rol.type";

const appEnv = import.meta.env.VITE_APP_ENV as Environments || "development";

const rolesConfig: Record<Environments, Record<Rol, string>> = {
  "local": {
    OWNER_COWBARN:     "130",
    ADMIN_COWBARN:     "131",
    VET_COWBARN:       "132",
    FINANCIAL_COWBARN: "133",
    ADMIN_TI:          "134"
  },
  "development": {
    OWNER_COWBARN:     "130",
    ADMIN_COWBARN:     "131",
    VET_COWBARN:       "132",
    FINANCIAL_COWBARN: "133",
    ADMIN_TI:          "134"
  },
  "qas": {
    OWNER_COWBARN:     "151",
    ADMIN_COWBARN:     "148",
    VET_COWBARN:       "149",
    FINANCIAL_COWBARN: "150",
    ADMIN_TI:          "152"
  },
  "production": {
    OWNER_COWBARN:     "122",
    ADMIN_COWBARN:     "120",
    VET_COWBARN:       "124",
    FINANCIAL_COWBARN: "123",
    ADMIN_TI:          "121"
  }
};

export const ROLES = rolesConfig[appEnv];
