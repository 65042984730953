import moment from "moment";
import { QualityIndicator } from "../../../../enum/QualityIndicator.enum";
import { PeriodId } from "../../../../enum/periodId.enum";
import { DateFormat } from "../../../../enum/DateFormat.enum";
import DecimalFormat, { RoundingMode } from "decimal-format";
import { IBaseDataProduction, IDownloadQualityInfo } from "../../../../services/quality/interface";
import { capitalize } from "./capitalize";

const df = new DecimalFormat("#,##0");
df.setRoundingMode(RoundingMode.UP);

export function getHeaders(indicatorIds: number[]) {
  const indicatorDict = {
    fat:         QualityIndicator.FAT,
    protein:     QualityIndicator.PROTEIN,
    UFC:         QualityIndicator.RANGE_UFC,
    RCS:         QualityIndicator.RANGE_RCS,
    volume:      QualityIndicator.RANGE_VOLUME,
    temperature: QualityIndicator.RANGE_TEMPERATURE
  };

  const headerList = [
    { header: "Grasa(%)", key: "fat", width: 15 },
    { header: "Proteína(%)", key: "protein", width: 15 },
    { header: "UFC(ml)", key: "UFC", width: 15 },
    { header: "RCS(ml)", key: "RCS", width: 15 },
    { header: "Acopio(kg)", key: "volume", width: 15 },
    { header: "Temperatura(°C)", key: "temperature", width: 15 }
  ];

  const headerFiltered = headerList.filter((header) => {
    const key = header.key as keyof typeof indicatorDict;
    return indicatorIds.includes(indicatorDict[key]);
  });

  const defaultHeader = {
    header: "Periodo",
    key:    "lot_milk_date",
    width:  15
  };

  return [defaultHeader, ...headerFiltered];
}

export function getDateFormat(lot_milk_date: string, periodId: number) {
  let dateFormated = lot_milk_date;

  if (periodId === PeriodId.DIARIA) {
    dateFormated = moment(lot_milk_date).format(DateFormat.SUDAMERICA);
  } else if (periodId == PeriodId.MENSUAL) {
    dateFormated = moment(lot_milk_date).format(DateFormat.MONTH_YEAR);
    dateFormated = capitalize(dateFormated);
  } else if (periodId === PeriodId.QUINCENAL) {
    const [year, month, quincena] = lot_milk_date.split("_");
    const sufix = quincena === "1" ? "1ra" : "2da";
    const monthC = capitalize(month);

    dateFormated = `${sufix} quincena ${monthC} ${year}`;
  } else if (periodId === PeriodId.LOTE) {
    dateFormated = moment(lot_milk_date).format(DateFormat.SUDAMERICA);
  }

  return dateFormated;
}

export function getBodyFormat(data: IDownloadQualityInfo[], periodId: PeriodId) {
  const countEntregasDict: { [x: string]: number } = {};

  return data.map(lotMilk => {
    let periodDate = getDateFormat(lotMilk.lot_milk_date, periodId);

    // Cambia formato de fecha si el periodo es por lote
    if (periodId === PeriodId.LOTE) {
      // la key es el dia de acopio y el value el contador
      if (!Object.prototype.hasOwnProperty.call(countEntregasDict, periodDate)) {
        countEntregasDict[periodDate] = 0;
      }
      countEntregasDict[periodDate]++;

      const label = ` - Entrega ${countEntregasDict[periodDate]}`;
      periodDate = `${periodDate} ${label}`;
    }

    const fatField = lotMilk.quality.find((q) => q.indicatorId === QualityIndicator.FAT)
      ?.result ?? 0;

    const proteinField = lotMilk.quality.find((q) => q.indicatorId === QualityIndicator.PROTEIN)
      ?.result ?? 0;

    const ufcField = df.format(lotMilk.quality.find((q) => q.indicatorId === QualityIndicator.RANGE_UFC)
      ?.result ?? 0);

    const rcsField = df.format(lotMilk.quality.find((q) => q.indicatorId === QualityIndicator.RANGE_RCS)
      ?.result ?? 0);

    const volumeField = df.format(lotMilk.amount_collected ?? 0);

    const temperatureField = lotMilk.quality.find((q) => q.indicatorId === QualityIndicator.RANGE_TEMPERATURE)
      ?.result ?? 0;

    return {
      lot_milk_date: periodDate,
      fat:           fatField === 0 ? "-" : `${fatField}`,
      protein:       proteinField === 0 ? "-" : `${proteinField}`,
      UFC:           ufcField === "0" ? "-" : `${ufcField}`,
      RCS:           rcsField === "0" ? "-" : `${rcsField}`,
      volume:        volumeField === "0" ? "-" : `${volumeField}`,
      temperature:   temperatureField === 0 ? "-" : `${temperatureField}`
    };
  });
}

export function sortMilkRecords(records: IDownloadQualityInfo[]) {
  // Ordenar por fecha (lot_milk_date) en orden descendente
  const sortedRecords = records.slice().sort((a, b) => b.id - a.id);

  return sortedRecords.slice().sort((a, b) => b.lot_milk_date.localeCompare(a.lot_milk_date));

}

export function sortMilkRecordsItems(records: IBaseDataProduction[]) {
  records.forEach(data => {
    data.items?.sort((a, b) => b.id - a.id);
  });

  return records;
}
