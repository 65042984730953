import moment from "moment";
import {
  ColumnProps,
  ExpandableColumnProps
} from "@glorialabs/gl-components-module-ui";
import { IBaseDataProduction, IdisaggregateData } from "../../../../services/quality/interface";
import { formatBiweeklyDate } from "../../utils/formatDate";

const titleProtein = "Proteína (%)";

export const compositionDailyDataColumns: ColumnProps[] = [
  {
    title:  "Periodo",
    key:    "period",
    render: (_: number, record: IBaseDataProduction, isExpanded, expandRow) => (
      <p>
        {moment(record.lot_milk_date).format("DD/MM/YYYY")}{" "}
        {record.items!.length > 0 && (
          <>
            {" "}
            -{" "}
            <span
              onClick={expandRow}
              onKeyDown={(e) => {
                if (e.key === "Enter" && expandRow) {
                  expandRow();
                }
              }}
              tabIndex={0}
              className="cursor-pointer font-semibold"
              style={{ background: "none", border: "none", padding: 0 }}
            >
              {isExpanded ? "Ocultar Entregas" : "Ver Entregas"}
            </span>
          </>
        )}{" "}
      </p>
    )
  },
  {
    title:  "Grasa (%)",
    key:    "fat",
    align:  "right",
    render: (_: number, record: IBaseDataProduction) => {

      return <p>{record.fatAverageWeighted === 0 ? "-" : record.fatAverageWeighted ?? "-"}</p>;
    }
  },
  {
    title:  titleProtein,
    key:    "temp",
    align:  "right",
    render: (_: number, record: IBaseDataProduction) => {
      return <p>{record.proteinAverageWeighted === 0 ? "-" : record.proteinAverageWeighted ?? "-"}</p>;
    }
  }
];

export const compositionExpandableDailyDataColumns: ExpandableColumnProps[] = [
  {
    title:  "Periodo",
    key:    "periodExpandable",
    render: (index: number) => {
      return <p className="text-[#07196D]">Entrega {index + 1}</p>;
    }
  },
  {
    title:  "Grasa (%)",
    key:    "fatExpandable",
    align:  "right",
    render: (_: number, record: IdisaggregateData) => {
      return (
        <p className="text-[#07196D]">
          {record.fatAverageWeighted === 0 ? "-" : record.fatAverageWeighted ?? "-"}
        </p>
      );
    }
  },
  {
    title:  titleProtein,
    key:    "proteinExpandable",
    align:  "right",
    render: (_: number, record: IdisaggregateData) => {

      return (
        <p className="text-[#07196D]">
          {record.proteinAverageWeighted === 0 ? "-" : record.proteinAverageWeighted ?? "-"}
        </p>
      );
    }
  }
];

export const compositionBiweeklyDataColumns: ColumnProps[] = [
  {
    title:  "Periodo",
    key:    "period",
    render: (_: number, record: IBaseDataProduction) => (
      <p>{record.lot_milk_date ? formatBiweeklyDate(record.lot_milk_date) : "-"}</p>
    )
  },
  {
    title:  "Grasa (%)",
    key:    "biweekly-fatAverage",
    align:  "right",
    render: (_: number, record: IBaseDataProduction) => (
      <p>
        {record.fatAverageWeighted === 0
          ? "-"
          : record.fatAverageWeighted?.toLocaleString("en-US") || "-"}
      </p>
    )
  },
  {
    title:  titleProtein,
    key:    "biweekly-protein",
    align:  "right",
    render: (_: number, record: IBaseDataProduction) => (
      <p>
        {record.proteinAverageWeighted === 0
          ? "-"
          : record.proteinAverageWeighted || "-"}
      </p>
    )
  }
];

interface IPeriod {
  period: string;
  2023: number | string;
  2024: number | string;
}

export const compositionMonthlyDataColumns: ColumnProps[] = [
  {
    title:  "Periodo",
    key:    "period",
    render: (_: number, record: IPeriod) => (
      <p className="capitalize">
        {record.period[0] + record.period.substring(1).toLowerCase()}
      </p>
    )
  },
  {
    title:  "2023",
    key:    "2023",
    align:  "right",
    render: (_: number, record: IPeriod) => (
      <p>
        {typeof record[2023] === "number" && record[2023] !== 0
          ? record[2023].toLocaleString("en-US")
          : "-"}
      </p>
    )
  },
  {
    title:  "2024",
    key:    "2024",
    align:  "right",
    render: (_: number, record: IPeriod) => (
      <p>
        {typeof record[2024] === "number" && record[2024] !== 0
          ? record[2024].toLocaleString("en-US")
          : "-"}
      </p>
    )
  }
];
