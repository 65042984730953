import { IOption } from "@glorialabs/gl-components-module-ui";

const STORAGE_KEY = "INFO";
type LOCAL_STORAGE_KEYS = "INFO" | "idToken" | "user" | "cowfarmer" | "token";

export interface ILocalStorage {
  cowbarns: {
    groupAndPlot: IOption[];
  };
  cowfarmers: object;
  user: object;
}

export function getStorage() {
  const storageData = localStorage.getItem(STORAGE_KEY) ?? "";

  return JSON.parse(storageData) as ILocalStorage;
}

export function setStorage<K extends keyof ILocalStorage>(
  field: K,
  value: ILocalStorage[K]
) {
  localStorage.setItem(
    STORAGE_KEY,
    JSON.stringify({
      ...getStorage,
      [field]: value
    })
  );
}

export function getStorageByField<K extends keyof ILocalStorage>(field: K) {
  const storageData = localStorage.getItem(STORAGE_KEY) ?? "";

  const response = JSON.parse(storageData) as ILocalStorage;

  return response[field];
}

export function getStorage2<T>(key: LOCAL_STORAGE_KEYS = STORAGE_KEY) {
  const storageData = localStorage.getItem(key) ?? "";

  return JSON.parse(storageData) as T;
}
