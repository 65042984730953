interface IApiConfig {
  URL_MASTER_API: string;
  URL_QUALITY_API: string;
  URL_COWFARMERS_API: string;
  URL_SERVICES_API: string;
  COGNITO_OAUTH2: {
    url: string
    clientId: string,
    clientSecret: string,
    grantType: string
  }
}

const apiConfig: IApiConfig = {
  URL_MASTER_API:     import.meta.env.VITE_API_URL_MASTER || "",
  URL_QUALITY_API:    import.meta.env.VITE_API_URL_QUALITY || "",
  URL_COWFARMERS_API: import.meta.env.VITE_API_URL_COWFARMERS || "",
  URL_SERVICES_API:   import.meta.env.VITE_API_URL_SERVICES || "",
  COGNITO_OAUTH2:     {
    url:          import.meta.env.VITE_OAUTH2_COGNITO || "",
    clientId:     import.meta.env.VITE_OAUTH2_CLIENT_ID || "",
    clientSecret: import.meta.env.VITE_OAUTH2_CLIENT_SECRET || "",
    grantType:    "client_credentials"
  }
};

export default apiConfig;
