import * as Yup from "yup";
export interface IFormUser {
  name: string;
  master: string;
  first_lastname: string;
  second_lastname: string;
  email: string;
  phone_number: string;
  rol: number;
  resend: boolean;
}

export const initialValues = {
  name:            "",
  master:          "",
  first_lastname:  "",
  second_lastname: "",
  email:           "",
  phone_number:    "",
  rol:             0,
  resend:          false
};

export const validationSchemaFormUser = Yup.object({
  name:           Yup.string().required("Ingresa los nombres del nuevo usuario"),
  first_lastname: Yup.string().required("Ingresa sus apellidos"),
  email:          Yup.string()
    .email("Ingresa un correo electrónico con el formato xxx@xxx.xxx")
    .required("Debe ingresar un correo"),
  phone_number: Yup.string()
    .matches(
      /^\d+$/,
      "El número de celular solo debe contener caracteres numéricos"
    )
    .min(9, "Ingresa los nueve dígitos del número de celular")
    .required("Ingresa un número de celular"),
  rol: Yup.number().test(
    "is-required",
    "Selecciona una opción",
    function (value) {
      if (this.parent.rol === 0) {
        return value !== 0;
      }
      return true;
    }
  )
});
