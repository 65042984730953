import { Button, Text } from "@glorialabs/gl-components-module-ui";
import FileSent from "../../../../assets/Filesent.svg";
import { Dispatch, SetStateAction } from "react";

interface Props {
  setViewsConf: Dispatch<SetStateAction<string>>;
  isLoadingEditUserMutation: boolean;
}

export const EditAlertUser = ({
  setViewsConf,
  isLoadingEditUserMutation
}: Props) => {
  return (
    <div className="flex flex-col items-center">
      <Text size="2xl" bold className="text-3xl mt-3">
        Confirmar acción
      </Text>

      <div className="my-5">
        <FileSent />
      </div>

      <Text size="base" className="flex flex-col lg:flex-row text-center">
        Vas a sobreescribir la información del usuario ¿Deseas guardar?
      </Text>

      <div className="flex flex-col lg:flex-row mt-5 w-full justify-center">
        <Button
          name="delete"
          variant="secondary"
          className="w-full lg:w-[141px]"
          loading={isLoadingEditUserMutation}
          type="submit"
        >
          Guardar
        </Button>
        <div className="mx-2"></div>
        {/* <Link to={`/noticias/detalle/${id}`}> */}
        <Button
          name="regresar"
          variant="primary"
          className="w-full lg:w-[141px] mt-4 lg:mt-0"
          onClick={() => setViewsConf("formDetailUser")}
          type="button"
        >
          Regresar
        </Button>
        {/* </Link> */}
      </div>
    </div>
  );
};
