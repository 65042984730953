const allFeatures = {
  VERDATAACOPIO:      { name: "VERDATAACOPIO", value: true },
  VERDATACALIDAD:     { name: "VERDATACALIDAD", value: true },
  VERDATACOMPOSICION: { name: "VERDATACOMPOSICION", value: true },
  VERCERTIFICADOS:    { name: "VERCERTIFICADOS", value: true },
  DESCARGARDATA:      { name: "DESCARGARDATA", value: true },
  VERACTIVOS:         { name: "VERACTIVOS", value: true },
  VERPRESTAMOS:       { name: "VERPRESTAMOS", value: true },
  VERCOMPRAS:         { name: "VERCOMPRAS", value: true },
  VERNOTIFICACIONES:  { name: "VERNOTIFICACIONES", value: true },
  VERNOTICIAS:        { name: "VERNOTICIAS", value: true }
};

export const categoriesMatrixData = [
  {
    features: [
      allFeatures.VERDATAACOPIO,
      allFeatures.VERDATACALIDAD,
      allFeatures.VERDATACOMPOSICION,
      allFeatures.VERCERTIFICADOS,
      allFeatures.DESCARGARDATA,
      allFeatures.VERACTIVOS,
      allFeatures.VERPRESTAMOS,
      allFeatures.VERCOMPRAS,
      allFeatures.VERNOTIFICACIONES,
      allFeatures.VERNOTICIAS
    ],
    name:  "administrator",
    title: "Administrador"
  },
  {
    features: [
      allFeatures.VERDATAACOPIO,
      allFeatures.VERDATACALIDAD,
      allFeatures.VERDATACOMPOSICION,
      allFeatures.VERCERTIFICADOS,
      allFeatures.DESCARGARDATA,
      allFeatures.VERNOTIFICACIONES,
      allFeatures.VERNOTICIAS
    ],
    name:  "veterinary",
    title: "Veterinario"
  },
  {
    features: [
      allFeatures.VERACTIVOS,
      allFeatures.VERPRESTAMOS,
      allFeatures.VERCOMPRAS,
      allFeatures.VERNOTIFICACIONES,
      allFeatures.VERNOTICIAS
    ],
    name:  "finan",
    title: "Financiero"
  }
];

export const featuresMatrixData = [
  { name: "VERDATAACOPIO", title: "Ver data de acopio" },
  { name: "VERDATACALIDAD", title: "Ver data de calidad" },
  { name: "VERDATACOMPOSICION", title: "Ver data de composición" },
  { name: "VERCERTIFICADOS", title: "Ver certificados" },
  { name: "DESCARGARDATA", title: "Descargar data" },
  { name: "VERACTIVOS", title: "Ver activos" },
  { name: "VERPRESTAMOS", title: "Ver préstamos" },
  { name: "VERCOMPRAS", title: "Ver compras" },
  { name: "VERUSUARIOS", title: "Ver usuarios" },
  { name: "AGREGARUSUARIO", title: "Agregar usuarios" },
  { name: "MODIFICARUSUARIOS", title: "Modificar usuarios" },
  { name: "INHABILITARUSUARIOS", title: "Inhabilitar usuarios" },
  { name: "VERNOTIFICACIONES", title: "Ver notificaciones" },
  { name: "VERNOTICIAS", title: "Ver noticias" }
];
