import { useContext } from "react";
import { useQuery } from "react-query";
import moment from "moment";
import {
  getCertificates,
  getGroupsAndPlotsByCowfarmer
} from "../../../services/cowfarmers/api";
import {
  Card,
  Dropdown,
  IOption,
  Spinner,
  Text
} from "@glorialabs/gl-components-module-ui";
import { AuthContext } from "../../../context/AuthContext";
import { CertificateItem } from "./components/CertificateItem";
import { ONE_HOUR } from "../../../constant/staleTimes";

interface DiseaseStatus {
  id: number;
  title: string;
  description: string | null;
  endDate: string;
  colorIcon: string;
}

interface DiseaseStatusResponse {
  [key: string]: DiseaseStatus;
}

export default function CertificationPage() {
  const { cowfarmer, cowbarn, setCowbarn } = useContext(AuthContext);

  const { data: dataCowbarns, isLoading: isLoadingCowbarns } = useQuery(
    ["dataCowbarns"],
    () => getGroupsAndPlotsByCowfarmer(cowfarmer!.id),
    {
      onSuccess(data) {
        setCowbarn(data[0].value);
      },
      staleTime: ONE_HOUR
    }
  );

  const onSelectChange = (value: IOption | null) => {
    if (value) {
      setCowbarn(value.value as number);
    }
  };

  const { data, isLoading } = useQuery(
    ["certificates", cowbarn],
    () => getCertificates(cowbarn),
    {
      enabled:   cowbarn !== 0,
      staleTime: ONE_HOUR
    }
  );

  if (isLoadingCowbarns || isLoading) {
    return (
      <div
        className="bg-red flex justify-center align-middle items-center flex-col"
        style={{ height: "calc(100vh - 210px)" }}
      >
        <Spinner />
        <Text size="2xl" className="text-[#07196D]">
          Cargando
        </Text>
      </div>
    );
  }

  const certificatesList = data ? Object.keys(data) : [];

  const countNonGrayIcons = (data: DiseaseStatusResponse): number => {
    return Object.values(data).reduce((count, status) => {
      return status.colorIcon === "GREEN" || status.colorIcon === "AMBER"
        ? count + 1
        : count;
    }, 0);
  };

  const generateCertificateMessage = (data: DiseaseStatusResponse): string => {
    const count = countNonGrayIcons(data);
    const certificateText =
      count === 0
        ? "no tienes certificados vigentes"
        : `tienes ${count} ${count > 1 ? "certificados vigentes" : "certificado vigente"}`;
    return `${cowfarmer?.business_name}, ${certificateText}`;
  };

  return (
    <div>
      <Text size="4xl" bold={true} className="text-3xl mb-6  w-[361px]">
        Certificados
      </Text>
      {/* {user.cowbarns.length > 1 && ( */}

      {Array.isArray(dataCowbarns) && dataCowbarns.length > 1 && (
        <div className="w-full lg:w-[361px]">
          <Dropdown
            options={dataCowbarns!}
            label="Grupo y parcela"
            suggestionText="Selecciona el grupo y parcela de tu establo"
            value={dataCowbarns?.find(
              (option: IOption) => option.value === cowbarn
            )}
            onChange={(value) => onSelectChange(value as IOption | null)}
          />
        </div>
      )}

      {/* )} */}
      <div className="mt-4">
        <Card.Container className="w-full bg-[#F0EDFF] rounded-2xl px-4 py-6 mb-4">
          <Card.Header className="mb-5">
            <Text size="2xl">{data && generateCertificateMessage(data)}</Text>
          </Card.Header>
          <Card.Body>
            <>
              <p className="mb-4 leading-6">
                El beneficio de tener establos libres de TBC y Brucella tiene un
                impacto fuerte en la economía del ganadero (al tener animales
                sanos sin estas enfermedades, disminuyen los tratamientos,
                abortos y % de saca de animales enfermos), y por otro lado es
                una obligación para resguardar la salud pública por tratarse de
                enfermedades zoonóticas transmisibles a las personas.
              </p>
              <p className="leading-6">
                Decreto Supremo que aprueba el Reglamento de la Leche y
                Productos Lácteos N° 007-2017-MINAGRI. Art. 22. Indica que:{" "}
                <span className="font-bold">
                  Los hatos o animales de producción lechera, deben estar
                  declarados oficialmente libres de brucelosis y tuberculosis
                  por SENASA, o estar sometidos a control oficial y a programas
                  de erradicación.
                </span>
              </p>
            </>
          </Card.Body>
        </Card.Container>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          {data &&
            certificatesList.map((certificate) => (
              <Card.Container
                className="w-full bg-[#E4F0FF] rounded-2xl px-4 py-6"
                key={certificate}
              >
                <Card.Body className="grid grid-cols-1 lg:grid-cols-8">
                  <CertificateItem
                    indicator={data[certificate]?.colorIcon}
                    className="flex items-center lg:justify-center lg:flex-col gap-2 col-span-2 mb-5 lg:mb-0"
                  />
                  <div className="col-span-6 lg:pl-4">
                    <Text size="2xl" className="mb-2 text-[#07196D]">
                      {data[certificate].title}
                    </Text>
                    <p className="mb-2 text-[#07196D]">
                      {data[certificate].description}
                    </p>
                    <p className="mb-2 text-base font-bold text-[#07196D]">
                      Vence{" "}
                      {data[certificate].endDate.length
                        ? moment(data[certificate].endDate).format("DD/MM/YY")
                        : "-"}
                    </p>
                  </div>
                </Card.Body>
              </Card.Container>
            ))}
        </div>
      </div>
    </div>
  );
}
