import { Button, Text } from "@glorialabs/gl-components-module-ui";
import Alert from "../../../../assets/Alert.svg";

interface EmptyDownloadProps {
  onClick?: () => void;
}

export const EmptyDownload = ({ onClick }: EmptyDownloadProps) => {
  return (
    <div className="flex flex-col items-center">
      <Text size="2xl" bold className="text-3xl my-3 text-center">
        No hemos encontrado información.
      </Text>

      <Text
        size="xl"
        className="flex flex-col lg:flex-row text-center tracking-wider"
      >
        Ingresa otra combinación de data e intenta nuevamente
      </Text>

      <div className="my-5">
        <Alert />
      </div>

      <div className="flex flex-col lg:flex-row w-full justify-center items-center">
        <Button
          name="regresar"
          variant="primary"
          className="w-[135px]"
          onClick={onClick}
          type="button"
        >
          Regresar
        </Button>
        {/* </Link> */}
      </div>
    </div>
  );
};
