import { Subject } from "rxjs";

export class SubjectManager<T> {
  private subject$ = new Subject<T>();

  getValue() {
    return this.subject$.asObservable();
  }

  setValue(value: T) {
    this.subject$.next(value);
  }
}

export const navBar$ = new SubjectManager<EventoClick>();

export interface EventoClick {
  [x: string]: boolean;
}
