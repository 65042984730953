import { FunctionComponent } from "react";
import LogoGloria from "../assets/logogloria.svg";
import { Button } from "@glorialabs/gl-components-module-ui";
import { Link } from "react-router-dom";

export const PageNotFound: FunctionComponent = () => {
  return (
    <div className="h-screen w-full flex-col flex justify-center items-center">
      <Link to="/">
        <LogoGloria />
      </Link>
      <h1 className="mt-4 font-bold text-xl">Página no encontrada</h1>
      <Link to="/">
        <Button className="mt-4">Volver a Inicio</Button>
      </Link>
    </div>
  );
};
