import React, {
  FunctionComponent, useContext, useEffect
} from "react";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import {
  apiCowfarmers,
  apiMaster,
  apiQuality,
  apiServices
} from "../../services/api";

interface IAxiosErrorHandler {
  children: React.ReactNode;
}

const AxiosErrorHandler: FunctionComponent<IAxiosErrorHandler> = ({
  children
}) => {
  const { logOut } = useContext(AuthContext);

  const authUserUrl = "/user/authUser";
  const authVerifyMfa = "/user/authVerifyMfa";
  const message = "Session has expired";

  useEffect(() => {
    const responseInterceptorCowfarmer =
      apiCowfarmers.interceptors.response.use(
        (response) => response,

        async (error) => {
          if (
            error.response?.data.message === message &&
            (error.config.url !== authUserUrl || error.config.url !== authVerifyMfa)
          ) {
            logOut();
          }
          return Promise.reject(error instanceof Error ? error : new Error(error));
        }
      );

    const responseInterceptorMaster = apiMaster.interceptors.response.use(
      (response) => response,

      async (error) => {
        console.log("message status", error.response?.data.message);

        if (
          error.response?.data.message === message &&
          (error.config.url !== authUserUrl || error.config.url !== authVerifyMfa)
        ) {
          logOut();
        }
        return Promise.reject(error instanceof Error ? error : new Error(error));
      }
    );

    const responseInterceptorQuality = apiQuality.interceptors.response.use(
      (response) => response,

      async (error) => {
        if (
          error.response?.data.message === message &&
          (error.config.url !== authUserUrl || error.config.url !== authVerifyMfa)
        ) {
          logOut();
        }
        return Promise.reject(error instanceof Error ? error : new Error(error));
      }
    );

    const responseInterceptorServices = apiServices.interceptors.response.use(
      (response) => response,

      async (error) => {
        if (
          error.response?.data.message === message &&
          (error.config.url !== authUserUrl || error.config.url !== authVerifyMfa)
        ) {
          logOut();
        }
        return Promise.reject(error instanceof Error ? error : new Error(error));
      }
    );

    return () => {
      axios.interceptors.response.eject(responseInterceptorCowfarmer);
      axios.interceptors.response.eject(responseInterceptorMaster);
      axios.interceptors.response.eject(responseInterceptorQuality);
      axios.interceptors.response.eject(responseInterceptorServices);
    };
  }, [logOut]);

  return children;
};

export default AxiosErrorHandler;
