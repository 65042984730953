import {
  Card, Icon, Text
} from "@glorialabs/gl-components-module-ui";
import moment from "moment";
import { formatMilesNumber } from "../../../utils";
import { ILastMeasurementAcopio } from "../../../services/quality/interface";
import { InfoType } from "../../../configs/interfaces";

interface Props {
  readonly info: InfoType;
}

const AccumulationCard: React.FC<Props> = ({ info }) => {

  const { lot_milk_date, amount_collected } = info as ILastMeasurementAcopio;

  return (
    <Card.Container
      style={{
        display:         "flex",
        flexDirection:   "column",
        justifyContent:  "space-between",
        backgroundColor: "#F0EDFF",
        paddingTop:      20,
        paddingBottom:   20,
        borderRadius:    16,
        paddingInline:   50,
        // width: 361,
        height:          225
      }}
    >
      <Card.Header className="text-center flex flex-row justify-center">
        <Icon name="PorongoIcon" size={32}></Icon>
        <Text size="2xl" className="ml-4">
          Acopio
        </Text>
      </Card.Header>
      <Card.Body className="text-center ">
        <Text size="2xl" className="text-[#0A0036]">
          {amount_collected ? formatMilesNumber(amount_collected) : 0} kg
        </Text>
        <Text size="xs" bold className="text-[#0A0036]">
          {`(${moment(lot_milk_date).format("DD/MM/YYYY")})`}
        </Text>
      </Card.Body>
      <Card.Footer
        className="text-center"
        style={{ color: "#B60014", textDecoration: "underline" }}
      >
        {/* <Link href="/myproduction/acumulation"> */}
        <Text
          size="base"
          bold={true}
          className="cursor-pointer"
          style={{ color: "#B60014" }}
        >
          Ver más
        </Text>
        {/* </Link> */}
      </Card.Footer>
    </Card.Container>
  );
};

export default AccumulationCard;
