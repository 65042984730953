import "./login.css";
import { useContext, useState } from "react";
import { useFormik } from "formik";
import { useMutation } from "react-query";

import RedEllipse from "../../assets/images/redellipse.png";
import BlueEllipse from "../../assets/images/blueellipse.png";
import DGanaderoLogo from "../../assets/images/Desarrollo-Ganadero-logo.png";
import Macbook from "../../assets/images/macbook.png";
import Phone from "../../assets/images/phone.png";

import { ValidateMFA } from "./components/ValidateMFA/ValidateMFA";
import { Login } from "./components/Login/Login";
import { IAuthForm, initialValues } from "./form";
import {
  changeFirstPassword,
  confirmForgetPassword,
  login,
  registerAlert,
  sendEmailForgetPassword,
  verifyMyMFA
} from "../../services/master/api";
import { CompleteEmail } from "./components/forgetPassword/CompleteEmail";
import { ConfirmationForgetPassword } from "./components/forgetPassword/ConfirmationForgetPassword";
import { VerificationCode } from "./components/forgetPassword/VerificationCode";
import { RegisterAuth } from "./components/register/RegisterAuth";
import { AlreadyExistRegister } from "./components/register/AlreadyExist";
import { ConfirmationRegister } from "./components/register/Confirmation";
import { NotFoundRegister } from "./components/register/NotFound";
import {
  IVerificationCodeForm,
  initialValuesForgetPassword,
  validationSchemaForgetPassword
} from "./components/forgetPassword/form";
import * as Yup from "yup";
import { validationSchemaLogin } from "./components/Login/form";
import {
  INewPasswordForm,
  initialValuesNewPassword,
  validationSchemaNewPassword
} from "./components/NewPassword/form";
import { NewPasswordRequired } from "./components/NewPassword/NewPasswordRequired";
import {
  IValidateMFAForm,
  initialValuesValidateMFA,
  validationSchemaValidateMFA
} from "./components/ValidateMFA/form";
import { AuthContext } from "../../context/AuthContext";
import { AxiosError } from "axios";
import {
  IBodyChangePassword, IBodyConfirmForgetPassword, IBodyLogin, IBodyMFA, IBodyRegister, IBodySendEmailForgetPassword
} from "../../services/master/request.interface";
// import { FooterRedirectWeb } from "./components/FooterRedirectWeb/FooterRedirectWeb";
import classNames from "classnames";

// Definimos un tipo que represente todos los posibles valores de screen
// type ScreenType =
//   | "login"
//   | "validateMFA"
//   | "newPassword"
//   | "completeEmail"
//   | "confirmationForgetPassword"
//   | "verificationCode"
//   | "registerAuth"
//   | "alreadyExistRegister"
//   | "confirmationRegister"
//   | "notFoundRegister";

export const Auth = () => {
  const [screen, setScreen] = useState<keyof typeof authsScreens>("login");
  const { signIn } = useContext(AuthContext);
  const [originMFA, setOriginMFA] = useState<"login" | "newPassword">("login");

  const {
    mutateAsync: emailForgetPassword,
    isLoading: isLoadingSendEmailForgetPassword,
    error: errorCompleteEmail
  } = useMutation(
    async (payload: IBodySendEmailForgetPassword) => {
      return await sendEmailForgetPassword(payload);
    },
    {
      onSuccess: (data) => {
        if (data && data.$metadata.httpStatusCode === 200) {
          setScreen("verificationCode");
        }
      }
    }
  );

  const {
    mutateAsync: verificationForgetPassword,
    isLoading: isLoadingVerificationForgetPassword,
    error: errorForgetPassword
  } = useMutation(
    async (payload: IBodyConfirmForgetPassword) => {
      return await confirmForgetPassword(payload);
    },
    {
      onSuccess: (data) => {
        if (data) {
          setScreen("confirmationForgetPassword");
        }
      }
    }
  );

  const {
    mutateAsync: LoginAuth,
    isLoading: isLoadingLogin,
    data: responseLogin,
    error: errorLogin
  } = useMutation(
    async (payload: IBodyLogin) => {
      return await login(payload);
    },
    {
      onSuccess: (data) => {
        if (data) {
          if (data.ChallengeName === "SMS_MFA") {
            setScreen("validateMFA");
          } else if (data.ChallengeName === "NEW_PASSWORD_REQUIRED") {
            setScreen("newPassword");
          }
        }
      }
    }
  );

  const {
    mutateAsync: register,
    isLoading: isLoadingRegister,
    data: responseRegister,
    error: errorRegister
  } = useMutation(
    async (payload: IBodyRegister) => {
      return await registerAlert(payload);
    },
    {
      onSuccess: (data) => {
        if (data) {
          setScreen("confirmationRegister");
        }
      },
      onError: (error: AxiosError<{ statusCode: number; message: string }>) => {
        const response = error.response?.data;
        console.log("error", response);

        if (response?.statusCode === 404) {
          setScreen("notFoundRegister");
        } else if (response?.statusCode === 409) {
          setScreen("alreadyExistRegister");
        }
      }
    }
  );

  const {
    mutateAsync: verifyMFA,
    isLoading: isLoadingMFA,
    error: errorMFA
  } = useMutation(
    async (payload: IBodyMFA) => {
      return await verifyMyMFA(payload);
    },
    {
      onSuccess: (data) => {
        if (data) {
          signIn(
            data.AuthenticationResult.IdToken,
            data.cowfarmer,
            data.user,
            data.rolesAndProfiles.rol,
            data.AuthenticationResult
          );
        }
      }
    }
  );

  const {
    mutateAsync: changeFirstPass,
    isLoading: isLoadingChangePass,
    error: errorNewPassword,
    data: responseFirstPass
  } = useMutation(
    async (payload: IBodyChangePassword) => {
      return await changeFirstPassword(payload);
    },
    {
      onSuccess: (data) => {
        if (data) {
          setScreen("validateMFA");
        }
      }
    }
  );

  const formikCompleteEmail = useFormik<{ email: string }>({
    initialValues: {
      email: ""
    },
    onSubmit: (values) => {
      emailForgetPassword(values);
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Ingresa un correo válido")
        .required("No has ingresado ningún correo")
    })
  });

  const formikForgetPassword = useFormik<IVerificationCodeForm>({
    initialValues: initialValuesForgetPassword,
    onSubmit:      (values) => {
      verificationForgetPassword({
        email:    formikCompleteEmail.values.email,
        password: values.newPassword,
        code:     values.verificationCodePassword
      });
    },
    validationSchema: validationSchemaForgetPassword
  });

  const formikDniOrRuc = useFormik<{ rucOrDni: string }>({
    initialValues: {
      rucOrDni: ""
    },
    onSubmit: (values) => {
      register(values);
    },
    validationSchema: Yup.object({
      rucOrDni: Yup.number()
        .typeError("El RUC o DNI deben ser números")
        .required("Debe completar este campo")
    })
  });

  const formik = useFormik<IAuthForm>({
    initialValues,
    validationSchema: validationSchemaLogin,
    onSubmit:         (values) => {
      LoginAuth({ username: values.email, password: values.password });
    }
  });

  const formikNewPassword = useFormik<INewPasswordForm>({
    initialValues:    initialValuesNewPassword,
    validationSchema: validationSchemaNewPassword,
    onSubmit:         (values) => {
      changeFirstPass({
        username: formik.values.email,
        session:  responseLogin!.Session,
        password: values.newPassword
      });
    }
  });

  const formikValidateMFA = useFormik<IValidateMFAForm>({
    initialValues:    initialValuesValidateMFA,
    validationSchema: validationSchemaValidateMFA,
    onSubmit:         (values) => {

      let session: string | undefined;

      if (responseLogin?.ChallengeName === "SMS_MFA" && responseLogin.Session) {
        session = responseLogin.Session;
      } else if (responseFirstPass?.Session) {
        session = responseFirstPass.Session;
      }

      if (session) {
        verifyMFA({
          username: formik.values.email,
          session,
          code:     values.codeMFA
        });
      }
    }
  });

  const resendCodeLogin = async () => {
    await LoginAuth({
      username: formik.values.email,
      password: formik.values.password
    });
  };

  const resendCodeChangePass = async () => {
    await LoginAuth({
      username: formik.values.email,
      password: formikNewPassword.values.newPassword
    });
  };

  const authsScreens = {
    login: (
      <Login
        formik={formik}
        isLoadingLogin={isLoadingLogin}
        responseLogin={responseLogin}
        setScreen={setScreen}
        error={errorLogin}
        setOriginMFA={setOriginMFA}
      />
    ),
    validateMFA: (
      <ValidateMFA
        formik={formikValidateMFA}
        responseLogin={responseLogin}
        responseFirstPass={responseFirstPass}
        isLoadingMFA={isLoadingMFA}
        error={errorMFA}
        originMFA={originMFA}
        resendLogin={resendCodeLogin}
        resendChangePass={resendCodeChangePass}
        isLoadingResend={isLoadingLogin || isLoadingChangePass}
      />
    ),
    newPassword: (
      <NewPasswordRequired
        formik={formikNewPassword}
        isLoadingChange={isLoadingChangePass}
        error={errorNewPassword}
        setOriginMFA={setOriginMFA}
      />
    ),
    completeEmail: (
      <CompleteEmail
        formik={formikCompleteEmail}
        setScreen={setScreen}
        isLoadingChange={isLoadingSendEmailForgetPassword}
        error={errorCompleteEmail}
      />
    ),
    confirmationForgetPassword: (
      <ConfirmationForgetPassword
        formik={formikForgetPassword}
        formikCompleteEmail={formikCompleteEmail}
        isLoadingChange={isLoadingChangePass}
        setScreen={setScreen}
      />
    ),
    verificationCode: (
      <VerificationCode
        formik={formikForgetPassword}
        isLoadingChange={isLoadingVerificationForgetPassword}
        setScreen={setScreen}
        error={errorForgetPassword}
        email={formikCompleteEmail.values.email}
        refreshEmail={emailForgetPassword}
        loadingEmail={isLoadingSendEmailForgetPassword}
      />
    ),
    registerAuth: (
      <RegisterAuth
        formik={formikDniOrRuc}
        isLoadingChange={isLoadingRegister}
        setScreen={setScreen}
      />
    ),
    alreadyExistRegister: (
      <AlreadyExistRegister formik={formikDniOrRuc} setScreen={setScreen} />
    ),
    confirmationRegister: (
      <ConfirmationRegister
        formik={formikDniOrRuc}
        setScreen={setScreen}
        responseRegister={responseRegister!}
      />
    ),
    notFoundRegister: (
      <NotFoundRegister
        formik={formikDniOrRuc}
        setScreen={setScreen}
        error={errorRegister}
      />
    )
  };

  return (
    <div className="relative h-screen flex justify-center align-middle items-center flex-col lg:flex-row">
      <div className="lg:w-1/2 flex flex-col items-center w-full h-full px-4">

        <div className={classNames("block relative",
          { "pt-8 mb-4": screen==="login", "lg:w-full py-6 lg:py-2 pl-6": screen !== "login" })}>
          <img src={DGanaderoLogo} width={87} alt="" />
        </div>

        <div className="pb-6 w-full flex flex-col items-center lg:h-full justify-center ">
          {authsScreens[screen]}
        </div>

        {/* {screen==="login" && (
          <FooterRedirectWeb/>
        )} */}

      </div>

      <div className="hidden w-1/2 lg:block h-full">
        <div id="bg_rightside" className="w-full h-full py-8 relative">
          <div className="absolute left-12 top-[24%] blur-2xl">
            <img width={150} height={150} src={RedEllipse} alt="laptop" />
          </div>

          <div className="absolute bottom-[20%] right-14 blur-3xl">
            <img width={200} height={150} src={BlueEllipse} alt="laptop" />
          </div>
          <div className="bg-opacity-30 bg-[#F0EDFF] z-10 w-full h-full rounded-xl flex flex-col
            backdrop-blur-3xl p-24 gap-12">
            <p className="text-2xl font-bold text-center leading-9">Consulta y descarga la calidad y composición
              de leche acopiada, realiza el seguimiento de tus préstamos, compras y ¡mucho más!
              con tu nuevo Portal Ganadero</p>
            <div className="flex justify-center relative">
              <img src={Macbook} alt="" width={"85%"} height={"auto"} />
              <img src={Phone} alt="" width={"24%"} className="absolute top-[45%] right-[6%]" />
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
