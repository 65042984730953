import { FormikProps } from "formik";
import {
  Button, Input, Text
} from "@glorialabs/gl-components-module-ui";
import {
  Dispatch, FunctionComponent, SetStateAction
} from "react";

import axios from "axios";

interface ICompleteEmail {
  formik: FormikProps<{ email: string }>;
  isLoadingChange: boolean;
  setScreen: Dispatch<
    SetStateAction<
      | "login"
      | "validateMFA"
      | "newPassword"
      | "completeEmail"
      | "confirmationForgetPassword"
      | "verificationCode"
      | "registerAuth"
      | "alreadyExistRegister"
      | "confirmationRegister"
      | "notFoundRegister"
    >
  >;
  error: unknown;
}

export const CompleteEmail: FunctionComponent<ICompleteEmail> = ({
  formik,
  setScreen,
  isLoadingChange,
  error
}) => {
  const { errors, handleSubmit } = formik;

  return (
    <>
      <p className="mb-8 text-2xl	color-[#000000] tracking-wider">
        Recupera tu contraseña
      </p>

      <form onSubmit={handleSubmit}>
        <Input
          {...formik.getFieldProps("email")}
          label="Correo electrónico"
          className="w-full lg:w-[361px] h-fit"
          suggestionText="Ingresa el correo electrónico con el que inicias sesión"
          type="email"
          maxLength={45}
          variant={
            axios.isAxiosError(error) || errors.email ? "error" : undefined
          }
          feedback={
            axios.isAxiosError(error)
              ? error.response?.data.message
              : errors.email ?? undefined
          }
        />

        <Button
          name="Iniciar Sesión"
          variant="primary"
          className="w-[361px] rounded-2xl mt-8"
          type="submit"
          loading={isLoadingChange}
        >
          Siguiente
        </Button>
      </form>
      <Text
        isButton
        className="rounded-2xl mt-4 align-middle"
        onClick={() => {
          formik.resetForm();
          setScreen("login");
        }}
      >
        Regresar
      </Text>
    </>
  );
};
