import {
  useContext, useEffect, useMemo, useState
} from "react";
import {
  Button,
  ComparisonMatrix,
  Spinner,
  Text
} from "@glorialabs/gl-components-module-ui";
import {
  Link, useNavigate, useParams
} from "react-router-dom";
import { useFormik } from "formik";
// import { useCallbackPrompt } from "../../../hooks/useCallbackPrompt";
import { AuthContext } from "../../../context/AuthContext";
import { useMutation, useQuery } from "react-query";
import {
  disableUser,
  getListUserByMaster,
  getUserById,
  getUserProfile,
  updateUser
} from "../../../services/master/api";
import {
  IFormUser, initialValues, validationSchemaFormUser
} from "../form";
// import { hasChangesEdit } from "./utils/hanChangesEdit.utils";
// import { ConfirmAction } from "../../../components/ConfirmAction/ConfirmAction";
import { DetailUserForm } from "./components/DetailUserForm";
import { EditAlertUser } from "./components/EditAlert";
import {
  categoriesMatrixData,
  featuresMatrixData
} from "../constants/comparison-matrix-data.const";
import { paths } from "../../../routes/paths";
import { DisableUser } from "./components/DisableUserAlert";
import { EnableUser } from "./components/EnableUserAlert";
import { IBodyDisableUser, IBodyUpdateUser } from "../../../services/master/request.interface";
import { countActives } from "../utils/countActives";
import { TWO_HOURS } from "../../../constant/staleTimes";
import { queryClient } from "../../../configs/queryClient.config";

export const DetailUserPage = () => {
  const { id, action } = useParams();
  const navigate = useNavigate();

  // const [showDialog, setShowDialog] = useState<boolean>(false);
  // const [showPrompt, confirmNavigation, cancelNavigation] =
  // useCallbackPrompt(showDialog);
  const [edit, setEdit] = useState(action === "ver");

  const { user } = useContext(AuthContext);

  const [viewsConf, setViewsConf] = useState<string>("formDetailUser");

  const { data: dataUserProfile } = useQuery(["getUserProfile"], () =>
    getUserProfile(),
  {
    staleTime: TWO_HOURS
  }
  );

  const { data: dataUserById, isFetching: isLoading } = useQuery(
    "getUserById",
    () => getUserById({ id, master_email: user?.origin_master_email ?? "" }),
    {
      enabled: Boolean(id)
    }
  );

  const { data: listUserByMaster } = useQuery(["getListUserByMaster", 0], () =>
    getListUserByMaster(user?.origin_master_email ?? "", 6, 0)
  );

  const {
    mutateAsync: editUserMutation,
    isLoading: isLoadingEditUserMutation
  } = useMutation(
    async (payload: IBodyUpdateUser) => {
      return await updateUser(payload);
    },
    {
      onSuccess: (data) => {
        if (data) {
          queryClient.invalidateQueries({ queryKey: ["getListUserByMaster"] });
          navigate("/usuarios");
        }
      }
    }
  );

  const formikEditUser = useFormik<IFormUser>({
    initialValues: initialValues,
    onSubmit:      (values) => {
      // setShowDialog(false);
      editUserMutation({
        email:           values.email,
        first_lastname:  values.first_lastname,
        master:          values.master,
        name:            values.name,
        phone_number:    `+51${values.phone_number}`,
        second_lastname: values.second_lastname
      });
    },
    validationSchema: validationSchemaFormUser
  });

  const { handleSubmit, setValues, values } = formikEditUser;

  const isFormIncomplete =
    values.name === "" ||
    values.first_lastname === "" ||
    values.email === "" ||
    values.phone_number === "" ||
    values.rol === 0;

  useEffect(() => {
    if (id === undefined) {
      return;
    }
    dataUserById &&
      setValues({
        email:           dataUserById.email_account,
        first_lastname:  dataUserById.lastname,
        name:            dataUserById.firstname,
        phone_number:    dataUserById.phone.substring(3),
        master:          user?.origin_master_email ?? "",
        rol:             dataUserById.profile.id,
        second_lastname: "",
        resend:          false
      });
  }, [id, user?.origin_master_email, dataUserById, setValues]);

  const optionsUserProfile = useMemo(() => {
    return dataUserProfile?.data.map((item) => ({
      label: item.description,
      value: item.id
    }));
  }, [dataUserProfile]);

  const {
    mutateAsync: toggleActiveUserMutation,
    isLoading: isLoadingToggleUserActiveMutation
  } = useMutation(
    async (payload: IBodyDisableUser) => {
      return await disableUser(payload);
    },
    {
      onSuccess: (data) => {
        if (data) {
          return navigate("/usuarios");
        }
      }
    }
  );

  useEffect(() => {
    if (dataUserById) {
      // const hasChanges = hasChangesEdit(dataUserById, values);
      // setShowDialog(hasChanges);
    }
  }, [values, dataUserById]);

  if (isLoading) {
    return (
      <div
        className="bg-red flex justify-center align-middle items-center flex-col"
        style={{ height: "calc(100vh - 210px)" }}
      >
        <Spinner />
        <Text size="2xl" className="text-[#07196D]">
          Cargando
        </Text>
      </div>
    );
  }

  // if (showPrompt) {
  //   return (
  //     <ConfirmAction
  //       textDescription={
  //         "Si continúas, perderás la información ingresada ¿Deseas continuar?"
  //       }
  //       textConfirm={"Continuar"}
  //       onConfirm={confirmNavigation}
  //       onCancel={cancelNavigation}
  //       isLoadingOk={isLoadingEditUserMutation}
  //     />
  //   );
  // }

  const renderFormContent = () => {
    if (viewsConf === "editAlert") {
      return (
        <EditAlertUser
          setViewsConf={setViewsConf}
          isLoadingEditUserMutation={isLoadingEditUserMutation}
        />
      );
    }

    if (viewsConf === "disableUser") {
      return (
        <DisableUser
          setViewsConf={setViewsConf}
          isLoadingToggleUserActiveMutation={isLoadingToggleUserActiveMutation}
          toggleActiveUserMutation={toggleActiveUserMutation}
          email={values.email}
        />
      );
    }

    if (viewsConf === "enableUser") {
      return (
        <EnableUser
          setViewsConf={setViewsConf}
          isLoadingToggleUserActiveMutation={isLoadingToggleUserActiveMutation}
          toggleActiveUserMutation={toggleActiveUserMutation}
          email={values.email}
        />
      );
    }

    return (
      <>
        <div className="flex justify-between items-center">
          <Text size="4xl" bold={true} className="text-3xl">
            Usuarios
          </Text>
          {edit ? (
            <div className="hidden lg:block">
              <div className="flex justify-end">
                {dataUserById?.is_active ? (
                  <Button
                    variant="secondary"
                    type="button"
                    className="w-[133px]"
                    onClick={() => setViewsConf("disableUser")}
                  >
                    Inhabilitar
                  </Button>
                ) : (
                  <Button
                    variant="secondary"
                    type="button"
                    className="w-[133px]"
                    disabled={countActives(listUserByMaster!) >= 5}
                    onClick={() => setViewsConf("enableUser")}
                  >
                    Habilitar
                  </Button>
                )}
                <div className="mx-4"></div>
                <Link to="/usuarios">
                  <Button variant="primary" outline={true} type="button">
                    Regresar
                  </Button>
                </Link>
                <div className="mx-4"></div>
                <Button
                  variant="primary"
                  onClick={() => setEdit(false)}
                  type="button"
                >
                  Editar
                </Button>
              </div>
              {!dataUserById?.is_active && countActives(listUserByMaster!) >= 5 && (
                <p className="text-sm text-[#B60014] font-bold pt-3 text-center lg:text-left">
                  Llegaste al límite máximo de usuarios activos (5),
                  Inhabilita algún usuario para poder habilitar otro
                </p>
              )}
            </div>
          ) : (
            <div className="hidden lg:flex">
              <Link to={paths.users}>
                <Button
                  variant="primary"
                  outline={true}
                  type="button"
                  className="w-full"
                >
                  Regresar
                </Button>
              </Link>
              <div className="mx-4"></div>
              <Button
                variant="primary"
                type="button"
                disabled={isFormIncomplete}
                onClick={() => setViewsConf("editAlert")}
              >
                Guardar
              </Button>
            </div>
          )}
        </div>
        <DetailUserForm
          formikEditUser={formikEditUser}
          optionsUserProfile={optionsUserProfile!}
          edit={edit}
          dataUserById={dataUserById}
        />
        <div className="pt-9">
          <ComparisonMatrix
            categories={categoriesMatrixData}
            description="Mira aquí a qué funcionalidades tiene acceso cada tipo de usuario"
            features={featuresMatrixData}
            title="Matriz de accesos"
          />
        </div>
        <div className="block lg:hidden pt-9">
          {edit ? (
            <>
              <Link to="/usuarios">
                <Button
                  variant="primary"
                  outline={true}
                  className="w-full"
                  type="button"
                >
                  Regresar
                </Button>
              </Link>
              <div className="mx-4"></div>
              <Button
                variant="primary"
                className="w-full mt-4"
                onClick={() => setEdit(false)}
                type="button"
              >
                Editar
              </Button>
              <div className="mx-4"></div>
              {dataUserById?.is_active ? (
                <Button
                  variant="secondary"
                  type="button"
                  className="w-full mt-4"
                  onClick={() => setViewsConf("disableUser")}
                >
                  Inhabilitar
                </Button>
              ) : (
                <Button
                  variant="secondary"
                  type="button"
                  className="w-full mt-4"
                  disabled={countActives(listUserByMaster!) >= 5}
                  onClick={() => setViewsConf("enableUser")}
                >
                  Habilitar
                </Button>
              )}
              {!dataUserById?.is_active && countActives(listUserByMaster!) >= 5 && (
                <p className="text-sm text-[#B60014] font-bold pt-3 text-center lg:text-left">
                  Llegaste al límite máximo de usuarios activos (5),
                  Inhabilita algún usuario para poder habilitar otro
                </p>
              )}
            </>
          ) : (
            <>
              <Link to={paths.users}>
                <Button
                  variant="primary"
                  outline={true}
                  type="button"
                  className="w-full"
                >
                  Regresar
                </Button>
              </Link>
              <div className="mx-4"></div>
              <Button
                variant="primary"
                type="button"
                className="w-full mt-4"
                disabled={isFormIncomplete}
                onClick={() => setViewsConf("editAlert")}
              >
                Guardar
              </Button>
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {renderFormContent()}
      </form>
    </div>
  );
};
