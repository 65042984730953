import React, {
  useContext, useEffect, useMemo, useState
} from "react";
import {
  Button, Text, ComparisonMatrix
} from "@glorialabs/gl-components-module-ui";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { AuthContext } from "../../../context/AuthContext";
// import { useCallbackPrompt } from "../../../hooks/useCallbackPrompt";
import { useMutation, useQuery } from "react-query";
import { addUser, getUserProfile } from "../../../services/master/api";
import {
  IFormUser, initialValues, validationSchemaFormUser
} from "../form";
// import { ConfirmAction } from "../../../components/ConfirmAction/ConfirmAction";

import {
  categoriesMatrixData,
  featuresMatrixData
} from "../constants/comparison-matrix-data.const";
import { ConfirmationAlert } from "./components/ConfirmationAlert";
import AddUserForm from "./components/AddUserForm";
import { TWO_HOURS } from "../../../constant/staleTimes";
import { queryClient } from "../../../configs/queryClient.config";

const AddUserPage: React.FC = () => {
  const { user } = useContext(AuthContext);
  // const [showDialog, setShowDialog] = useState<boolean>(false);
  // const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);
  const [alertView, setAlertView] = useState<string>("formAddUser");

  const { data: dataUserProfile } = useQuery(["getUserProfile"], () => getUserProfile(), {
    staleTime: TWO_HOURS
  });

  const optionsUserProfile = useMemo(() => {
    return dataUserProfile?.data.map((item) => ({
      label: item.description,
      value: item.id
    }));
  }, [dataUserProfile]);

  const {
    mutateAsync: addUserMutation,
    isLoading: isLoadingAddUserMutation,
    data: responseAddUserMutation,
    error
  } = useMutation(
    async (payload: IFormUser) => {
      return await addUser(payload);
    },
    {
      onSuccess: (data) => {
        if (data) {
          queryClient.invalidateQueries({ queryKey: ["getListUserByMaster"] });
          return setAlertView("corfirmationAddUser");
        }
      }
    }
  );

  const formikAddUser = useFormik<IFormUser>({
    initialValues: initialValues,
    onSubmit:      (values) => {
      addUserMutation({
        name:            values.name,
        email:           values.email,
        first_lastname:  values.first_lastname,
        master:          user?.origin_master_email ?? "",
        phone_number:    `+51${values.phone_number}`,
        rol:             values.rol,
        second_lastname: values.second_lastname,
        resend:          values.resend
      });
    },
    validationSchema: validationSchemaFormUser
  });

  const { handleSubmit, values } = formikAddUser;

  const isFormIncomplete =
    values.name === "" ||
    values.first_lastname === "" ||
    values.email === "" ||
    values.phone_number === "" ||
    values.rol === 0;

  useEffect(() => {
    // const hasChanges = JSON.stringify(values) !== JSON.stringify(initialValues);
    // setShowDialog(hasChanges);
  }, [values]);

  // if (showPrompt) {
  //   return (
  //     <ConfirmAction
  //       textDescription={"Si continúas, perderás la información ingresada ¿Deseas continuar?"}
  //       textConfirm={"Continuar"}
  //       onConfirm={confirmNavigation}
  //       onCancel={cancelNavigation}
  //       isLoadingOk={isLoadingAddUserMutation}
  //     />
  //   );
  // }

  return (
    <div>
      {alertView === "corfirmationAddUser" ? (
        <ConfirmationAlert formikResponse={responseAddUserMutation} />
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <div className="flex justify-between items-center">
              <Text size="4xl" bold={true} className="text-3xl">Usuarios</Text>
              <div className="hidden lg:flex">
                <Link to="/usuarios">
                  <Button variant="primary" outline={true} type="button">Regresar</Button>
                </Link>
                <div className="mx-4"></div>
                <Button
                  variant="primary"
                  disabled={isFormIncomplete}
                  type="submit"
                  loading={isLoadingAddUserMutation}
                >
                  Guardar
                </Button>
              </div>
            </div>
            <AddUserForm
              formikAddUser={formikAddUser}
              optionsUserProfile={optionsUserProfile!}
              isFormIncomplete={isFormIncomplete}
              isLoadingAddUserMutation={isLoadingAddUserMutation}
              error={error}
            />
          </form>
          <div className="pt-9">
            <ComparisonMatrix
              categories={categoriesMatrixData}
              description="Mira aquí a qué funcionalidades tiene acceso cada tipo de usuario"
              features={featuresMatrixData}
              title="Matriz de accesos"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default AddUserPage;
