import {
  ColumnProps,
  Text,
  Pagination,
  Table
} from "@glorialabs/gl-components-module-ui";
import {
  AdditionalInfo,
  DetailInfo,
  GeneralInfo,
  ILoanInfo,
  IPurchaseInfo,
  PurchaseDetail
} from "../../../services/services/interface";
import { Fragment, useState } from "react";
import { getColorByStatus } from "../utils/getColorByStatus";
import { formatMilesNumber } from "../../../utils";
import moment from "moment";
import { getInstallmentStatus } from "../utils/getInstallmentStatus";

interface Props {
  generalInfo: GeneralInfo;
  detailInfo: DetailInfo;
  tableInfo: (Partial<IPurchaseInfo> & Partial<ILoanInfo>)[];
  purchaseInfo?: PurchaseDetail[];
  divider?: boolean;
  columnsTable: ColumnProps[];
}

const CardItem = ({
  generalInfo,
  detailInfo,
  tableInfo,
  divider = false,
  purchaseInfo = [],
  columnsTable
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const changePage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(tableInfo?.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedData = tableInfo.slice(startIndex, endIndex);

  const renderInstallmentInfo = (
    res: Partial<IPurchaseInfo> & Partial<ILoanInfo>,
    isPurchase: boolean
  ) => {
    const colorStatus = getColorByStatus(
      res.installment_due_date!,
      res.status!
    );
    return (
      <div className="mt-4 px-4" key={res.id}>
        {
          isPurchase ? (
            <>
              <div className="flex">
                <div className="flex-1">
                  <div className="flex">
                    <p className="font-bold mr-2">Cuota:</p>
                    <p>
                      {res.purchase_installments_number} de {tableInfo.length}
                    </p>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="flex">
                    <p className="font-bold mr-2">Importe:</p>
                    <p>
                      S/{" "}
                      {
                        formatMilesNumber(res.purchase_installments_amount!)
                      }
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex">
                <div className="flex-1">
                  <div className="flex">
                    <p className="font-bold mr-2">Vence:</p>
                    <p>{moment(res.installment_due_date).format("DD/MM/YYYY")}</p>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="flex">
                    <p className="font-bold mr-2">Estado:</p>
                    <p style={{ color: colorStatus }}>
                      {getInstallmentStatus(res.installment_due_date!, res.status!)}
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex">
                <div className="flex-1">
                  <div className="flex text-xs sm:text-sm">
                    <p className="font-bold mr-2">Proxima cuota:</p>
                    <p>
                      {res.loan_installments_number} de {res.total_installment}
                    </p>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="flex text-xs sm:text-sm">
                    <p className="font-bold mr-2">Capital:</p>
                    <p>
                      S/{" "}
                      {formatMilesNumber(res.loan_installments_amount!)}
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex">
                <div className="flex-1">
                  <div className="flex text-xs sm:text-sm">
                    <p className="font-bold mr-2">Vence:</p>
                    <p>{moment(res.installment_due_date).format("DD/MM/YYYY")}</p>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="flex text-xs sm:text-sm">
                    <p className="font-bold mr-2">Interés:</p>
                    <p className={`${res.loan_installments_interest === 0 && "text-[#EF863D]"}`}>
                      {
                        res.loan_installments_interest === 0 ?
                          "Por calcular" :
                          `S/ ${formatMilesNumber(parseFloat( res.loan_installments_interest!.toFixed(2)))}`
                      }
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex">
                <div className="flex-1">
                  <div className="flex text-xs sm:text-sm">
                    <p className="font-bold mr-2">Estado:</p>
                    <p style={{ color: colorStatus }}>
                      {getInstallmentStatus(res.installment_due_date!, res.status!)}
                    </p>
                  </div>
                </div>
              </div>
            </>
          )
        }
      </div>
    );

  };

  return (
    <div
      style={{
        backgroundColor: "#F0EDFF",
        paddingInline:   14,
        paddingBlock:    16,
        borderRadius:    16,
        marginBottom:    12
      }}
    >
      <button
        className="flex lg:flex-row flex-col justify-between"
        onClick={() => setOpen(!open)}
        style={{
          background: "none",
          border:     "none",
          padding:    0,
          width:      "100%"
        }}
      >
        <div className="flex flex-col items-center justify-center flex-1 mr-0 md:mr-1 w-full">
          <Text size="base" bold={true} className="tracking-wide">
            {generalInfo.title}
          </Text>

          <Text size="2xl" bold={false} className="tracking-widest my-1">
            {generalInfo.totalAmount}
          </Text>

          <Text size="xs" bold={true} className="tracking-wide">
            Vence: {generalInfo.expiration}
          </Text>
        </div>
        <div className="flex flex-[5_5_0%] flex-col lg:my-0 my-4 items-center w-full">
          <div className="flex lg:flex-row flex-col justify-center w-full">
            <div
              className={`lg:flex lg:flex-row grid grid-cols-2 ${
                divider ? "grid-rows-1 justify-start" : "grid-rows-2 justify-evenly"
              } 
              gap-y-2 ${divider === false && "flex-1"} w-full`}
            >
              {detailInfo.columns.map((res, index) => (
                <Fragment key={index}>
                  {divider &&
                    index === Math.floor(detailInfo.columns.length / 2) && (
                    <div className="hidden border border-[#60606C] h-full mr-6 lg:block" />
                  )}

                  <div
                    className={`mr-0 ${
                      res.name === "Interés Pendiente" || res.name === "Cuotas"
                        ? "lg:mr-0"
                        : "lg:mr-6"
                    } flex flex-col items-center lg:block lg:items-start ${
                      divider === false && index === 0
                        ? "sm:flex-[2_2_0%] xl:flex-[2.5_2.5_0%]"
                        : "flex-1"
                    } ${divider && "flex-[0.25_0.25_0%]"}`}
                  >
                    <Text
                      size="base"
                      bold={true}
                      className="tracking-wide text-left"
                    >
                      {res.name}
                    </Text>
                    <Text
                      size="sm"
                      bold={false}
                      className="tracking-wide mt-4 text-left"
                    >
                      {res.detail}
                    </Text>
                  </div>
                </Fragment>
              ))}
            </div>
          </div>
          <div className="flex flex-row mt-3 justify-evenly lg:justify-start w-full">
            {detailInfo.additionalInfo.map(
              (res: AdditionalInfo, index: number) => {
                return (
                  <div
                    key={index}
                    className={`flex flex-col lg:flex-row ${res.name === "Moneda:" ? "mr-0" : "mr-6"}`}
                  >
                    <Text
                      size="xs"
                      bold={true}
                      className="tracking-wide flex flex-col lg:flex-row"
                    >
                      {res.name}
                    </Text>
                    <Text
                      size="xs"
                      bold={res.bold !== undefined ? res.bold : true}
                      className="tracking-wide ml-0 lg:ml-1"
                    >
                      {res.detail}
                    </Text>
                  </div>
                );
              }
            )}
          </div>
        </div>
        <div
          className={
            "flex flex-1 w-full justify-center align-middle items-center sm:flex lg:hidden xl:flex"
          }
        >
          <button
            type="button"
            className={`${open ? "px-3" : "px-6"} py-4 border-2 border-solid 
                border-[#07196D] rounded-2xl text-[#07196D] lg:w-fit w-full font-semibold`}
            onClick={() => setOpen(!open)}
          >
            {open ? "Ocultar Detalle" : "Ver detalle"}
          </button>
        </div>
      </button>

      {open && (
        <div>
          <div className="border border-solid border-[#E1E1E1] mt-4"></div>

          {purchaseInfo?.length > 1 ? (
            <div className="flex px-3 flex-col lg:flex-row">
              <div className="flex-1 pl-6 pt-4 border-0 lg:border-r-2">
                <p className="font-bold text-base">Detalle venta</p>
                <ul className="list-disc pl-4 pt-4">
                  {purchaseInfo?.map((res, index: number) => {
                    const formattedQuantity =
                      res.quantity < 10 ? `0${res.quantity}` : res.quantity;
                    return (
                      <li
                        key={index}
                        className={`text-sm ${purchaseInfo.length === index ? "mb-0" : "mb-2"}`}
                      >
                        {`${formattedQuantity} ${res.product.description}`}
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="flex-[3_3_0%] block lg:hidden">
                {displayedData.map((res) =>
                  renderInstallmentInfo(res, !divider)
                )}
                {tableInfo.length > 10 && (
                  <div className={"flex justify-center"}>
                    <Pagination
                      totalPages={totalPages}
                      className="mt-4"
                      onChange={changePage}
                    />
                  </div>
                )}
              </div>
              <div className="flex-[3_3_0%] hidden lg:block">
                <Table
                  rowKey="id"
                  title={""}
                  className="w-4/5"
                  key={"loan"}
                  expandableColumns={undefined}
                  keyExpandableData={undefined}
                  columns={columnsTable}
                  data={tableInfo}
                  pagination={
                    tableInfo.length > 10 ? { position: "start" } : undefined
                  }
                  itemsPerPage={10}
                  backgroundColor="#F0EDFF"
                />
              </div>
            </div>
          ) : (
            <>
              <div className="flex-[3_3_0%] block lg:hidden">
                {displayedData.map((res) =>
                  renderInstallmentInfo(res, !divider)
                )}
                {tableInfo.length > 10 && (
                  <div className={"flex justify-center"}>
                    <Pagination
                      totalPages={totalPages}
                      className="mt-4"
                      onChange={changePage}
                    />
                  </div>
                )}
              </div>
              <div className="flex-[3_3_0%] hidden lg:block">
                <Table
                  rowKey={"id"}
                  title={""}
                  className="w-4/5"
                  key={"loan"}
                  expandableColumns={undefined}
                  keyExpandableData={undefined}
                  columns={columnsTable}
                  data={tableInfo}
                  pagination={
                    tableInfo.length > 10 ? { position: "start" } : undefined
                  }
                  itemsPerPage={10}
                  backgroundColor="#F0EDFF"
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default CardItem;
