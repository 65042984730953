import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Navbar } from "../navbar/Navbar";
import { AuthContext } from "../../context/AuthContext";
import { Footer } from "../Footer/Footer";
import { paths } from "../../routes/paths";

export default function Layout({ children }: { readonly children: React.ReactNode }) {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const termsAndConditionsOk = Boolean(user?.condition_1 && user.condition_2);
  const location = useLocation();

  const routesWithoutNavbar = [
    paths.termsAndConditions,
    paths.viewTermsAndConditions
  ];

  const routesWithoutFooter = [
    paths.termsAndConditions,
    paths.viewTermsAndConditions
  ];

  const { pathname } = location;

  const isWithNavbar = !routesWithoutNavbar.includes(pathname);

  const isWithFooter = !routesWithoutFooter.includes(pathname);

  useEffect(() => {
    if (
      !termsAndConditionsOk &&
      (pathname !== "/terminos-y-condiciones-de-uso")
    ) {
      return navigate("/terminos-y-condiciones-de-uso");
    }
  }, [navigate, pathname, termsAndConditionsOk]);
  return (
    <>
      {isWithNavbar && <Navbar />}
      {/* 240 */}
      <div
        className={`${isWithNavbar ? "mt-40" : ""} ${isWithFooter ? "pb-16" : "pb-0"}`}
        style={{ minHeight: "calc(100vh - 145px)" }}
      >
        <div className="container mx-auto px-4 lg:px-16">{children}</div>
      </div>
      {isWithFooter && <Footer />}
    </>
  );
}
