import { Text } from "@glorialabs/gl-components-module-ui";
import { FunctionComponent } from "react";

interface ILogoutMenu {
  closeAll: () => void;
  btnLogout: () => Promise<void>;
}

export const LogoutMenu: FunctionComponent<ILogoutMenu> = ({
  closeAll,
  btnLogout
}) => {
  return (
    <div id="closesession">
      <div className="w-full p-4 flex justify-end pr-8">
        <button
          onClick={() => {
            closeAll();
            btnLogout();
          }}
          style={{ background: "none", border: "none", padding: 0 }}
        >
          <Text
            size="base"
            bold={true}
            className="text-[#0A0036] tracking-wider cursor-pointer"
          >
            Cerrar sesión
          </Text>
        </button>
      </div>
    </div>
  );
};
