import React from "react";
import {
  Table,
  ExpandableColumnProps,
  ColumnProps
} from "@glorialabs/gl-components-module-ui";
import {
  compositionBiweeklyDataColumns,
  compositionDailyDataColumns,
  compositionExpandableDailyDataColumns,
  compositionMonthlyDataColumns
} from "../Composition/utils/compositionColumns";
import {
  qualityBiweeklyDataColumns,
  qualityDailyDataColumns,
  qualityExpandableDailyDataColumns,
  qualityMonthlyDataColumns
} from "../Quality/utils/qualityColumns";
import { DataMonthlyChart, transformMonthlyData } from "../utils/formatChartData";
import { IBaseDataProduction } from "../../../services/quality/interface";
import {
  acumulationBiweeklyDataColumns,
  acumulationDailyDataColumns,
  acumulationExpandableDailyDataColumns,
  acumulationMonthlyDataColumns
} from "../Accumulation/utils/acumulationColumns";
import { Period } from "../../../enum/Period.enum";
import classNames from "classnames";

export interface MonthlyDataField {
  field: keyof IBaseDataProduction;
  title: string;
}

interface DataTableProps {
  activePage: "composition" | "quality" | "acumulation";
  activeTab: number;
  dataDaily: IBaseDataProduction[];
  dataBiweekly: IBaseDataProduction[];
  dataMonthly: IBaseDataProduction[];
  monthlyDataFields: MonthlyDataField[]; // Ajusta según tus columnas mensuales
}

type IDataTable = IBaseDataProduction | DataMonthlyChart;

const DataTable: React.FC<DataTableProps> = ({
  activePage,
  activeTab,
  dataDaily,
  dataBiweekly,
  dataMonthly,
  monthlyDataFields
}) => {
  let columns: ColumnProps[] = [];
  let expandableColumns: ExpandableColumnProps[] = [];
  let monthlyColumns: ColumnProps[] = [];
  let data: IDataTable[] = [];
  let rowKey: string | undefined;
  let title: string | undefined;
  let keyExpandableData: string | undefined;
  let pagination: object | undefined;
  let itemsPerPage: number = 10;
  let key: string | undefined;

  // Selección de columnas según la página activa y la pestaña activa (daily, biweekly, monthly)
  if (activePage === "composition") {
    if (activeTab === Period.DAILY) {
      columns = compositionDailyDataColumns;
      expandableColumns = compositionExpandableDailyDataColumns;
      data = dataDaily as IBaseDataProduction[];
      pagination = {};
      keyExpandableData = "items";
      rowKey = "lot_milk_date";
      key = "dailydata";
    } else if (activeTab === Period.BIWEEKLY) {
      columns = compositionBiweeklyDataColumns;
      data = dataBiweekly as IBaseDataProduction[];
      rowKey = "lot_milk_date";
      key = "biweeklydata";
    } else if (activeTab === Period.MONTHLY) {
      monthlyColumns = compositionMonthlyDataColumns;
      columns = compositionMonthlyDataColumns;
      title = monthlyDataFields[0].title;
      data = transformMonthlyData(dataMonthly, monthlyDataFields[0].field) as DataMonthlyChart[];
      itemsPerPage = 12;
      rowKey = "period";
      key = "monthlydata";
    }
  } else if (activePage === "quality") {
    if (activeTab === Period.DAILY) {
      columns = qualityDailyDataColumns;
      expandableColumns = qualityExpandableDailyDataColumns;
      data = dataDaily as IBaseDataProduction[];
      pagination = {};
      keyExpandableData = "items";
      rowKey = "lot_milk_date";
      key = "dailydata";
    } else if (activeTab === Period.BIWEEKLY) {
      columns = qualityBiweeklyDataColumns;
      data = dataBiweekly as IBaseDataProduction[];
      rowKey = "lot_milk_date";
      key = "biweeklydata";
    } else if (activeTab === Period.MONTHLY) {
      columns = compositionMonthlyDataColumns;
      monthlyColumns = qualityMonthlyDataColumns;
      data = transformMonthlyData(dataMonthly, monthlyDataFields[0].field) as DataMonthlyChart[];
      title = monthlyDataFields[0].title;
      itemsPerPage = 12;
      rowKey = "period";
      key = "monthlydata";
    }
  } else if (activePage === "acumulation") {
    if (activeTab === Period.DAILY) {
      columns = acumulationDailyDataColumns;
      expandableColumns = acumulationExpandableDailyDataColumns;
      data = dataDaily as IBaseDataProduction[];
      pagination = {};
      keyExpandableData = "items";
      rowKey = "lot_milk_date";
      key = "dailydata";
    } else if (activeTab === Period.BIWEEKLY) {
      columns = acumulationBiweeklyDataColumns;
      data = dataBiweekly as IBaseDataProduction[];
      rowKey = "lot_milk_date";
      key = "biweeklydata";
    } else if (activeTab === Period.MONTHLY) {
      columns = acumulationMonthlyDataColumns;
      monthlyColumns = acumulationMonthlyDataColumns;
      data = transformMonthlyData(dataMonthly, monthlyDataFields[0].field) as DataMonthlyChart[];
      title = monthlyDataFields[0].title;
      itemsPerPage = 12;
      rowKey = "period";
      key = "monthlydata";
    }
  }

  return (
    <div className={classNames({
      "grid grid-cols-1 lg:grid-cols-2 gap-4": (activeTab === 2 && monthlyDataFields[1])
    })}>
      <Table
        className="w-full"
        key={key}
        columns={columns}
        data={data}
        rowKey={rowKey}
        title={title}
        expandableColumns={expandableColumns}
        keyExpandableData={keyExpandableData}
        pagination={pagination}
        itemsPerPage={itemsPerPage}
      />

      {activeTab === 2 && monthlyDataFields[1] && (
        <Table
          rowKey="period"
          title={monthlyDataFields[1].title}
          className="w-full"
          columns={monthlyColumns}
          data={
            dataMonthly
              ? transformMonthlyData(dataMonthly, monthlyDataFields[1].field)
              : []
          }
          itemsPerPage={12}
        />
      )}
    </div>
  );
};

export default DataTable;
