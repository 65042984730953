import { FunctionComponent } from "react";

interface IConditionalWrapper{
  condition: boolean;
  wrapper: (children: JSX.Element) => JSX.Element;
  children: JSX.Element
}

export const ConditionalWrapper: FunctionComponent<IConditionalWrapper> = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;
