import { Button, Input } from "@glorialabs/gl-components-module-ui";
import axios from "axios";
import { FormikProps } from "formik";
import { FunctionComponent, useEffect } from "react";
import { INewPasswordForm } from "./form";

interface INewPasswordRequired {
  formik: FormikProps<INewPasswordForm>;
  isLoadingChange: boolean;
  error: unknown;
  setOriginMFA: (origin: "login" | "newPassword") => void;
}

export const NewPasswordRequired: FunctionComponent<INewPasswordRequired> = ({
  formik,
  isLoadingChange,
  error,
  setOriginMFA
}) => {
  const { values, errors, handleSubmit } = formik;

  useEffect(() => {
    setOriginMFA("newPassword");
  }, [setOriginMFA]);

  return (
    <div className="flex flex-col justify-center items-center">
      <p className="text-center leadig-6">
        ¡Bienvenido al Portal Ganadero de Gloria!
      </p>
      <p className="text-center leadig-6 mb-4">
        Por favor, crea tu propia contraseña con la que
        <br />
        iniciarás sesión de hoy en adelante.
      </p>
      <form onSubmit={handleSubmit}>
        <Input
          label="Crea tu nueva contraseña"
          value={values.newPassword}
          onChange={(e) => formik.setFieldValue("newPassword", e.target.value)}
          maxLength={45}
          className="w-full lg:w-[361px] h-fit mb-6"
          suggestionText={
            "La contraseña debe tener al menos 8 caracteres, 1 mayúscula, 1 minúscula, 1 carácter especial y 1 número"
          }
          variant={
            axios.isAxiosError(error) || errors.newPassword
              ? "error"
              : undefined
          }
          feedback={
            axios.isAxiosError(error)
              ? error.response?.data.message
              : errors.newPassword ?? undefined
          }
        />
        <Input
          {...formik.getFieldProps("newPasswordRepeat")}
          label="Repite tu nueva contraseña"
          maxLength={45}
          className="w-full lg:w-[361px] h-fit mb-6"
          suggestionText="Debe coincidir la nueva contraseña"
          variant={errors.newPasswordRepeat ? "error" : undefined}
          feedback={errors.newPasswordRepeat ?? undefined}
        />
        <Button
          loading={isLoadingChange}
          className="w-[361px] mb-4"
          type="submit"
        >
          Crear contraseña
        </Button>
      </form>
    </div>
  );
};
