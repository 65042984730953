import { Button, ColumnProps } from "@glorialabs/gl-components-module-ui";
import { IResponseUserMaster } from "../../../services/master/response.interface";
import { Link } from "react-router-dom";

export const userColumns: ColumnProps[] = [
  {
    title:  "Nombre",
    key:    "name",
    render: (_: number, record: IResponseUserMaster) => {
      return (
        <p>{record.firstname + " " + record.lastname}</p>
      );
    }
  },
  {
    title:  "Correo",
    key:    "email_account",
    render: (_: number, record: IResponseUserMaster) => <p>{record.email_account}</p>
  },
  {
    title:  "Tipo de usuario",
    key:    "jobTitle",
    render: (_: number, record: IResponseUserMaster) => (
      <p>
        {record.profile.description === "Usuario administrador"
          ? "Administrador"
          : record.profile.description}
      </p>
    )
  },
  {
    title: "Estado",
    key:   "is_active",

    render: (_: number, record: IResponseUserMaster) => (
      <p>{record.is_active ? "Habilitado" : "Inhabilitado"}</p>
    )
  },
  {
    title:  "",
    key:    "act",
    align:  "right",
    render: (_: number, record: IResponseUserMaster) => (
      <div className="flex justify-end">
        <Link to={`/usuarios/detail/ver/${record.id}`}>
          <Button variant="primary" outline={true} className="w-[139px]">
            Ver
          </Button>
        </Link>

        <div className="mx-4"></div>
        <Link to={`/usuarios/detail/editar/${record.id}`}>
          <Button variant="primary" outline={true} className="w-[139px]">
            Editar
          </Button>
        </Link>
      </div>
    )
  }
];
