import { getInstallmentStatus } from "./getInstallmentStatus";

export const getColorByStatus = (dueDate: string, status: boolean): string => {
  const installmentStatus = getInstallmentStatus(dueDate, status);
  if (installmentStatus === "Por pagar") {
    return "#EF863D";
  } else {
    return "#0A0036";
  }
};
