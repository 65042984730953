import moment from "moment";
import type { ColumnProps, ExpandableColumnProps } from "@glorialabs/gl-components-module-ui";
import { IBaseDataProduction } from "../../../../services/quality/interface";
import { formatBiweeklyDate } from "../../utils/formatDate";
// Función para renderizar la fecha
const renderDate = (record: IBaseDataProduction, biweekly?: boolean) => (
  <>{biweekly ? formatBiweeklyDate(record.lot_milk_date) : moment(record.lot_milk_date).format("DD/MM/YYYY")}</>
);

// Función para renderizar el valor numérico
const renderNumericValue = (value?: number) => (
  <p>{value === 0 ? "-" : value?.toLocaleString("en-US") ?? "-"}</p>
);

// Columnas comunes para dailyDataColumns y biweeklyDataColumns
const commonColumns: ColumnProps[] = [
  {
    title:  "Periodo",
    key:    "period",
    render: (_: number, record: IBaseDataProduction, isExpanded, expandRow) => (
      <p>
        {renderDate(record)}{" "}
        {record.items!.length > 0 && (
          <>
            {" "}
            -{" "}
            <span
              onClick={expandRow}
              className="cursor-pointer font-semibold"
              style={{ background: "none", border: "none", padding: 0 }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && expandRow) {
                  expandRow();
                }
              }}
              tabIndex={0}
            >
              {isExpanded ? "Ocultar Entregas" : "Ver Entregas"}
            </span>
          </>
        )}{" "}
      </p>
    )
  },
  {
    title:  "Acopio (kg)",
    key:    "acopio",
    align:  "right",
    render: (_: number, record: IBaseDataProduction) => renderNumericValue(record.sumAmountCollect)
  },
  {
    title:  "Temp (ºC)",
    key:    "temp",
    align:  "right",
    render: (_: number, record: IBaseDataProduction) => renderNumericValue(record.tempAverageWeighted)
  }
];

// Columnas comunes para expandableDailyDataColumns y biweeklyDataColumns
const expandableCommonColumns: ExpandableColumnProps[] = [
  {
    title:  "Periodo",
    key:    "periodExpandable",
    render: (index: number) => (
      <p className="text-[#07196D]">Entrega {index + 1}</p>
    )
  },
  {
    title:  "Acopio",
    key:    "acopioExpandable",
    align:  "right",
    render: (_: number, record: IBaseDataProduction) => renderNumericValue(record.sumAmountCollect)
  },
  {
    title:  "Temp (ºC)",
    key:    "temp",
    align:  "right",
    render: (_: number, record: IBaseDataProduction) => renderNumericValue(record.tempAverageWeighted)
  }
];

export const acumulationDailyDataColumns: ColumnProps[] = commonColumns;
export const acumulationExpandableDailyDataColumns: ExpandableColumnProps[] = expandableCommonColumns;

export const acumulationBiweeklyDataColumns: ColumnProps[] = [
  {
    title:  "Periodo",
    key:    "period",
    render: (_: number, record: IBaseDataProduction) => renderDate(record, true)
  },
  ...commonColumns.slice(1, 2) // Copia las dos primeras columnas comunes
];

interface IPeriod {
  period: string;
  2023: number | string;
  2024: number | string;
}

export const acumulationMonthlyDataColumns: ColumnProps[] = [
  {
    title:  "Periodo",
    key:    "period",
    render: (_: number, record: IPeriod) => (
      <p className="capitalize">
        {record.period[0] + record.period.substring(1).toLowerCase()}
      </p>
    )
  },
  {
    title:  "2023",
    key:    "2023",
    align:  "right",
    render: (_: number, record: IPeriod) => (
      <p>
        {typeof record[2023] === "number" && record[2023] !== 0 ? record[2023].toLocaleString("en-US") : "-"}
      </p>
    )
  },
  {
    title:  "2024",
    key:    "2024",
    align:  "right",
    render: (_: number, record: IPeriod) => (
      <p>
        {typeof record[2024] === "number" && record[2024] !== 0 ? record[2024].toLocaleString("en-US") : "-"}
      </p>
    )
  }
];
