import moment from "moment";

// Función para determinar el estado de la cuota
export function getInstallmentStatus(date: string, status: boolean): string {
  const currentDate = moment(); // Obtener la fecha actual
  const dueDate = moment(date); // Convertir la fecha de vencimiento a un objeto moment

  if (status === true) {
    return "Pagado";
  } else if (status === false && currentDate.isBefore(dueDate, "day")) {
    return "Por pagar";
  } else {
    return "Pagado";
  }
}

